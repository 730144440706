import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import type { LoadableLoadingComponentProps } from 'view/lazy';
import * as grid from 'view/styles/grid';
import { Box } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { ChunkLoadErrorMessage, LoadingSkeleton } from '@atlassian/help-center-common-component/loading';
import { Container, TopicContainer, HelpObjectContainer, SpinnerWrapper } from '../styled';

export const EditTopicLoader: React.FC<LoadableLoadingComponentProps> = (props) => {
    return (
        <>
            {props.error ? (
                <LoaderContainer>
                    <ChunkLoadErrorMessage />
                </LoaderContainer>
            ) : (
                <Container>
                    <TopicContainer>
                        <Box paddingInline="space.500">
                            <SpinnerWrapper>
                                <Spinner size={'medium'} />
                            </SpinnerWrapper>
                        </Box>
                    </TopicContainer>
                    <HelpObjectContainer>
                        <Row>
                            <LoadingSkeleton height={40} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
                        </Row>
                        <Row>
                            <LoadingSkeleton height={40} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
                        </Row>
                        <Row>
                            <LoadingSkeleton height={40} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
                        </Row>
                    </HelpObjectContainer>
                </Container>
            )}
        </>
    );
};

const LOADER_MIN_HEIGHT = '75vh';
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const LoaderContainer = styled.div`
    min-height: ${LOADER_MIN_HEIGHT};
    display: flex;
    align-items: center;
    justify-content: center;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Row = styled.div`
    margin-top: ${token('space.200', '16px')};
    width: ${grid.multiple(50).px};
`;
