import React from 'react';
import { LazySuspense } from 'react-loosely-lazy';
import type { LooselyLazyOptions } from '..';
import { LoadingComponent, ErrorBoundaryAnalytics, Fallback } from '..';

const LooselyLoadableWrapper = <TProps extends object>(options: LooselyLazyOptions<TProps>) => {
    const Component = options.loader;
    const LazyComponent: React.FC<TProps> & { preload: typeof options.loader.preload } = (props) => {
        const renderError = React.useCallback((error: Error) => {
            return <LoadingComponent error={error} LoadingSkeleton={options.LoadingSkeleton} />;
        }, []);

        return (
            <ErrorBoundaryAnalytics renderError={renderError}>
                <LazySuspense fallback={<Fallback {...options} />}>
                    <Component {...props} />
                </LazySuspense>
            </ErrorBoundaryAnalytics>
        );
    };

    LazyComponent.preload = options.loader.preload;

    return LazyComponent;
};

export default LooselyLoadableWrapper;
