/**
 * @generated SignedSource<<2295af9625867920fc4b7ff6ddb06640>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type HelpObjectStoreRequestTypeSearchStrategy = "JIRA_ISSUE_BASED_SEARCH" | "JIRA_KEYWORD_BASED" | "SEARCH_PLATFORM_KEYWORD_BASED" | "SEARCH_PLATFORM_KEYWORD_BASED_ER" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type requestFormItemFragmentV2$data = {
  readonly displayLink: string;
  readonly id: string;
  readonly metadata: {
    readonly isExternal: boolean;
    readonly searchStrategy: HelpObjectStoreRequestTypeSearchStrategy;
  };
  readonly title: string;
  readonly " $fragmentType": "requestFormItemFragmentV2";
};
export type requestFormItemFragmentV2$key = {
  readonly " $data"?: requestFormItemFragmentV2$data;
  readonly " $fragmentSpreads": FragmentRefs<"requestFormItemFragmentV2">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "requestFormItemFragmentV2",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "title"
    },
    {
      "kind": "ScalarField",
      "name": "displayLink"
    },
    {
      "concreteType": "HelpObjectStoreRequestTypeMetadata",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isExternal"
        },
        {
          "kind": "ScalarField",
          "name": "searchStrategy"
        }
      ]
    }
  ],
  "type": "HelpObjectStoreRequestTypeSearchResult"
};

(node as any).hash = "3676027039798345dde34e2948999172";

export default node;
