import type { Environment as RelayModernEnvironment } from 'relay-runtime';
import { commitLocalUpdate } from 'relay-runtime';

interface UpdateOmniChannelConversationMetaArgs {
    workspaceAri?: string;
    conversationContextAri?: string;
    conversationId?: string;
    connectionId?: string | null;
    isReplyPending?: boolean;
    isConversationClosed?: boolean;
    isConversationExpired?: boolean;
    disableInput?: boolean;
    environment: RelayModernEnvironment;
    subscriptionId?: string | null;
    draftMessage?: string;
    greetingMessage?: string | null;
    isGreetingMessageAdded?: boolean;
    subscriptionTriggered?: boolean;
    subscriptionStatus?: 'TRIGGERED' | 'ACTIVE' | 'FAILED' | 'NONE';
}

export const updateOmniChannelConversationMeta = ({
    environment,
    ...remainingParams
}: UpdateOmniChannelConversationMetaArgs) => {
    commitLocalUpdate(environment, (store) => {
        const root = store.getRoot();
        const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
        Object.keys(remainingParams).forEach(
            (key: keyof Omit<UpdateOmniChannelConversationMetaArgs, 'environment'>) => {
                jsmChatQuery.setValue(remainingParams[key], key);
            }
        );
    });
};

export const updateOmniChannelConversationMetaPromise = ({
    environment,
    ...remainingParams
}: UpdateOmniChannelConversationMetaArgs) => {
    return new Promise<undefined>((resolve) => {
        commitLocalUpdate(environment, (store) => {
            const root = store.getRoot();
            const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
            Object.keys(remainingParams).forEach(
                (key: keyof Omit<UpdateOmniChannelConversationMetaArgs, 'environment'>) => {
                    jsmChatQuery.setValue(remainingParams[key], key);
                }
            );
            resolve(undefined);
        });
    });
};
