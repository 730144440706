import React from 'react';

import { isUiModificationsSupportEnabled } from '@helpCenter/feature-flags';
import { di } from 'react-magnetic-di';
import type { ContainerComponent } from 'react-sweet-state';
import { BaseUiModificationsContainer } from '@atlassian/ui-modifications-core/controllers';

import { REQUEST_CREATE_VIEW_TYPE } from '../../common/constants';
import { RequestCreateViewConfiguration } from '../../common/utils/configuration';

export const UiModificationsContainer: ContainerComponent<unknown> = ({ children }) => {
    di(BaseUiModificationsContainer, isUiModificationsSupportEnabled);

    return isUiModificationsSupportEnabled() ? (
        <BaseUiModificationsContainer
            scope={REQUEST_CREATE_VIEW_TYPE}
            viewType={REQUEST_CREATE_VIEW_TYPE}
            viewConfiguration={RequestCreateViewConfiguration}
        >
            {children}
        </BaseUiModificationsContainer>
    ) : (
        children
    );
};
