import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { css } from 'styled-components';
import { isCORTOPSExperimentEnabled } from 'feature-flags';
import * as fonts from 'view/styles/fonts';
import * as mixins from 'view/styles/mixins';
import { sizes } from 'view/styles/sizes-viewport';
import { Flex, xcss } from '@atlaskit/primitives';
import type { ControlProps, FormatOptionLabelMeta, OptionProps, ValueType } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Select } from '@atlassian/help-center-common-component/analytics/select';
import { AutoFocus } from '@atlassian/help-center-common-component/auto-focus';
import type { Appearance as CardLinkAppearance } from '@atlassian/help-center-common-component/card-link';
import { CardLink } from '@atlassian/help-center-common-component/card-link';
import type {
    CustomizeRequestTypesPortalButtonGroup,
    EditRequestTypeButton,
} from '@atlassian/help-center-common-component/cutomize-portal-buttons';
import { FireScreenEventOnMount } from '@atlassian/help-center-common-util/analytics/fire-screen-event';
import { toSingleSelectValue } from '@atlassian/help-center-common-util/select';
import { GroupBox, GroupBoxLink } from '../group-box';

export interface Option<TValue> {
    value: TValue;
    label: string;
    description?: React.ReactNode;
    to: string;
    iconUrl?: string;
    dataTestId?: string;
    target?: '_self' | '_blank' | '_parent' | '_top';
}

interface GroupBoxSelectProps<TValue> {
    options: Option<TValue>[];
    label: string;
    labelIcon?: React.ReactNode;
    id: string;
    actionSubjectId: string;
    itemActionSubjectId: string;
    /**
     * Only callbacks when the value was changed via the select input.
     * If an item was clicked inside the list it will not be called.
     */
    onChange?: (value?: TValue) => void;
    value?: TValue;
    disabled?: boolean;
    className?: string;
    autoFocus?: boolean;
    listAppearance: 'default' | 'card';
    selectAppearance: 'default' | 'simple';
    cardLinkAppearance?: CardLinkAppearance;
    renderItem?: (node: React.ReactNode, index: number) => React.ReactNode;
    cutomizePortalButtonsGroup?: React.ReactElement<typeof CustomizeRequestTypesPortalButtonGroup> | null;
    editRequestTypeButton?: React.ReactElement<typeof EditRequestTypeButton> | null;
    isProjectAdmin?: boolean;
    isJiraAdmin?: boolean;
}

export type Value = string | number;

export default class GroupBoxSelect<TValue extends Value> extends React.PureComponent<GroupBoxSelectProps<TValue>> {
    static defaultProps = {
        listAppearance: 'default',
        selectAppearance: 'default',
    };

    formatOptionLabel = (option: Option<TValue>, context: FormatOptionLabelMeta<Option<TValue>>) => {
        switch (this.props.selectAppearance) {
            case 'simple': {
                if (context.context === 'menu') {
                    return (
                        <GroupBox
                            containerPadding="tight"
                            icon={option.iconUrl}
                            heading={option.label}
                            description={option.description}
                        />
                    );
                }
                return <SimpleSelectOption>{option.label}</SimpleSelectOption>;
            }

            case 'default':
            default: {
                return (
                    <GroupBox
                        containerPadding="tight"
                        icon={option.iconUrl}
                        heading={option.label}
                        description={option.description}
                        lineClamp={context.context === 'value' ? 1 : 0}
                    />
                );
            }
        }
    };

    getValue() {
        const { value, options } = this.props;

        if (value) {
            return options.find((option) => option.value === value);
        }

        return null;
    }

    onChange = (optionValue: ValueType<Option<TValue>>) =>
        this.props.onChange?.(toSingleSelectValue(optionValue)?.value);

    renderSmallLabel() {
        const { id, label, editRequestTypeButton, isProjectAdmin, isJiraAdmin } = this.props;
        const isMediumScreen = window.innerWidth < sizes.medium;
        return (isJiraAdmin || isProjectAdmin) &&
            !isMediumScreen &&
            editRequestTypeButton &&
            isCORTOPSExperimentEnabled() === 'variation' ? (
            <Flex justifyContent="space-between" alignItems="center" xcss={labelStylesWrapper}>
                <SmallSelectHeader isCORTOPSExperimentEnabled>
                    <label htmlFor={id}>{label}</label>
                </SmallSelectHeader>
                {editRequestTypeButton}
            </Flex>
        ) : (
            <SmallSelectHeader>
                <label htmlFor={id}>{label}</label>
            </SmallSelectHeader>
        );
    }

    renderBigLabel() {
        const { id, label, labelIcon, cutomizePortalButtonsGroup, isProjectAdmin, isJiraAdmin } = this.props;
        const isMediumScreen = window.innerWidth < sizes.medium;
        return (isProjectAdmin || isJiraAdmin) &&
            !isMediumScreen &&
            cutomizePortalButtonsGroup &&
            isCORTOPSExperimentEnabled() === 'variation' ? (
            <Flex justifyContent="space-between" alignItems="center" xcss={labelStylesWrapper}>
                <SelectHeader isCORTOPSExperimentEnabled>
                    <LabelWithIcon htmlFor={id}>
                        {labelIcon}
                        {/* Important for this to be in a span so we can use CSS selectors to target when */}
                        {/* there is/isn't a label. Span gives this a proper element. */}
                        <span>{label}</span>
                    </LabelWithIcon>
                </SelectHeader>
                {cutomizePortalButtonsGroup}
            </Flex>
        ) : (
            <SelectHeader>
                <LabelWithIcon htmlFor={id}>
                    {labelIcon}
                    {/* Important for this to be in a span so we can use CSS selectors to target when */}
                    {/* there is/isn't a label. Span gives this a proper element. */}
                    <span>{label}</span>
                </LabelWithIcon>
            </SelectHeader>
        );
    }

    renderAsSelect(optionValue: Option<TValue>) {
        const { id, disabled, options, autoFocus, actionSubjectId } = this.props;

        return (
            <>
                {this.renderSmallLabel()}

                <Select<Option<TValue>>
                    actionSubjectId={actionSubjectId}
                    inputId={id}
                    // Disabling existing violations, should be fixed when revisited.
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={autoFocus}
                    options={options}
                    onChange={this.onChange}
                    value={optionValue}
                    menuPortalTarget={document.body}
                    formatOptionLabel={this.formatOptionLabel}
                    isDisabled={disabled}
                    menuPlacement="auto"
                    // We want the selected option to be visible so customers can read the description if they desire.
                    hideSelectedOptions={false}
                    isSearchable={false}
                    styles={{
                        control: (supplied: ControlProps<Option<TValue>>, { isFocused }: { isFocused: boolean }) => ({
                            ...supplied,
                            // Don't allow flex children to wrap, since we reset styles of
                            // the value container we need this.
                            flexWrap: 'nowrap',
                            // Suppressing existing violation. Please fix this.
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            ':hover': {
                                // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                                ...supplied[':hover'],
                                backgroundColor: isFocused
                                    ? token('color.background.input', colors.N0)
                                    : token('color.background.accent.gray.subtler', colors.N30),
                                borderColor: isFocused
                                    ? token('color.border.focused', colors.B100)
                                    : token('color.background.accent.gray.subtler', colors.N30),
                            },
                            backgroundColor: isFocused
                                ? token('color.background.input', colors.N0)
                                : token('color.background.accent.gray.subtlest', colors.N20),
                            borderColor: isFocused
                                ? token('color.border.focused', colors.B100)
                                : token('color.background.accent.gray.subtlest', colors.N20),
                        }),
                        valueContainer: () => ({
                            // We apply our own styles here.
                            //
                            // Set the container to be flex so its height matches the children
                            // instead of busting past it. Overflow hidden so the children
                            // show their ellipsis if needed.
                            display: 'flex',
                            overflow: 'hidden',
                        }),
                        singleValue: () => ({
                            // We apply our own styles here.
                            //
                            // Since the value is a flex child set its width to 100% so it only
                            // takes up the given space.
                            width: '100%',
                        }),
                        option: (_: OptionProps, state: { isFocused: boolean; isSelected: boolean }) => ({
                            // We apply our own styles here.
                            backgroundColor: state.isFocused
                                ? token('elevation.surface.hovered', colors.N20)
                                : undefined,
                            cursor: 'pointer',
                            padding: 0,
                            margin: 0,
                            width: '100%',
                        }),
                        menu: (styles: object) => ({
                            ...styles,

                            backgroundColor: token('elevation.surface.overlay', colors.N0),
                        }),
                    }}
                />
            </>
        );
    }

    renderAsList(optionValue: Option<TValue> | null | undefined) {
        const { options, autoFocus, listAppearance, renderItem } = this.props;
        const hasSelectedSingleValue = options.length === 1 && optionValue === options[0];

        return (
            <>
                <FireScreenEventOnMount />
                {hasSelectedSingleValue ? this.renderSmallLabel() : this.renderBigLabel()}
                <List offsetMargin={listAppearance === 'default' && !hasSelectedSingleValue}>
                    {options.map((option, index) => {
                        const content = this.renderListItemContent(
                            option,
                            optionValue === option,
                            hasSelectedSingleValue
                        );
                        return (
                            <AutoFocus key={option.value} isEnabled={autoFocus && index === 0}>
                                <ListItem withMargin={listAppearance === 'card'}>
                                    {/* We only want to pump a list item through render item when it is NOT */}
                                    {/* selected (e.g. the list has one item in it, and the selected value is */}
                                    {/* that single item. */}
                                    {renderItem && !hasSelectedSingleValue ? renderItem(content, index) : content}
                                </ListItem>
                            </AutoFocus>
                        );
                    })}
                </List>
            </>
        );
    }

    renderListItemContent(option: Option<TValue>, isSelected: boolean, hasSelectedSingleValue: boolean) {
        const { listAppearance, cardLinkAppearance, itemActionSubjectId } = this.props;

        switch (listAppearance) {
            case 'card': {
                return (
                    <CardLink
                        to={option.to}
                        padding={0}
                        appearance={cardLinkAppearance}
                        actionSubjectId={itemActionSubjectId}
                        dataTestId={option.dataTestId}
                        target={option.target}
                    >
                        <GroupBox
                            icon={option.iconUrl}
                            heading={option.label}
                            description={option.description}
                            lineClamp={isSelected ? 1 : 0}
                        />
                    </CardLink>
                );
            }

            case 'default':
            default: {
                return (
                    <>
                        {isSelected ? (
                            <GroupBox
                                icon={option.iconUrl}
                                heading={option.label}
                                description={option.description}
                                dataTestId={option.dataTestId}
                                // eslint-disable-next-line no-nested-ternary
                                lineClamp={isSelected ? (hasSelectedSingleValue ? 0 : 1) : 0}
                            />
                        ) : (
                            <GroupBoxLink
                                icon={option.iconUrl}
                                to={option.to}
                                heading={option.label}
                                description={option.description}
                                // eslint-disable-next-line no-nested-ternary
                                lineClamp={isSelected ? (hasSelectedSingleValue ? 0 : 1) : 0}
                                selected={isSelected}
                                actionSubjectId={itemActionSubjectId}
                                dataTestId={option.dataTestId}
                                target={option.target}
                            />
                        )}
                    </>
                );
            }
        }
    }

    render() {
        const { className, options } = this.props;
        const value = this.getValue();
        return (
            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            <div className={className}>
                {options.length > 1 && value ? this.renderAsSelect(value) : this.renderAsList(value)}
            </div>
        );
    }
}

interface ListProps {
    offsetMargin?: boolean;
    withMargin?: boolean;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ListItem = styled.li<ListProps>`
    display: block;
    ${(props) =>
        mixins.applyIf(
            !!props.withMargin,
            // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            css`
                &:not(:last-child) {
                    margin-bottom: ${token('space.400', '32px')};
                }
            `
        )};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const LabelWithIcon = styled.label`
    display: flex;
    align-items: center;

    /* Apply a margin-right to every child that isn't the last. */
    /* We use this selector because it's nice and generic, so we don't have */
    /* to wrap a child with a container which would break flex centering. */
    > :not(:last-child) {
        margin-right: ${token('space.100', '8px')};
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const SimpleSelectOption = styled.div`
    margin-left: ${token('space.100', '8px')};
`;

interface ListProps {
    offsetMargin?: boolean;
    withMargin?: boolean;
}

const GROUP_BOX_SELECT_LIST_TOP_MARGIN = token('space.negative.200', '-16px');
const GROUP_BOX_SELECT_PADDING_INLINE = token('space.negative.300', '-24px');
// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const List = styled.ul<ListProps>`
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    margin: ${(props) =>
        props.offsetMargin ? `${GROUP_BOX_SELECT_LIST_TOP_MARGIN} ${GROUP_BOX_SELECT_PADDING_INLINE} 0` : '0'};
    padding: 0;
`;

interface SelectHeaderProps {
    isCORTOPSExperimentEnabled?: boolean;
}
// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const SelectHeader = styled.h2<SelectHeaderProps>`
    ${fonts.h500};
    display: block;
    margin-bottom: ${(props) => (props.isCORTOPSExperimentEnabled ? '0' : token('space.200', '16px'))};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const SmallSelectHeader = styled(SelectHeader)<SelectHeaderProps>`
    ${fonts.h200};
    margin-bottom: ${(props) => (props.isCORTOPSExperimentEnabled ? '0' : token('space.050', '4px'))};
`;

const labelStylesWrapper = xcss({
    marginBottom: 'space.200',
});
