import { once } from 'lodash';
import { isCmdbFieldWithAttributesEnabled } from '../../feature-flags';
import { getAssetFieldValueElementClass } from './asset-field-value';
import { getCmdbFieldValueElement } from './cmdb-field-value';

// This is where all of our custom Web Components should be defined.
export const install = once(() => {
    // asset-field-value is rendered on Request Details; it displays the names of assets linked to the request
    if (typeof window !== 'undefined' && window.customElements) {
        customElements.define('asset-field-value', getAssetFieldValueElementClass());
        if (isCmdbFieldWithAttributesEnabled()) {
            customElements.define('cmdb-field-value', getCmdbFieldValueElement());
        }
    }
});
