import { isJsmScimLockEnabled } from 'feature-flags';
import type { UserResponse } from 'rest/models';
import type {
    SaveUserProfileSuccess,
    SuccessfullyLoggedIn,
    UpdateUserModelAction,
    UpdateUserModelAnonymousAction,
    FetchServiceProjectRequestPermissionSuccess,
} from 'state/actions/user';
import {
    SAVE_USER_PROFILE_SUCCESS,
    SUCCESSFULLY_LOGGED_IN,
    UPDATE_USER_MODEL,
    UPDATE_USER_TO_ANON,
    FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS_SUCCESS,
} from 'state/actions/user';

import * as AJS from '@atlassian/help-center-common-util/ajs';
import { ensureConsistentKey } from '@atlassian/help-center-common-util/languages';
import { initialModel } from '@atlassian/help-center-common-util/model';

export interface LanguageModel {
    key: string;
    originalKey: string;
    displayName: string;
}

export interface TimezoneModel {
    id: string;
    regionKey: string;
    city: string;
    gmtOffset: string;
}

export interface AvatarModel {
    xsmall: string;
    small: string;
    medium: string;
    large: string;
    xlarge: string;
}

export interface CustomMenuLinkModel {
    id?: string;
    key?: string;
    label?: string;
    href: string;
    params: { [key: string]: string };
    styleClass?: string;
    items?: CustomMenuLinkModel;
}

export interface UserRequestsModel {
    myRequestCount?: number;
    pendingApprovalCount?: number;
    approvalCount?: number;
}

export interface UserState {
    language: LanguageModel; // this is the language that should be used to translate
    storedLanguage: LanguageModel; // this is the language the user set as preference
    timezone: TimezoneModel;
    avatars?: AvatarModel;
    userRequests: UserRequestsModel;
    isLoaded: boolean; // This property tells us whether the user state has been loaded at some point
    isLoggedIn: boolean;
    customMenuLinks: CustomMenuLinkModel[];
    canAdministerJIRA: boolean;
    agentForPortal?: boolean;
    accountId?: string;
    displayName?: string;
    email?: string;
    atlassianAccountManaged: boolean;
    scimManaged: boolean;
    isAdEnabled?: boolean;
    canCreateProject?: boolean;
    externalCustomer?: boolean;
}

function reduceResponseToState(userResponse: UserResponse) {
    const state: UserState = {
        language: {
            key: ensureConsistentKey(userResponse.language.key),
            originalKey: userResponse.language.key,
            displayName: userResponse.language.displayName,
        },
        storedLanguage: {
            key: ensureConsistentKey(userResponse.storedLanguage.key),
            originalKey: userResponse.storedLanguage.key,
            displayName: isJsmScimLockEnabled()
                ? userResponse.storedLanguage.displayName
                : userResponse.language.displayName,
        },
        timezone: {
            id: userResponse.timezone.id,
            regionKey: userResponse.timezone.regionKey,
            city: userResponse.timezone.city,
            gmtOffset: userResponse.timezone.gmtOffset,
        },
        avatars: userResponse.avatars,
        userRequests: {},
        isLoaded: true,
        isLoggedIn: !!userResponse.accountId,
        customMenuLinks: userResponse.customMenuLinks ? userResponse.customMenuLinks : [],
        canAdministerJIRA: userResponse.canAdministerJIRA,
        agentForPortal: userResponse.agentForPortal,
        accountId: userResponse.accountId,
        displayName: userResponse.displayName,
        email: userResponse.email,
        atlassianAccountManaged: userResponse.atlassianAccountManaged,
        scimManaged: userResponse.scimManaged ?? false,
        externalCustomer: userResponse.externalCustomer,
    };

    return state;
}

const getLocale = () => AJS.getMeta('user-locale') || '';

const getInitialUserState = (): UserState | undefined => {
    const initialState = initialModel();
    if (initialState && initialState.user) {
        return reduceResponseToState(initialState.user);
    }
    return undefined;
};

const anonymousState: UserState = {
    language: {
        // temporary access user locale via AJS until we have the locale hard coded into each asset
        key: ensureConsistentKey(getLocale()),
        originalKey: getLocale(),
        displayName: getLocale(),
    },
    storedLanguage: {
        // temporary access user locale via AJS until we have the locale hard coded into each asset
        key: ensureConsistentKey(getLocale()),
        originalKey: getLocale(),
        displayName: getLocale(),
    },
    timezone: {
        id: 'JIRA',
        regionKey: 'JIRA',
        city: '',
        gmtOffset: '',
    },
    isLoaded: true,
    isLoggedIn: false,
    userRequests: {},
    canAdministerJIRA: false,
    customMenuLinks: [],
    atlassianAccountManaged: false,
    scimManaged: false,
    isAdEnabled: false,
    canCreateProject: false,
};

const defaultState: UserState = {
    language: {
        // temporary access user locale via AJS until we have the locale hard coded into each asset
        key: ensureConsistentKey(getLocale()),
        originalKey: getLocale(),
        displayName: getLocale(),
    },
    storedLanguage: {
        // temporary access user locale via AJS until we have the locale hard coded into each asset
        key: ensureConsistentKey(getLocale()),
        originalKey: getLocale(),
        displayName: getLocale(),
    },
    timezone: {
        id: 'JIRA',
        regionKey: 'JIRA',
        city: '',
        gmtOffset: '',
    },
    isLoaded: false,
    isLoggedIn: false,
    userRequests: {},
    canAdministerJIRA: false,
    customMenuLinks: [],
    atlassianAccountManaged: false,
    scimManaged: false,
    isAdEnabled: false,
    canCreateProject: false,
    // Initial user state spreads _last_ just in case there are things on the server that should
    // override initial (local) state.
    ...getInitialUserState(),
};

export type Action =
    | UpdateUserModelAction
    | UpdateUserModelAnonymousAction
    | SaveUserProfileSuccess
    | SuccessfullyLoggedIn
    | FetchServiceProjectRequestPermissionSuccess;

export default function reducer(state: UserState = defaultState, action: Action): UserState {
    switch (action.type) {
        case UPDATE_USER_MODEL: {
            const userResponse: UserResponse = action.payload;
            const newState = reduceResponseToState(userResponse);
            return { ...state, ...newState };
        }
        case UPDATE_USER_TO_ANON:
            return { ...anonymousState };
        case SAVE_USER_PROFILE_SUCCESS:
            return handleUpdateUserProfileSuccessAction(state, action);
        case FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS_SUCCESS: {
            const { isAdEnabled, canCreateProject } = action.payload;
            return { ...state, isAdEnabled, canCreateProject };
        }
        case SUCCESSFULLY_LOGGED_IN:
            return handleSuccessfullyLoggedIn(state);
        default:
            return state;
    }
}

function handleUpdateUserProfileSuccessAction(state: UserState, action: SaveUserProfileSuccess): UserState {
    const displayName = action.payload.displayName || state.displayName;
    const language = action.payload.language || state.storedLanguage;
    const timezone = action.payload.timezone || state.timezone;

    return {
        ...state,
        displayName,
        timezone,
        storedLanguage: {
            key: ensureConsistentKey(language.originalKey),
            originalKey: language.originalKey,
            displayName: language.displayName,
        },
    };
}

const handleSuccessfullyLoggedIn = (state: UserState): UserState => ({
    ...state,
    isLoggedIn: true,
});
