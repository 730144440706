import type { AssetsConfigAllAtlassianArgs, AssetsConfigPreloadedArgs } from '@atlaskit/react-ufo/common';

const allAtlassian = ({ entry }: AssetsConfigAllAtlassianArgs): boolean => {
    const assetNames = ['atl-paas.net', 'atlastunnel', 'batch.js'];
    const isAtlassian = assetNames.some((name) => entry.name.includes(name));
    return isAtlassian;
};

const preloaded = ({ entry, SSRDoneTime }: AssetsConfigPreloadedArgs): boolean => {
    return Boolean(SSRDoneTime && entry.startTime <= SSRDoneTime);
};

export const assetsConfig = {
    // allowedTypes: ['js', 'css', 'image', 'font'], // By default only JS
    classification: {
        allAtlassian,
        preloaded,
        all: true,
    },
};
