import { isReact18LazyEnabled } from 'feature-flags';
import { LazyLoadableComponent, type CombinedOptions } from '../../lazy';
import { LooselyLoadableWrapper } from '../../lazy/loosely-loadable-wrapper';

/**
 * This will generically handle async loading of components with a
 * loading, timeout, and errored states. If you need more specific
 * handling use react-loosely-lazy directly.
 *
 * Use the preload option if you want the component to start loading
 * immediately.
 */
const withAsync = <TProps extends object>(options: CombinedOptions<TProps>) => {
    if (isReact18LazyEnabled()) {
        return LazyLoadableComponent(
            options as CombinedOptions<TProps> & { lazyLoader: NonNullable<typeof options.lazyLoader> }
        );
    }
    const LoadableComponent = LooselyLoadableWrapper({
        loader: options.loader,
        LoadingSkeleton: options.LoadingSkeleton,
        loadingPosition: options.loadingPosition,
        timeout: 5000,
    });

    if (options.preload) {
        LoadableComponent.preload();
    }

    return LoadableComponent;
};

export default withAsync;
