import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

// Used to track Subscription Reliability
export const subscriptionConnectionExperience = new UFOExperience(
    'service-management.help-center.omnichannel-v2.subscription-connection',
    {
        type: ExperienceTypes.Experience,
        performanceType: ExperiencePerformanceTypes.InlineResult,
    }
);

export const initialIntentMessageHCExperience = new UFOExperience(
    'service-management.help-center.omnichannel-v2.initial-message',
    {
        type: ExperienceTypes.Experience,
        performanceType: ExperiencePerformanceTypes.InlineResult,
    }
);

export const initialAIMessageHCExperience = new UFOExperience(
    'service-management.help-center.omnichannel-v2.initial-message.ai',
    {
        type: ExperienceTypes.Experience,
        performanceType: ExperiencePerformanceTypes.InlineResult,
    }
);

export const omnichannelPageLoadExperience = new UFOExperience(
    'service-management.help-center.omnichannel-v2.page-load',
    {
        type: ExperienceTypes.Experience,
        performanceType: ExperiencePerformanceTypes.InlineResult,
    }
);

export const omnichannelGreetingMessageExperience = new UFOExperience(
    'service-management.help-center.omnichannel.greeting-message',
    {
        type: ExperienceTypes.Experience,
        performanceType: ExperiencePerformanceTypes.InlineResult,
    }
);
