import * as React from 'react';
import { isCustomPagesUiEnabled, isProjectMappingInHelpCenterEnabled } from '@helpCenter/feature-flags';
import { getIsHCCustomizationEnabled } from '@helpCenter/util/env';
import { di } from 'react-magnetic-di';
import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import { Header, NavigationContent, NavigationHeader, Section } from '@atlaskit/side-navigation';
import { SkeletonItem } from '@atlassian/help-center-common-component/skeleton-item';

const SideBarContentSkeleton = () => {
    return (
        <Flex gap="space.150" direction="row" alignItems="center">
            <SkeletonItem height="14px" width="14px" />
            <SkeletonItem height="16px" width="35%" />
        </Flex>
    );
};
const SidebarHeaderSkeleton: React.FC = () => {
    di(isProjectMappingInHelpCenterEnabled);
    return (
        <NavigationHeader>
            <Header
                iconBefore={<SkeletonItem height="24px" width="80%" data-testid="icon-skeleton" />}
                description={<SkeletonItem height="16px" width="55%" data-testid="description-skeleton" />}
            >
                <Box paddingBlockEnd="space.025">
                    <SkeletonItem height="16px" width="85%" data-testid="title-skeleton" />
                </Box>
            </Header>
            <Box paddingBlockStart="space.300" paddingBlockEnd="space.150" paddingInlineStart="space.100">
                <Flex direction="row" alignItems="center" gap="space.150">
                    <SkeletonItem height="18px" width="18px" isCircle />
                    <SkeletonItem height="16px" width="60%" />
                </Flex>
            </Box>
            <NavigationContent showTopScrollIndicator>
                <Box paddingBlock="space.200">
                    <SkeletonItem height="16px" width="30%" />
                </Box>
                <Box paddingBlockStart="space.100">
                    <Stack space="space.300">
                        {isProjectMappingInHelpCenterEnabled() && (
                            <>
                                <SideBarContentSkeleton />
                                <SideBarContentSkeleton />
                            </>
                        )}
                        {isCustomPagesUiEnabled() && getIsHCCustomizationEnabled() && <SideBarContentSkeleton />}
                        <SideBarContentSkeleton />
                    </Stack>
                </Box>
            </NavigationContent>
            <Box xcss={navFooter}>
                <NavigationContent showTopScrollIndicator>
                    <Section>
                        <Box paddingBlock="space.100">
                            <Flex gap="space.150" direction="row" alignItems="center">
                                <SkeletonItem height="14px" width="14px" />
                                <SkeletonItem height="16px" width="70%" />
                            </Flex>
                        </Box>
                    </Section>
                </NavigationContent>
            </Box>
        </NavigationHeader>
    );
};

const navFooter = xcss({
    position: 'absolute',
    bottom: 'space.0',
    width: '94%',
});
export default SidebarHeaderSkeleton;
