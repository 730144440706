/**
 * @generated SignedSource<<4d1371f0915ece21011494d3b8340dd5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type HelpObjectStoreArticleSearchStrategy = "CONTENT_SEARCH" | "CQL" | "PROXY" | "%future added value";
export type HelpObjectStoreArticleSourceSystem = "CONFLUENCE" | "CROSS_SITE_CONFLUENCE" | "EXTERNAL" | "GOOGLE_DRIVE" | "SHAREPOINT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type articleItemFragmentV2$data = {
  readonly __typename: "HelpObjectStoreArticleSearchResult";
  readonly ari: string;
  readonly displayLink: string;
  readonly excerpt: string;
  readonly metadata: {
    readonly isExternal: boolean;
    readonly searchStrategy: HelpObjectStoreArticleSearchStrategy;
  };
  readonly sourceSystem?: HelpObjectStoreArticleSourceSystem | null | undefined;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"articleMetadataFragmentV2">;
  readonly " $fragmentType": "articleItemFragmentV2";
};
export type articleItemFragmentV2$key = {
  readonly " $data"?: articleItemFragmentV2$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleItemFragmentV2">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isSourceSystemEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "articleItemFragmentV2",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "ScalarField",
      "name": "ari"
    },
    {
      "kind": "ScalarField",
      "name": "title"
    },
    {
      "kind": "ScalarField",
      "name": "excerpt"
    },
    {
      "kind": "ScalarField",
      "name": "displayLink"
    },
    {
      "condition": "isSourceSystemEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "sourceSystem"
        }
      ]
    },
    {
      "concreteType": "HelpObjectStoreArticleMetadata",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isExternal"
        },
        {
          "kind": "ScalarField",
          "name": "searchStrategy"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "articleMetadataFragmentV2"
    }
  ],
  "type": "HelpObjectStoreArticleSearchResult"
};

(node as any).hash = "26c96a7b5e7c142faf3ebe704a3da4a1";

export default node;
