import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { graphql, useFragment } from 'react-relay';
import type { State } from 'state';
import { isLoggedIn as isUserLoggedIn } from 'state/selectors/user';
import { useExternalLink } from 'view/external-links/use-external-link';
import { Box } from '@atlaskit/primitives';
import type { articleMetadataFragmentV2$key } from './__generated__/articleMetadataFragmentV2.graphql';
import messages from './messages';

export interface Props {
    isLoggedIn: boolean;
    result: articleMetadataFragmentV2$key;
}

interface ArticleMetadata {
    lastModified: string;
}

export const ArticleMetadata = ({ isLoggedIn, result }: Props) => {
    di(useExternalLink);
    const { formatMessage } = useIntl();
    const data = useFragment<articleMetadataFragmentV2$key>(
        graphql`
            fragment articleMetadataFragmentV2 on HelpObjectStoreArticleSearchResult {
                ari
                displayLink
                containerName
                metadata {
                    isExternal
                }
            }
        `,
        result
    );

    const { ari, displayLink, metadata, containerName } = data;
    const { isExternal } = metadata;

    const {
        data: { source },
    } = useExternalLink({
        isLoggedIn,
        link: displayLink,
    });

    const confluencePageText = containerName
        ? formatMessage(messages.confluencePage, {
              value: containerName,
          })
        : '';
    const showSeparator = source && containerName;
    const externalResourceText = `${source ? source : ''} ${showSeparator ? `•` : ''} ${containerName ? containerName : ''}`;
    const metaData = !isExternal ? confluencePageText : externalResourceText;

    return <Box key={ari}>{metaData}</Box>;
};

const ConnectedArticleMetadata = connect((state: State) => {
    return {
        isLoggedIn: isUserLoggedIn(state),
    };
})(ArticleMetadata);

export default ConnectedArticleMetadata;
