import React from 'react';
import { compose } from 'redux';
import { isIncreasedFlexibilityEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import type { Route } from 'react-resource-router';
import { SplashLayoutRoute } from 'view/layout';
import { PrefetchBrandingModel } from 'view/layout/prefetch-branding-model';
import { authPageMessage, callToActionUrlText } from 'view/portal-reorder-experience';
import { RouteName, RoutePath, ScreenName } from '@atlassian/help-center-common-component/constants';
import { withOnlyJiraAdmin } from '@atlassian/help-center-common-component/with-only-jira-admin';
import { withOnlyLoggedIn } from '@atlassian/help-center-common-component/with-only-logged-in';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { EditCustomPage, ViewCustomPage } from '@atlassian/help-center-custom-pages';
import { brandingResource } from '../advanced-help-center/resources/multi-help-centers';
import EditCustomPageResource from './resources/edit-custom-page';
import { ViewCustomPageResource } from './resources/view-custom-page/resources';

export const getViewCustomPageRoute = (
    name: string = RouteName.VIEW_CUSTOM_PAGE,
    path: string = RoutePath.VIEW_CUSTOM_PAGE
) => {
    const viewCustomPageSSRResources = [brandingResource, ViewCustomPageResource];
    return createRouteWithLayout(
        {
            name,
            path,
            exact: true,
            component: ViewCustomPage,
            resources: __SERVER__ ? viewCustomPageSSRResources : [ViewCustomPageResource],
        },
        ScreenName.VIEW_CUSTOM_PAGE,
        {
            paperAppearance: 'none',
            fadeInSearchModal: true,
            hideSearchButton: true,
            hideBanner: true,
            removeHeader: true,
            prefetchBrandingModel: true,
        }
    );
};

interface Path {
    location: {
        pathname: string;
    };
}

export const EditCustomPageLayout = (props: Path) => {
    const { formatMessage } = useIntl();
    const WrappedComponent = compose(
        withOnlyLoggedIn({
            redirectUrl: props.location.pathname,
        }),
        withOnlyJiraAdmin({
            message: formatMessage(authPageMessage),
            callToActionText: formatMessage(callToActionUrlText),
            callToActionUrl: '/',
        })
    )(() => (
        <SplashLayoutRoute
            path={RoutePath.EDIT_CUSTOM_PAGE}
            component={EditCustomPage}
            screenName={ScreenName.EDIT_CUSTOM_PAGE}
            isFooterHidden={isIncreasedFlexibilityEnabled()}
        />
    ));

    return (
        <>
            <PrefetchBrandingModel />
            <WrappedComponent {...props} />
        </>
    );
};

export const getEditCustomPageRoute = (
    name: string = RouteName.EDIT_CUSTOM_PAGE,
    path: string = RoutePath.EDIT_CUSTOM_PAGE
) => {
    const editHomePageRoute: Route = {
        name,
        path,
        exact: true,
        component: React.memo(EditCustomPageLayout),
        resources: [EditCustomPageResource],
    };
    return editHomePageRoute;
};
