import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getCanAdministerJIRA } from '@helpCenter/state/selectors/user';
import type { State } from 'state';
import type { ReqTypesState, ReqGroupsState } from 'state/persisted/portal';
import { getPortals } from 'state/selectors/featured-and-sorted-portals';
import {
    getCanAdministerProject,
    getIsProjectSimplified,
    getPortalRequestGroups,
    getPortalRequestTypes,
    getProjectKey,
} from 'state/selectors/portal';
import type { CardLinkAppearance } from '@atlassian/help-center-common-component/group-box-select';
import { FormPickerDumb } from './form-picker-dumb';

export interface ExternalProps {
    portalId: number | undefined;
    cardLinkAppearance?: CardLinkAppearance;
    isEmbeddedRoute?: boolean;
}

const getRequestTypes: (state: State, props: ExternalProps) => ReqTypesState[] = (state, props) =>
    getPortalRequestTypes(state, props.portalId);

const getRequestGroups: (state: State, props: ExternalProps) => ReqGroupsState[] = (state, props) =>
    getPortalRequestGroups(state, props.portalId);

const getIsSimplifiedPortal: (state: State, props: ExternalProps) => boolean = (state, props) =>
    Boolean(getIsProjectSimplified(state, props.portalId));

const getPortalProjectKey: (state: State, props: ExternalProps) => string = (state, props) => {
    const projectKey = getProjectKey(state, props?.portalId);
    return projectKey?.toString() || '';
};

const getIsProjectAdmin: (state: State, props: ExternalProps) => boolean = (state, props) => {
    return Boolean(getCanAdministerProject(state, props.portalId));
};

const getIsJiraAdmin: (state: State, props: ExternalProps) => boolean = (state, _) => {
    return Boolean(getCanAdministerJIRA(state));
};

const getRequestTypeNameById = (id: string, requestTypes: ReqTypesState[]) => {
    const requestType = requestTypes.find((reqType) => reqType.id === id);
    return requestType ? { name: requestType.name, id: requestType.id } : undefined;
};

const getRequestTypeNames = (groupReqTypes: string[] = [], portalReqGroups: ReqTypesState[]) =>
    groupReqTypes.map((id: string) => getRequestTypeNameById(id, portalReqGroups)).filter(Boolean);

const populateRequestTypeNames = (portalReqTypes: ReqTypesState[]) => (requestGroup: ReqGroupsState) => ({
    ...requestGroup,
    reqTypes: getRequestTypeNames(requestGroup.reqTypes, portalReqTypes),
});

const selector = createSelector(
    getRequestGroups,
    getRequestTypes,
    getPortals,
    getIsSimplifiedPortal,
    getPortalProjectKey,
    getIsProjectAdmin,
    getIsJiraAdmin,
    (requestGroups, requestTypes, allPortals, isProjectSimplified, projectKey, isProjectAdmin, isJiraAdmin) => ({
        requestTypes,
        isProjectSimplified,
        projectKey,
        isProjectAdmin,
        isJiraAdmin,
        requestGroups: requestGroups.map(populateRequestTypeNames(requestTypes)),
        // displayLinkWhenNoGroups will only return true if portals data is already populated. If this page is accessed by direct link, it'll return false.
        // As it's such an edge case, it was decided that's enough.
        displayLinkWhenNoGroups: Object.keys(allPortals).length > 1,
    })
);

export default connect(selector)(FormPickerDumb);
