import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { sanitize } from 'dompurify';
import { isIsomorphicDompurifyLibUsed } from 'feature-flags';
import { sanitize as isomorphicSanitize } from 'isomorphic-dompurify';
import { graphql, useFragment } from 'react-relay';
import { usePathParam } from 'react-resource-router';
import type { State } from 'state';
import { getThemeColor } from 'state/selectors/help-center';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import { Inline, xcss, Text, Box } from '@atlaskit/primitives';
import { Link } from '@atlassian/help-center-common-component/analytics/link';
import { urlRefsAndTarget } from '@atlassian/help-center-common-util/is-external-resource';
import { formatText, sendSearchUIAnalyticEvents, useVisibilityState } from '../common';
import { ResourceIcon } from '../resource-icon';
import type { portalItemFragmentV2$key } from './__generated__/portalItemFragmentV2.graphql';

export interface Props {
    itemOrder: number;
    term: string;
    showAllResults?: boolean;
    themeColor: string;
    result: portalItemFragmentV2$key;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const PortalItem = ({ itemOrder, result, term, themeColor, createAnalyticsEvent }: Props) => {
    const data = useFragment<portalItemFragmentV2$key>(
        graphql`
            fragment portalItemFragmentV2 on HelpObjectStorePortalSearchResult {
                __typename
                id
                title
                displayLink
                metadata {
                    searchStrategy
                }
            }
        `,
        result
    );
    const { title, displayLink, id, metadata } = data;
    const { searchStrategy } = metadata;
    const [portalId] = usePathParam('portalId');
    const { setIsHovered, setIsFocused } = useVisibilityState();
    const highlightedTitle = title ? formatText(title, term) : '';
    const linkProps = urlRefsAndTarget({ displayLink, isAnExternalResource: false });

    useEffect(() => {
        sendSearchUIAnalyticEvents(
            {
                searchAlgorithm: searchStrategy,
                action: 'list-item-rendered',
                resourceType: 'portal',
            },
            createAnalyticsEvent,
            portalId
        );
    }, [createAnalyticsEvent, portalId, searchStrategy]);

    return (
        <Box
            xcss={containerStyles}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocusCapture={() => setIsFocused(true)}
            onBlurCapture={() => setIsFocused(false)}
        >
            <Inline xcss={headingStyles} space="space.100">
                <ResourceIcon entityType="PORTAL" displayLink={displayLink} />
                <Link
                    key={id}
                    actionSubjectId={'portal-link'}
                    data-test-id="result-link-container"
                    aria-label={title}
                    attributes={{
                        itemOrder,
                        searchAlgorithm: searchStrategy,
                    }}
                    {...linkProps}
                >
                    <Text as="p">
                        <span
                            style={{
                                color: themeColor,
                            }}
                            dangerouslySetInnerHTML={{
                                __html: isIsomorphicDompurifyLibUsed()
                                    ? isomorphicSanitize(highlightedTitle)
                                    : sanitize(highlightedTitle),
                            }}
                        />
                    </Text>
                </Link>
            </Inline>
        </Box>
    );
};

const ConnectedPortalItem = connect((state: State) => {
    return {
        themeColor: getThemeColor(state),
    };
})(PortalItem);

export default withAnalyticsEvents()(ConnectedPortalItem);

const containerStyles = xcss({
    overflow: 'hidden',
    width: '100%',
    padding: 'space.100',
    gap: 'space.150',
    borderRadius: 'border.radius.100',
});
const headingStyles = xcss({
    overflow: 'hidden',
    width: '100%',
});
