/**
 * @generated SignedSource<<cb3d199ffa324cbb554f4c5daac56959>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type HelpObjectStorePortalSearchStrategy = "JIRA" | "SEARCH_PLATFORM" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type portalItemFragmentV2$data = {
  readonly __typename: "HelpObjectStorePortalSearchResult";
  readonly displayLink: string;
  readonly id: string;
  readonly metadata: {
    readonly searchStrategy: HelpObjectStorePortalSearchStrategy;
  };
  readonly title: string;
  readonly " $fragmentType": "portalItemFragmentV2";
};
export type portalItemFragmentV2$key = {
  readonly " $data"?: portalItemFragmentV2$data;
  readonly " $fragmentSpreads": FragmentRefs<"portalItemFragmentV2">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "portalItemFragmentV2",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "title"
    },
    {
      "kind": "ScalarField",
      "name": "displayLink"
    },
    {
      "concreteType": "HelpObjectStorePortalMetadata",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "searchStrategy"
        }
      ]
    }
  ],
  "type": "HelpObjectStorePortalSearchResult"
};

(node as any).hash = "e844983974d9732ca3ef706885375f3f";

export default node;
