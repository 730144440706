import { defineMessages } from 'react-intl-next';

export default defineMessages({
    emptyConfigPanelHeading: {
        defaultMessage: 'Edit page layout',
        description: 'Heading to be displayed when no section is selected in the layout builder',
        id: 'cv.portal.customization.config.panel.empty.heading',
    },
    emptyConfigPanelText: {
        defaultMessage:
            'Pages are made of horizontal blocks called <b>sections</b> to which you can add <b>rich content.</b> Select an existing section or add a new one to get started.',
        description: 'Text to be displayed when no section is selected in the layout builder',
        id: 'cv.portal.customization.config.panel.empty.text',
    },
    emptyConfigPanelImageAltText: {
        defaultMessage: 'The config panel is empty',
        description: 'Alt text for image to be displayed when no section is selected in the layout builder',
        id: 'cv.portal.customization.config.panel.empty.image.alt',
    },
});
