import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { isSearchUIRedesignEnabled, isProjectMappingInHelpCenterEnabled } from 'feature-flags';
import { LazySuspense } from 'react-loosely-lazy';
import { di } from 'react-magnetic-di';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { usePathParam, useRouter } from 'react-resource-router';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { Grid, media, Stack, xcss } from '@atlaskit/primitives';
import { isCSMHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { useIsoMorphicLayoutEffect } from '@atlassian/help-center-common-util/hooks';
import { getCloudId, getHelpCenterAri } from '@atlassian/help-center-common-util/meta';
import useDebouncedValue from '@atlassian/help-center-common-util/use-debounced-value';
import { Articles } from '../articles';
import type { articlesFragment$key } from '../articles/__generated__/articlesFragment.graphql';
import {
    ALL_RESULTS_COUNT,
    DEFAULT_RESULTS_COUNT,
    SEARCH_QUERY_DEBOUNCE_TIME,
    useQueryParams,
    getIdsArray,
    isSinglePortalSearch,
} from '../common';
import { csmSearchExperience, searchExperience } from '../experiences';
import { ArticleResultsLoader, NewSearchResultsLoader, SearchResultsLoader, SidePanelResultsLoader } from '../loading';
import { SearchResultNone } from '../none';
import { Portals } from '../portals';
import type { portalsFragment$key } from '../portals/__generated__/portalsFragment.graphql';
import { RequestForms } from '../request-forms';
import type { requestFormsFragment$key } from '../request-forms/__generated__/requestFormsFragment.graphql';
import type { searchResultArticlesAndPortalsQuery } from './__generated__/searchResultArticlesAndPortalsQuery.graphql';
import type { searchResultRequestFormQuery } from './__generated__/searchResultRequestFormQuery.graphql';

export interface ResourceProps {
    term: string;
    articlesData: articlesFragment$key;
    portalsData: portalsFragment$key;
    requestFormData: requestFormsFragment$key;
    showAllResults?: boolean;
    updateResultsCount: (count: number) => void;
}

export const Resources = ({
    term,
    articlesData,
    portalsData,
    requestFormData,
    showAllResults,
    updateResultsCount,
}: ResourceProps) => {
    di(getEnv, isCSMHelpCenter, isSearchUIRedesignEnabled);

    const isCSM = isCSMHelpCenter(getEnv().helpCenterType);
    const [{ location }] = useRouter();
    const onlyRenderArticles = isSearchUIRedesignEnabled()
        ? isCSM
        : location?.pathname?.includes('/articles/search') || isCSM;
    const renderArticles = () => (
        <Articles
            term={term}
            result={articlesData}
            showAllResults={showAllResults}
            updateResultsCount={updateResultsCount}
        />
    );
    const renderPortals = () => <Portals term={term} result={portalsData} updateResultsCount={updateResultsCount} />;
    const renderRequestForms = () => (
        <RequestForms term={term} result={requestFormData} updateResultsCount={updateResultsCount} />
    );

    if (isSearchUIRedesignEnabled()) {
        if (onlyRenderArticles) {
            return <LazySuspense fallback={<ArticleResultsLoader />}>{renderArticles()}</LazySuspense>;
        }
        return (
            <Grid gap="space.400" templateColumns="1fr" xcss={responsiveStyles}>
                <LazySuspense fallback={<ArticleResultsLoader />}>{renderArticles()}</LazySuspense>

                <Stack space="space.100" alignBlock="start">
                    <LazySuspense fallback={<SidePanelResultsLoader />}>{renderRequestForms()}</LazySuspense>
                    <LazySuspense fallback={<SidePanelResultsLoader />}>{renderPortals()}</LazySuspense>
                </Stack>
            </Grid>
        );
    }
    if (onlyRenderArticles) {
        return <LazySuspense fallback={<SearchResultsLoader numberOfSections={1} />}>{renderArticles()}</LazySuspense>;
    }

    return (
        <>
            <LazySuspense fallback={<SearchResultsLoader numberOfSections={1} />}>{renderArticles()}</LazySuspense>
            <LazySuspense fallback={<SearchResultsLoader numberOfSections={1} />}>{renderPortals()}</LazySuspense>
            <LazySuspense fallback={<SearchResultsLoader numberOfSections={1} />}>{renderRequestForms()}</LazySuspense>
        </>
    );
};

export interface Props {
    term: string;
    showAllResults?: boolean;
}

type SearchResultProps = Props & WithAnalyticsEventsProps;

function useParallelQuery(
    cloudId: string,
    queryTerm: string,
    portalIds: string[],
    categoryIds: string[],
    resultLimit: number,
    helpCenterAri?: string
): {
    articlesFragment: articlesFragment$key;
    portalsFragment: portalsFragment$key;
    requestFormFragment: requestFormsFragment$key;
} {
    const ARTICLES_AND_PORTALS_QUERY = graphql`
        query searchResultArticlesAndPortalsQuery(
            $cloudId: ID!
            $queryTerm: String!
            $resultLimit: Int
            $portalIds: [ID!]
            $categoryIds: [ID!]
            $helpCenterAri: String
        ) {
            helpObjectStore(cloudId: $cloudId) @required(action: THROW) {
                __typename
                ...articlesFragment
                ...portalsFragment
            }
        }
    `;

    const REQUEST_FORM_QUERY = graphql`
        query searchResultRequestFormQuery(
            $cloudId: ID!
            $queryTerm: String!
            $resultLimit: Int
            $portalIds: [ID!]
            $helpCenterAri: String
        ) {
            helpObjectStore(cloudId: $cloudId) @required(action: THROW) {
                __typename
                ...requestFormsFragment
            }
        }
    `;

    const articlesAndPortalsData = useLazyLoadQuery<searchResultArticlesAndPortalsQuery>(
        ARTICLES_AND_PORTALS_QUERY,
        {
            cloudId,
            queryTerm,
            portalIds,
            categoryIds,
            resultLimit,
            helpCenterAri,
        },
        { fetchPolicy: 'store-or-network' }
    );

    const requestFormData = useLazyLoadQuery<searchResultRequestFormQuery>(
        REQUEST_FORM_QUERY,
        {
            cloudId,
            queryTerm,
            portalIds,
            resultLimit,
        },
        { fetchPolicy: 'store-or-network' }
    );

    return {
        articlesFragment: articlesAndPortalsData.helpObjectStore,
        portalsFragment: articlesAndPortalsData.helpObjectStore,
        requestFormFragment: requestFormData.helpObjectStore,
    };
}

interface SearchParameters {
    cloudId: string;
    portalId?: string;
    portalIds: string[];
    categoryIds: string[];
    resultLimit: number;
    getSelectedPortalIds: () => string[];
}

const useExtractSearchParameters = ({
    showAllResults,
    CSMPortalId,
}: {
    showAllResults?: boolean;
    CSMPortalId: number | undefined;
}): SearchParameters => {
    const cloudId = useMemo(() => getCloudId(), []);
    const {
        portalIds,
        categoryIds,
        resultLimit = DEFAULT_RESULTS_COUNT,
    } = useQueryParams() as {
        portalIds: string[];
        categoryIds: string[];
        resultLimit?: number;
    };
    const [portalId] = usePathParam('portalId');
    const getSelectedPortalIds = () => {
        if (CSMPortalId) return getIdsArray(CSMPortalId);
        if (isSinglePortalSearch(portalId)) return getIdsArray(portalId);
        return getIdsArray(portalIds);
    };

    return {
        cloudId,
        portalIds,
        categoryIds,
        portalId,
        getSelectedPortalIds,
        resultLimit: showAllResults ? ALL_RESULTS_COUNT : resultLimit,
    };
};

interface ResourceWrapperProps {
    term: string;
    showAllResults?: boolean;
    updateResultsCount: (count: number) => void;
    searchParams: SearchParameters;
}

const ParallelQueryWrapperOnResource = ({
    term,
    updateResultsCount,
    showAllResults,
    searchParams,
}: ResourceWrapperProps) => {
    const { articlesFragment, portalsFragment, requestFormFragment } = useParallelQuery(
        searchParams.cloudId,
        term,
        searchParams.getSelectedPortalIds(),
        searchParams.categoryIds,
        searchParams.resultLimit,
        isProjectMappingInHelpCenterEnabled() ? getHelpCenterAri() : undefined
    );
    return (
        <Resources
            term={term}
            articlesData={articlesFragment}
            portalsData={portalsFragment}
            requestFormData={requestFormFragment}
            showAllResults={showAllResults}
            updateResultsCount={updateResultsCount}
        />
    );
};

export const SearchResult = ({ term, showAllResults }: SearchResultProps) => {
    di(getEnv, isCSMHelpCenter, isSearchUIRedesignEnabled);

    const isCSM = isCSMHelpCenter(getEnv().helpCenterType);
    const CSMPortalId = isCSM ? getEnv().hoistedPortalId : undefined;
    const searchParams = useExtractSearchParameters({ showAllResults, CSMPortalId });
    const [totalCount, setTotalCount] = useState(-1);
    const resultsCount = useRef(-1);
    const callBackCount = useRef(0);
    const debouncedTerm = useDebouncedValue(term, SEARCH_QUERY_DEBOUNCE_TIME);
    const numberOfSections = isCSM ? 1 : 3;

    // Every time this callback will be called, that resource will increment callBackCount through acknowledgement
    const updateResultsCount = useCallback((count: number, acknowledgement?: number) => {
        if (resultsCount.current < 0) resultsCount.current = 0;

        resultsCount.current += count;

        if (acknowledgement) callBackCount.current += acknowledgement;

        // Update state only when articles, portals and requestForms all have updated the results
        if (callBackCount.current === 3) {
            // Since callbackCount will persist across states, setting it back to 0
            callBackCount.current = 0;
            setTotalCount(resultsCount.current);
        }
    }, []);

    useIsoMorphicLayoutEffect(() => {
        // Reset count to initial state on term change
        resultsCount.current = -1;
        setTotalCount(-1);
        callBackCount.current = 0;
    }, [debouncedTerm]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        isCSM && csmSearchExperience.start();

        return () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            isCSM && csmSearchExperience.abort();
        };
    }, [debouncedTerm, isCSM]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        searchExperience.start();

        return () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            searchExperience.abort();
        };
    }, [debouncedTerm]);

    const isEmptyResultsState = totalCount === 0;
    const isEmptyQueryState = debouncedTerm === '';
    const showEmptyState = isSearchUIRedesignEnabled() ? isEmptyQueryState || isEmptyResultsState : isEmptyResultsState;
    const fallback = isSearchUIRedesignEnabled() ? (
        <NewSearchResultsLoader />
    ) : (
        <SearchResultsLoader numberOfSections={numberOfSections} />
    );

    return (
        <LazySuspense fallback={fallback}>
            {showEmptyState && <SearchResultNone term={debouncedTerm} />}
            {!isEmptyResultsState && (
                <ParallelQueryWrapperOnResource
                    term={debouncedTerm}
                    searchParams={searchParams}
                    showAllResults={showAllResults}
                    updateResultsCount={updateResultsCount}
                />
            )}
        </LazySuspense>
    );
};

const responsiveStyles = xcss({
    [media.above.xxs]: { gridTemplateColumns: '1fr' },
    [media.above.md]: {
        gridTemplateColumns: '638px 287px',
    },
});
