import { RouteName, RoutePath, ScreenName } from 'view/common/constants';
import type { PaperAppearance } from 'view/layout/paper';
import { CsmContactUs } from '@atlassian/csm-contact-us';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';

const contactUs = {
    resources: [],
    name: RouteName.CSM_CONTACT_US_PAGE,
    path: RoutePath.CSM_CONTACT_US_PATH,
    exact: true,
    component: CsmContactUs,
};

const layoutProps = {
    isAnonymous: false,
    hideSearchButton: true,
    paperAppearance: 'wide' as PaperAppearance,
    fadeInSearchModal: true,
    prefetchBrandingModel: true,
};

export const csmContactUsRoute = createRouteWithLayout(contactUs, ScreenName.CSM_CONTACT_US, layoutProps);
