import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchCategoriesAction } from 'epics/model/kb-category';
import { fetchUserModelAction } from 'epics/model/user';
import { di } from 'react-magnetic-di';
import type { RouteContext } from 'react-resource-router';
import type { Category } from 'rest/category';
import type { PortalAnnouncement } from 'rest/portal';
import type { State } from 'state';
import { fetchPortalAction } from 'state/actions/portal';
import { fetchRequestCreateAction } from 'state/actions/request-create';
import { getCanCustomize, getHelpCenterBrandingLoader } from 'state/selectors/help-center';
import {
    getPortalOrError,
    getPortalAnnouncement,
    getProjectId,
    getPortalRequestType,
    getName,
    getIsKbEnabled,
    getCategories,
    getCanAdministerProject,
    getIsProjectSimplified,
    getIsRequestTypeBelongsToGroup,
} from 'state/selectors/portal';
import { getRequestCreate } from 'state/selectors/request-create';
import { isAtlassianAccountManaged, isLoggedIn as isLoggedInSelector } from 'state/selectors/user';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsEvents, withAnalyticsContext } from '@atlaskit/analytics-next';
import { TRACK_EVENT_TYPE, OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { Experience } from '@atlassian/help-center-common-component/analytics';
import { ScreenName, ExperienceName } from '@atlassian/help-center-common-component/constants';
import { AnalyticsTrackActions } from '@atlassian/help-center-common-util/analytics';
import { AnalyticsScreen } from '@atlassian/help-center-common-util/analytics/analytics-screen';
import { pathname } from '@atlassian/help-center-common-util/location';
import { toNumber } from '@atlassian/help-center-common-util/number';
import { clearSidebarExperienceAndCustomize } from '@atlassian/help-center-common-util/sidebar-experience';
import { UiModificationsContainer } from '@atlassian/jsm-ui-modifications-view-request-create/src/ui/container';
import { ScreenErrorBoundary } from '../layout/screen-error-boundary';
import { PortalHomeDumb } from './portal-home-dumb';
import type { PersistedError } from 'state/persisted/types';

type ExternalProps = Omit<RouteContext, 'action' | 'query' | 'route'>;

interface TStateProps {
    portalName: string | undefined;
    requestTypeName: string | undefined;
    isLoading: boolean;
    kbEnabled: boolean;
    error: PersistedError | undefined;
    announcement: PortalAnnouncement | undefined;
    canEditAnnouncement?: boolean;
    categoriesLoaded: boolean;
    categories: Category[] | undefined;
    projectId: number | undefined;
    portalId: number | undefined;
    requestTypeId: number | undefined;
    requestGroupId: number | undefined;
    isProjectSimplified: boolean;
    canCustomiseHelpCenter: boolean;
    isProjectAdmin: boolean;
    isAtlassianManagedAccount: boolean;
    isLoggedIn: boolean;
    requestTypeBelongsToGroup: boolean;
    requestCreateModelLoaded: boolean;
    isBrandingLoaded?: boolean;
}

interface Props extends TStateProps, ExternalProps, WithAnalyticsEventsProps {
    fetchPortal: typeof fetchPortalAction;
    fetchUser: typeof fetchUserModelAction;
    fetchCategories: typeof fetchCategoriesAction;
    fetchRequestCreate: typeof fetchRequestCreateAction;
}

interface PortalHomeState {
    sideBarOpen: boolean;
}

export const checkIfSamePage = (screenLocation: string) => pathname().endsWith(screenLocation);

export class PortalHome extends React.Component<Props, PortalHomeState> {
    state = {
        sideBarOpen: false,
    };

    tryFetchCategories = () => {
        const { portalId, kbEnabled, projectId, categoriesLoaded, fetchCategories } = this.props;

        if (kbEnabled && projectId && portalId && !categoriesLoaded) {
            const meta = this.getAnalyticsEvents();
            fetchCategories(projectId, portalId, meta);
        }
    };

    getAnalyticsEvents = () => {
        const { createAnalyticsEvent } = this.props;

        return {
            analyticsSuccessEvent: createAnalyticsEvent?.({
                action: 'fetchCategories success',
                analyticsType: TRACK_EVENT_TYPE,
                actionSubject: 'PortalHome',
            }),
            analyticsFailureEvent: createAnalyticsEvent?.({
                action: 'fetchCategories error',
                analyticsType: OPERATIONAL_EVENT_TYPE,
            }),
        };
    };

    getViewSinglePortalAnalyticsEvents = () => {
        const { createAnalyticsEvent } = this.props;
        if (createAnalyticsEvent) {
            return {
                analyticsSuccessEvent: createAnalyticsEvent({
                    action: AnalyticsTrackActions.FETCHED,
                    analyticsType: TRACK_EVENT_TYPE,
                    actionSubject: 'PortalHome',
                }),
                analyticsFailureEvent: createAnalyticsEvent({
                    action: AnalyticsTrackActions.FETCHED_FAILURE,
                    analyticsType: TRACK_EVENT_TYPE,
                    actionSubject: 'PortalHome',
                }),
            };
        }

        return {};
    };

    componentDidMount() {
        const { portalId, fetchPortal, fetchUser, fetchRequestCreate, requestTypeId, categories } = this.props;

        const analytics = this.getViewSinglePortalAnalyticsEvents();

        if (portalId) {
            fetchPortal(
                {
                    id: portalId,
                    expand: ['kbs', 'orderMapping', 'reqGroups', 'reqTypes', 'analyticsContext', 'categories'],
                },
                analytics
            );

            if (!this.props.requestCreateModelLoaded && requestTypeId) {
                fetchRequestCreate({ portalId, id: requestTypeId });
            }
            // FSD-2541: required to re-fetch user model inorder to send correct analytics remove in clean-up task FSD-2881
            fetchUser();

            if (!categories) {
                this.tryFetchCategories();
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { projectId, requestTypeId, portalId, fetchRequestCreate, kbEnabled } = this.props;
        const projectUpdated = prevProps.projectId !== projectId;
        const kbEnabledUpdated = !prevProps.kbEnabled && kbEnabled;
        const requestTypeUpdated = prevProps.requestTypeId !== requestTypeId;
        const portalUpdated = prevProps.portalId !== portalId;

        if (projectUpdated || kbEnabledUpdated) {
            this.tryFetchCategories();
        }

        if (portalId && requestTypeId && (portalUpdated || requestTypeUpdated)) {
            fetchRequestCreate({ portalId, id: requestTypeId });
        }
    }

    toggleSideBar = (value?: boolean) => {
        this.setState({ sideBarOpen: value !== undefined ? value : !this.state.sideBarOpen });

        // this.props.location.pathname is used to check if the user is on the same page
        if (value === false && checkIfSamePage(this.props.location.pathname)) {
            clearSidebarExperienceAndCustomize();
        }
    };

    getScreenName() {
        const { requestTypeId, requestGroupId } = this.props;

        let screenName;
        if (requestTypeId) {
            screenName = ScreenName.REQUEST_CREATE;
        } else if (requestGroupId === -1) {
            screenName = ScreenName.REQUEST_GROUP_LIST;
        } else if (requestGroupId) {
            screenName = ScreenName.REQUEST_GROUP;
        } else {
            screenName = ScreenName.PORTAL_HOME;
        }

        return screenName;
    }

    render() {
        di(UiModificationsContainer);

        const { sideBarOpen } = this.state;
        const {
            portalName,
            requestTypeName,
            isLoading,
            error,
            announcement,
            canEditAnnouncement,
            portalId,
            projectId,
            categories,
            requestTypeId,
            requestGroupId,
            isProjectSimplified,
            isAtlassianManagedAccount,
            isProjectAdmin,
            canCustomiseHelpCenter,
            requestTypeBelongsToGroup,
            isBrandingLoaded,
            isLoggedIn,
        } = this.props;
        const screenName = this.getScreenName();

        return (
            <AnalyticsScreen screenName={screenName} screenId={requestTypeId || requestGroupId || portalId}>
                <ScreenErrorBoundary screenName={screenName}>
                    <Experience name={ExperienceName.REQUEST_CREATE}>
                        <UiModificationsContainer>
                            <PortalHomeDumb
                                portalName={portalName}
                                portalId={portalId}
                                projectId={projectId}
                                requestTypeName={requestTypeName}
                                requestTypeId={requestTypeId}
                                requestGroupId={requestGroupId}
                                loading={isLoading}
                                sideBarOpen={sideBarOpen}
                                toggleSidebar={this.toggleSideBar}
                                announcement={announcement}
                                canEditAnnouncement={canEditAnnouncement}
                                categories={categories}
                                isProjectSimplified={isProjectSimplified}
                                canCustomiseHelpCenter={canCustomiseHelpCenter}
                                isProjectAdmin={isProjectAdmin}
                                isAtlassianManagedAccount={isAtlassianManagedAccount}
                                requestTypeBelongsToGroup={requestTypeBelongsToGroup}
                                isBrandingLoaded={isBrandingLoaded}
                                isLoggedIn={isLoggedIn}
                                screenName={screenName}
                                {...error}
                            />
                        </UiModificationsContainer>
                    </Experience>
                </ScreenErrorBoundary>
            </AnalyticsScreen>
        );
    }
}

const selector = createSelector(
    (state: State, props: ExternalProps) => {
        const portalIdAsString = props.match.params && props.match.params.portalId;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const portalId = toNumber(portalIdAsString!);
        const kbEnabled = portalId && getIsKbEnabled(state, portalId);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const requestTypeId = props.match.params && toNumber(props.match.params.requestTypeId!);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const requestGroupId = props.match.params && toNumber(props.match.params.requestGroupId!);
        const portalName = portalId ? getName(state, portalId) : undefined;
        const requestType =
            portalId && requestTypeId ? getPortalRequestType(state, portalId, requestTypeId) : undefined;
        const requestTypeName = requestType ? requestType.name : undefined;

        const requestTypeBelongsToGroup =
            portalId &&
            requestGroupId &&
            requestTypeId &&
            getIsRequestTypeBelongsToGroup(state, portalId, requestGroupId, requestTypeId);

        const requestCreateModelLoaded =
            portalId && requestTypeId && !!getRequestCreate(state, portalId, requestTypeId);

        return {
            portalId,
            portalName,
            kbEnabled,
            requestTypeId,
            requestGroupId,
            requestTypeName,
            requestTypeBelongsToGroup,
            requestCreateModelLoaded,
            isAtlassianManagedAccount: isAtlassianAccountManaged(state),
            isLoggedIn: isLoggedInSelector(state),
            categories: getCategories(state, portalId),
            portal: getPortalOrError(state, portalId),
            projectId: getProjectId(state, portalId),
            announcement: getPortalAnnouncement(state, portalId),
            isProjectAdmin: getCanAdministerProject(state, portalId),
            canCustomiseHelpCenter: getCanCustomize(state),
            isProjectSimplified: getIsProjectSimplified(state, portalId),
            isBrandingLoaded: getHelpCenterBrandingLoader(state),
        };
    },
    ({
        portalId,
        portal,
        announcement,
        requestTypeId,
        requestGroupId,
        portalName,
        requestTypeName,
        requestTypeBelongsToGroup,
        requestCreateModelLoaded,
        isAtlassianManagedAccount,
        projectId,
        kbEnabled,
        categories,
        isProjectAdmin,
        isLoggedIn,
        canCustomiseHelpCenter,
        isProjectSimplified,
        isBrandingLoaded,
    }): TStateProps => {
        // isLoading when portal is loading OR categories is loading with kb enabled
        const isLoading = Boolean(!portal || (kbEnabled && !categories));
        const categoriesOrUndefined = Array.isArray(categories) ? categories : undefined;

        return {
            portalId,
            portalName,
            requestTypeId,
            requestGroupId,
            requestTypeName,
            projectId,
            announcement,
            isLoading,
            canCustomiseHelpCenter,
            isAtlassianManagedAccount,
            isBrandingLoaded,
            isLoggedIn,
            requestTypeBelongsToGroup: Boolean(requestTypeBelongsToGroup),
            requestCreateModelLoaded: Boolean(requestCreateModelLoaded),
            kbEnabled: Boolean(kbEnabled),
            categories: categoriesOrUndefined,
            isProjectAdmin: Boolean(isProjectAdmin),
            isProjectSimplified: Boolean(isProjectSimplified),
            categoriesLoaded: !!categories,
            error: portal && 'error' in portal ? portal : undefined,
            canEditAnnouncement: announcement ? announcement.canEditAnnouncement : undefined,
        };
    }
);

export default connect(selector, {
    fetchPortal: fetchPortalAction,
    fetchUser: fetchUserModelAction,
    fetchCategories: fetchCategoriesAction,
    fetchRequestCreate: fetchRequestCreateAction,
})(withAnalyticsContext({ componentName: 'PortalHome' })(withAnalyticsEvents()(PortalHome)));
