import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchCategoriesAction } from '@helpCenter/epics/model/kb-category';
import type { State } from 'state';
import { getCategories } from 'state/selectors/portal';
import CategoryFilterComponent from './category-filter';

interface ExternalProps {
    portalId: number;
}

const selector = createSelector(
    (state: State, props: ExternalProps) => getCategories(state, props.portalId),
    (maybeCategories) => {
        const categories = Array.isArray(maybeCategories) ? maybeCategories : [];
        return {
            categories,
        };
    }
);

const CategoryFilter = connect(selector, {
    fetchCategories: fetchCategoriesAction,
})(CategoryFilterComponent);

export default CategoryFilter;
