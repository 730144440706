import React from 'react';
import { isReact18LazyEnabled } from 'feature-flags';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import type { LazyOptions, LooselyLazyOptions } from '../index';
import { LoadingComponent } from '../loading-component';

export const Fallback = <TProps extends object>(options: LazyOptions<TProps> | LooselyLazyOptions<TProps>) => {
    let displayName = 'unknown';
    if ('lazyLoader' in options && isReact18LazyEnabled()) {
        displayName = options.lazyLoader?.name || 'unknown';
    } else if ('loader' in options) {
        displayName = options.loader.displayName || 'unknown';
    }
    return !__SERVER__ ? (
        <UFOLoadHold name={displayName}>
            <LoadingComponent LoadingSkeleton={options.LoadingSkeleton} timeout={options.timeout} />
        </UFOLoadHold>
    ) : (
        <LoadingComponent LoadingSkeleton={options.LoadingSkeleton} timeout={options.timeout} />
    );
};
