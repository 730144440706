import { ASSISTANT_MESSAGE_STATUS } from '../../rest/converse';
import type { AssistantMessage } from '../../store/converse/types';

export const initialAssistantMessage = (answerId: number): AssistantMessage => ({
    aiMateConversationResponse: {
        message: {
            id: String(answerId),
            time_created: '',
            user_ari: '',
            role: 'assistant',
            content: '',
            plugin_invocations: [],
        },
    },
    userType: 'assistant',
    messageMetadata: {
        status: ASSISTANT_MESSAGE_STATUS.AWAITING_RESPONSE,
        messageId: answerId.toString(),
    },
});
