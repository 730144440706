/* eslint-disable rulesdir/do-not-manually-build-jsm-agent-view-url */
import React from 'react';
import { useIntl } from 'react-intl-next';
import { withAnalyticsContext, useAnalyticsEvents } from '@atlaskit/analytics-next';
import { LinkButton } from '@atlaskit/button/new';
import EditIcon from '@atlaskit/icon/core/edit';
import FolderOpenIcon from '@atlaskit/icon/core/folder-open';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import messages from '../messages';

interface CustomizeRequestTypesPortalButtonGroupProps {
    isProjectSimplified?: boolean;
    projectKey?: string;
}

const CustomizeRequestTypesPortalButtonGroup = (props: CustomizeRequestTypesPortalButtonGroupProps) => {
    const { formatMessage } = useIntl();
    const { isProjectSimplified = false, projectKey = '' } = props;
    const { createAnalyticsEvent } = useAnalyticsEvents();

    const onClickCustomizeRequestTypes = () => {
        createAnalyticsEvent({
            actionSubjectId: 'customizeRequestTypesButton',
            action: 'clicked',
            actionSubject: 'button',
            analyticsType: UI_EVENT_TYPE,
        }).fire();
    };

    const onClickManagaPortalGroups = () => {
        createAnalyticsEvent({
            actionSubjectId: 'managePortalGroupsButton',
            action: 'clicked',
            actionSubject: 'button',
            analyticsType: UI_EVENT_TYPE,
        }).fire();
    };

    return (
        <Flex gap="space.100" xcss={ButtonGroupWrapperStyles}>
            <Box xcss={ButtonStyles}>
                <LinkButton
                    href={`/jira/servicedesk/projects/${projectKey}/settings/${isProjectSimplified ? 'issuetypes' : 'request-types'}/`}
                    isDisabled={!projectKey.length}
                    appearance="subtle"
                    target="_blank"
                    testId="customize-request-type-button"
                    onClick={onClickCustomizeRequestTypes}
                >
                    <Inline xcss={ButtonTextStyles} space="space.075" alignBlock="center">
                        <EditIcon label={formatMessage(messages.customizeRequestTypesLabel)} spacing="spacious" />
                        {formatMessage(messages.customizeRequestTypesLabel)}
                    </Inline>
                </LinkButton>
            </Box>
            {!isProjectSimplified && (
                <Box xcss={ButtonStyles}>
                    <LinkButton
                        href={`/jira/servicedesk/projects/${projectKey}/settings/portal-settings/portal-groups/`}
                        isDisabled={!projectKey.length}
                        appearance="subtle"
                        target="_blank"
                        testId="manage-portal-groups-button"
                        onClick={onClickManagaPortalGroups}
                    >
                        <Inline xcss={ButtonTextStyles} space="space.075" alignBlock="center">
                            <FolderOpenIcon
                                label={formatMessage(messages.managePortalGroupsLabel)}
                                spacing="spacious"
                            />
                            {formatMessage(messages.managePortalGroupsLabel)}
                        </Inline>
                    </LinkButton>
                </Box>
            )}
        </Flex>
    );
};

const ButtonGroupWrapperStyles = xcss({
    fontSize: 'font.body',
});

const ButtonStyles = xcss({
    borderWidth: 'border.width',
    borderStyle: 'solid',
    borderColor: 'color.border',
    borderRadius: 'border.radius.100',
    height: 'fit-content',
});

const ButtonTextStyles = xcss({
    color: 'color.text.subtle',
});

export default withAnalyticsContext({ component: 'CustomizeRequestTypesPortalButtonGroup' })(
    CustomizeRequestTypesPortalButtonGroup
);
