import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import * as fonts from 'view/styles/fonts';
import { Box, xcss } from '@atlaskit/primitives';
import { HtmlAnchor } from '@atlassian/help-center-common-component/analytics/html-anchor';
import { Link } from '@atlassian/help-center-common-component/analytics/link';
import { ExternalThemedLink } from '@atlassian/help-center-common-component/external-themed-link';
import { ThemedLink } from '@atlassian/help-center-common-component/themed-link';

interface Props {
    to: string;
    name: string | undefined; // If the name is not available then the crumb (and all its children) will not render
    isFirstCrumb?: boolean;
    actionSubjectId: string;
    testId?: string;
    disableTheme?: boolean;
    tabIndex?: number;
}

/**
 * This is a nestable bread crumb component, it will render the first component with a valid name.
 * It itself will also not render if the name is not valid.
 */
const BreadCrumbDumb: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    name,
    to,
    children,
    isFirstCrumb,
    actionSubjectId,
    testId,
    tabIndex,
    disableTheme = false,
}) => {
    if (!name) {
        return null;
    }

    let child: React.ReactElement<Props> | undefined;
    React.Children.forEach(children, (element: React.ReactElement<Props>) => {
        // Short circuit if there is already a match
        if (child) {
            return;
        }

        if (element && React.isValidElement(element)) {
            const { name: childName } = element.props;

            if (childName) {
                child = element;
            }
        }
    });
    let linkComponent;
    if (to.startsWith('/')) {
        const RootLinkComponent = disableTheme ? RootLinkDisabledTheme : RootLink;
        linkComponent = (
            <RootLinkComponent
                to={to}
                actionSubjectId={actionSubjectId}
                data-test-id={testId}
                aria-current={child ? 'false' : 'page'}
                tabIndex={tabIndex}
            >
                {name}
            </RootLinkComponent>
        );
    } else {
        const ExternalLinkComponent = disableTheme ? ExternalLinkDisabledTheme : ExternalLink;
        linkComponent = (
            <ExternalLinkComponent
                tabIndex={tabIndex}
                href={to}
                data-test-id={testId}
                actionSubjectId={actionSubjectId}
            >
                {name}
            </ExternalLinkComponent>
        );
    }

    return (
        <>
            <Box as={'li'} xcss={[breadCrumbContainer, isFirstCrumb ? undefined : breadCrumbSeparator]}>
                {linkComponent}
            </Box>
            {child}
        </>
    );
};

export default BreadCrumbDumb;

const breadCrumbContainer = xcss({
    display: 'flex',
    listStyle: 'none',
    alignItems: 'center',
    margin: 'space.0',
});

const breadCrumbSeparator = xcss({
    '::before': {
        width: '8px',
        flexShrink: 0,
        content: '"/"',
        paddingBlock: 'space.0',
        paddingInline: 'space.075',
        textAlign: 'center',
        lineHeight: '1.5em',
        color: 'color.text',
    },
});

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const RootLink = styled(ThemedLink)`
    ${fonts.h200};
    margin: 0;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ExternalLink = styled(ExternalThemedLink)`
    ${fonts.h200};
    margin: 0;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled
const RootLinkDisabledTheme = styled(Link)`
    margin: 0;
    /* stylelint-disable scale-unlimited/declaration-strict-value */
    color: unset !important;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled
const ExternalLinkDisabledTheme = styled(HtmlAnchor)`
    margin: 0;
    color: unset !important;
`;
