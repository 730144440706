import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { State } from '@helpCenter/state';
import { editingHelpCenter } from '@helpCenter/state/actions/help-center-preview';
import { customizingRequestListColumn } from '@helpCenter/state/actions/requests-column-settings';
import { getPortalId } from '@helpCenter/state/selectors/context';
import { getJsmAIConfigEnabled } from '@helpCenter/state/selectors/env';
import { getCanCustomize } from '@helpCenter/state/selectors/help-center';
import {
    getName,
    getPortalAnnouncement,
    getCanAdministerProject,
    getIsProjectSimplified,
    getProjectKey,
} from '@helpCenter/state/selectors/portal';
import { getRequestsColumnSettings } from '@helpCenter/state/selectors/requests-column-settings';
import { getCanAdministerJIRA } from '@helpCenter/state/selectors/user';

import { CustomiseMenuDumb } from '../customise-menu-dumb';

const selector = createSelector(
    (state: State) => {
        const portalId = getPortalId(state);
        const portal = {
            id: portalId,
            name: getName(state, portalId),
            canEditAnnouncement: Boolean(getPortalAnnouncement(state, portalId)?.canEditAnnouncement),
            isProjectAdmin: Boolean(getCanAdministerProject(state, portalId)),
            isProjectSimplified: Boolean(getIsProjectSimplified(state, portalId)),
        };

        return {
            portal,
            canAdministerJIRA: getCanAdministerJIRA(state),
            canCustomiseHelpCenter: getCanCustomize(state),
            defaultColumnSettings: getRequestsColumnSettings(state),
            jsmAIConfigEnabled: getJsmAIConfigEnabled(state),
            projectKey: getProjectKey(state, portalId),
        };
    },
    ({ portal, canAdministerJIRA, canCustomiseHelpCenter, defaultColumnSettings, jsmAIConfigEnabled, projectKey }) => ({
        portal,
        canAdministerJIRA,
        canCustomiseHelpCenter,
        defaultColumnSettings,
        jsmAIConfigEnabled,
        projectKey,
    })
);

export default connect(selector, {
    openHelpCenterCustomizationSidebar: () => editingHelpCenter(true),
    openRequestListCustomizationSidebar: () => customizingRequestListColumn(true),
})(CustomiseMenuDumb);
