import { isVAOnHelpCenterGAEnabled, isEditLayoutEnabledForCSM } from 'feature-flags';
import ViewHomepageQuery from 'view/advanced-help-center/view-homepage/__generated__/viewHomepageQuery.graphql';
import type { viewHomepageQuery } from 'view/advanced-help-center/view-homepage/__generated__/viewHomepageQuery.graphql';
import type { viewHomepageForCsmQuery } from 'view/customer-service/view-homepage/__generated__/viewHomepageForCsmQuery.graphql';
import ViewHomepageForCsmQuery from 'view/customer-service/view-homepage/__generated__/viewHomepageForCsmQuery.graphql';
import type { searchLoaderVirtualAgentEnabledQuery as searchLoaderVirtualAgentEnabledQueryV1 } from 'view/search/__generated__/searchLoaderVirtualAgentEnabledQuery.graphql';
import SearchLoaderVirtualAgentEnabledQueryV1 from 'view/search/__generated__/searchLoaderVirtualAgentEnabledQuery.graphql';
import { fg } from '@atlaskit/platform-feature-flags';
import { isCSMHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { createRelayResource } from '@atlassian/help-center-common-util/resource-router/resource-router';
import type { searchLoaderVirtualAgentEnabledV2Query } from '@atlassian/help-center-search/src/__generated__/searchLoaderVirtualAgentEnabledV2Query.graphql';
import SearchLoaderVirtualAgentEnabledV2Query from '@atlassian/help-center-search/src/__generated__/searchLoaderVirtualAgentEnabledV2Query.graphql';

export const ViewHomepageResource =
    isCSMHelpCenter(getEnv().helpCenterType) && isEditLayoutEnabledForCSM()
        ? createRelayResource<viewHomepageForCsmQuery>({
              type: 'VIEW_HOMEPAGE',
              getQuery: (_, { cloudId, helpCenterAri, layoutAri }) => {
                  return {
                      parameters: ViewHomepageForCsmQuery,
                      variables: {
                          cloudId,
                          helpCenterAri,
                          layoutAri,
                          filter: { isEditMode: false },
                      },
                      options: {
                          fetchPolicy: 'store-or-network',
                      },
                  };
              },
          })
        : createRelayResource<viewHomepageQuery>({
              type: 'VIEW_HOMEPAGE',
              getQuery: (_, { cloudId, helpCenterAri, layoutAri }) => {
                  return {
                      parameters: ViewHomepageQuery,
                      variables: {
                          cloudId,
                          helpCenterAri,
                          layoutAri,
                          filter: { isEditMode: false },
                      },
                      options: {
                          fetchPolicy: 'store-or-network',
                      },
                  };
              },
          });

export const searchComponentVirtualAgentResource = fg('helpcenter_search_api_changes')
    ? createRelayResource<searchLoaderVirtualAgentEnabledV2Query>({
          type: 'HELP_CENTER_SEARCH_VIRTUAL_AGENT',
          getQuery: (_, { cloudId, helpCenterAri, isUserLoggedIn }) => {
              return {
                  parameters: SearchLoaderVirtualAgentEnabledV2Query,
                  variables: {
                      cloudId,
                      helpCenterAri,
                      isVaOnHcGAEnabled: isVAOnHelpCenterGAEnabled() && isUserLoggedIn,
                  },
                  options: {
                      fetchPolicy: 'store-or-network',
                  },
              };
          },
      })
    : createRelayResource<searchLoaderVirtualAgentEnabledQueryV1>({
          type: 'HELP_CENTER_SEARCH_VIRTUAL_AGENT',
          getQuery: (_, { cloudId, helpCenterAri, isUserLoggedIn }) => {
              return {
                  parameters: SearchLoaderVirtualAgentEnabledQueryV1,
                  variables: {
                      cloudId,
                      helpCenterAri,
                      isVaOnHcGAEnabled: isVAOnHelpCenterGAEnabled() && isUserLoggedIn,
                  },
                  options: {
                      fetchPolicy: 'store-or-network',
                  },
              };
          },
      });
