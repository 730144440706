import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import { is1DFOCAQFeatureGateEnabled, isCORTOPSExperimentEnabled } from 'feature-flags';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl, FormattedMessage } from 'react-intl-next';

import type { ReqTypesState } from 'state/persisted/portal';
import { sizes } from 'view/styles/sizes-viewport';
import { token } from '@atlaskit/tokens';
import {
    CustomizeRequestTypesPortalButtonGroup,
    EditRequestTypeButton,
} from '@atlassian/help-center-common-component/cutomize-portal-buttons';
import type { CardLinkAppearance } from '@atlassian/help-center-common-component/group-box-select';
import { ThemedLink } from '@atlassian/help-center-common-component/themed-link';
import { FireScreenEventOnMount } from '@atlassian/help-center-common-util/analytics/fire-screen-event';
import { history } from '@atlassian/help-center-common-util/history';
import * as qs from '@atlassian/help-center-common-util/history-qs';
import { REFERRER_QUERY_PARAM } from '@atlassian/learn-by-doing-create-request';
import { RequestGroupSelect } from '../request-group-select';
import { RequestTypeSelect } from '../request-type-select';
import type { DerivedReqGroupsState } from '../types';
import i18n from './messages';
export interface FormPickerProps extends WrappedComponentProps {
    autoFocus?: boolean;
    isDisabled?: boolean;
    className?: string;
    hideLabels?: boolean;
    requestTypes: ReqTypesState[];
    requestGroups: DerivedReqGroupsState[];
    portalId: number | undefined;
    cardLinkAppearance?: CardLinkAppearance;
    requestGroupId?: number | undefined;
    requestTypeId?: number | undefined;
    displayLinkWhenNoGroups?: boolean;
    isEmbeddedRoute?: boolean;
    isCSM?: boolean;
    isProjectSimplified?: boolean;
    projectKey?: string;
    isProjectAdmin?: boolean;
    isJiraAdmin?: boolean;
}

export class FormPickerDumb extends React.Component<FormPickerProps> {
    static defaultProps = {
        // Required to explicitly set this to false by default as the <AutoFocus />
        // component will focus when it is `undefined`.
        autoFocus: false,
    };

    getRequestTypesForSelectedRequestGroup: (requestGroupId: number | undefined) => ReqTypesState[] = (
        requestGroupId
    ) => {
        const { requestTypes, requestGroups } = this.props;

        if (requestGroupId) {
            const selectedRequestGroup = requestGroups.find((requestGroup) => requestGroup.id === requestGroupId);
            if (selectedRequestGroup) {
                return selectedRequestGroup.reqTypes
                    .map((reqType) => requestTypes.find((rt) => rt.id === reqType.id))
                    .filter(Boolean) as ReqTypesState[];
            }
        }

        return [];
    };

    selectRequestGroup = (id?: number) => {
        // If id is undefined we push back to the /:portalId/group/-1 route.
        // This covers the case if there are article categories on the portal.
        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
        const referrer = qs.get(REFERRER_QUERY_PARAM);
        const shouldAddReferrer = referrer && referrer !== 'undefined' && is1DFOCAQFeatureGateEnabled();
        history.push({
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            pathname: `/portal/${this.props.portalId}/group/${id || '-1'}`,
            search: shouldAddReferrer ? `?${REFERRER_QUERY_PARAM}=${referrer}` : '',
        });
    };

    selectRequestType = (id?: number) => {
        const { requestGroups, portalId, requestGroupId } = this.props;

        const hasSingleRequestGroup = requestGroups.length === 1;
        const groupId = hasSingleRequestGroup ? requestGroups[0].id : requestGroupId;
        const referrer = qs.get(REFERRER_QUERY_PARAM);
        const shouldAddReferrer = referrer && referrer !== 'undefined' && is1DFOCAQFeatureGateEnabled();

        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.

        history.push({
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            pathname: `/portal/${portalId}/group/${groupId}${id ? `/create/${id}` : ''}`,
            search: shouldAddReferrer ? `?${REFERRER_QUERY_PARAM}=${referrer}` : '',
        });
    };

    getStyledRequestTypeSelectLabel = () => {
        const { hideLabels, intl, isCSM } = this.props;

        if (!!hideLabels) {
            return '';
        }

        if (isCSM) {
            return intl.formatMessage(i18n.CSMRequestTypeSelectLabel);
        }

        return intl.formatMessage(i18n.requestTypeSelectLabel);
    };

    render() {
        const {
            requestGroupId,
            requestTypeId,
            portalId,
            requestGroups,
            isDisabled,
            className,
            intl,
            hideLabels,
            autoFocus,
            cardLinkAppearance,
            displayLinkWhenNoGroups,
            isEmbeddedRoute,
            isCSM,
            isProjectSimplified,
            projectKey,
            isProjectAdmin,
            isJiraAdmin,
        } = this.props;

        // eslint-disable-next-line @atlassian/ssr-linting/no-dom-globals-in-react-cc-render
        const isMediumScreen = window.innerWidth < sizes.medium;

        const hasSingleRequestGroup = requestGroups.length === 1;
        const hasRequestGroup = requestGroups.length > 0;
        // If a group id is passed through that isn't actually inside the request groups array we make it undefined
        const matchingRequestGroupId = !!requestGroups.find((group) => group.id === requestGroupId)
            ? requestGroupId
            : undefined;
        // If there is only one element in the request groups array we force it to be selected.
        const selectedRequestGroupId = hasSingleRequestGroup ? requestGroups[0].id : matchingRequestGroupId;
        const requestTypesForGroup = this.getRequestTypesForSelectedRequestGroup(selectedRequestGroupId);
        // If a type id is passed through that isn't actually inside the request types array we make it undefined
        const selectedRequestTypeId = !!requestTypesForGroup.find(
            (requestType) => Number(requestType.id) === Number(requestTypeId)
        )
            ? requestTypeId
            : undefined;

        const renderCustomizePortalButtonsGroup =
            (isProjectAdmin || isJiraAdmin) &&
            projectKey?.length &&
            !isMediumScreen &&
            isCORTOPSExperimentEnabled() === 'variation' ? (
                <ErrorBoundary id="customize-request-types-portal-button-group" renderError={() => null}>
                    <CustomizeRequestTypesPortalButtonGroup
                        isProjectSimplified={isProjectSimplified}
                        projectKey={projectKey}
                    />
                </ErrorBoundary>
            ) : null;

        const renderEditRequestTypeButton =
            (isProjectAdmin || isJiraAdmin) &&
            projectKey?.length &&
            !isMediumScreen &&
            isCORTOPSExperimentEnabled() === 'variation' ? (
                <ErrorBoundary id="edit-request-type-button" renderError={() => null}>
                    <EditRequestTypeButton
                        projectKey={projectKey}
                        requestTypeId={selectedRequestTypeId}
                        isProjectSimplified={isProjectSimplified}
                    />
                </ErrorBoundary>
            ) : null;

        return hasRequestGroup ? (
            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            <div className={className}>
                {hasSingleRequestGroup || (
                    <RequestGroupSelect
                        // Disabling existing violations, should be fixed when revisited.
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={autoFocus && !selectedRequestGroupId}
                        portalId={portalId}
                        requestGroups={requestGroups}
                        label={!!hideLabels ? '' : intl.formatMessage(i18n.requestGroupSelectLabel)}
                        value={selectedRequestGroupId}
                        onChange={this.selectRequestGroup}
                        disabled={isDisabled}
                        cardLinkAppearance={cardLinkAppearance}
                        isEmbeddedRoute={isEmbeddedRoute}
                        {...((isProjectAdmin || isJiraAdmin) &&
                        projectKey?.length &&
                        !isMediumScreen &&
                        isCORTOPSExperimentEnabled() === 'variation'
                            ? {
                                  isProjectAdmin,
                                  isJiraAdmin,
                                  cutomizePortalButtonsGroup: renderCustomizePortalButtonsGroup,
                              }
                            : {})}
                    />
                )}

                {!!requestTypesForGroup.length && (
                    <StyledRequestTypeSelect
                        hasTopMargin={!hasSingleRequestGroup}
                        // Disabling existing violations, should be fixed when revisited.
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={autoFocus && !!selectedRequestGroupId}
                        portalId={portalId}
                        requestGroupId={selectedRequestGroupId}
                        label={this.getStyledRequestTypeSelectLabel()}
                        requestTypes={requestTypesForGroup}
                        value={selectedRequestTypeId}
                        onChange={this.selectRequestType}
                        disabled={isDisabled}
                        cardLinkAppearance={cardLinkAppearance}
                        isEmbeddedRoute={isEmbeddedRoute}
                        isCSM={isCSM}
                        {...((isProjectAdmin || isJiraAdmin) &&
                        projectKey?.length &&
                        !isMediumScreen &&
                        isCORTOPSExperimentEnabled() === 'variation'
                            ? {
                                  isProjectAdmin,
                                  isJiraAdmin,
                                  cutomizePortalButtonsGroup: renderCustomizePortalButtonsGroup,
                                  editRequestTypeButton: renderEditRequestTypeButton,
                              }
                            : {})}
                    />
                )}
            </div>
        ) : (
            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            <div className={className}>
                <FireScreenEventOnMount />
                <FormattedMessage {...i18n.emptyStateMessage} />{' '}
                {displayLinkWhenNoGroups && (
                    <ThemedLink actionSubjectId="tryAnotherServiceDesk" to="/portals">
                        <FormattedMessage {...i18n.tryAnotherServiceProjectLink} />
                    </ThemedLink>
                )}
            </div>
        );
    }
}

export default injectIntl(FormPickerDumb);

interface StyledRequestTypeSelectProps {
    hasTopMargin: boolean;
}

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const StyledRequestTypeSelect = styled(RequestTypeSelect)`
    margin-top: ${(props: StyledRequestTypeSelectProps) => (props.hasTopMargin ? token('space.400', '32px') : 0)};
`;
