/**
 * @generated SignedSource<<c12f34146fb03b7fdb1401ae0868dd8b>>
 * @relayHash cccd6a4b3fa58258eac96f56064f2d50
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1de9c6650a4d15456958100342ad192e3d3437064d80aff90518435f31a8e82c

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type editHomepageV2Query$variables = {
  cloudId: string;
  helpCenterAri: string;
  layoutAri: string;
};
export type editHomepageV2Query$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "QueryError";
      readonly " $fragmentSpreads": FragmentRefs<"globalBrandingSettingsPanelFragment">;
    } | null | undefined;
    readonly mediaConfig: {
      readonly " $fragmentSpreads": FragmentRefs<"globalBrandingSettingsPanelMediaConfigFragment">;
    };
  } | null | undefined;
  readonly helpLayout: {
    readonly elementTypes: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"elementsCatalogueFragment">;
    }> | null | undefined;
    readonly layout: {
      readonly " $fragmentSpreads": FragmentRefs<"pageBuilderFragment" | "useLayoutServiceFragment">;
    };
    readonly mediaConfig: {
      readonly " $fragmentSpreads": FragmentRefs<"pageBuilderMediaConfigFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type editHomepageV2Query = {
  response: editHomepageV2Query$data;
  variables: editHomepageV2Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "layoutAri"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "layoutAri"
  }
],
v7 = {
  "alias": "layoutId",
  "kind": "ScalarField",
  "name": "id"
},
v8 = {
  "concreteType": "HelpLayoutSubsectionConfig",
  "kind": "LinkedField",
  "name": "config",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "span"
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "name": "adf"
},
v10 = {
  "kind": "ScalarField",
  "name": "horizontalAlignment"
},
v11 = {
  "kind": "ScalarField",
  "name": "verticalAlignment"
},
v12 = {
  "concreteType": "HelpLayoutAlignmentSettings",
  "kind": "LinkedField",
  "name": "alignment",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v11/*: any*/)
  ]
},
v13 = {
  "kind": "ScalarField",
  "name": "fileId"
},
v14 = {
  "kind": "ScalarField",
  "name": "url"
},
v15 = {
  "concreteType": "HelpLayoutBackgroundImage",
  "kind": "LinkedField",
  "name": "backgroundImage",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v14/*: any*/)
  ]
},
v16 = {
  "kind": "ScalarField",
  "name": "backgroundType"
},
v17 = {
  "kind": "ScalarField",
  "name": "backgroundColor"
},
v18 = {
  "kind": "ScalarField",
  "name": "foregroundColor"
},
v19 = {
  "kind": "ScalarField",
  "name": "hidden"
},
v20 = {
  "kind": "ScalarField",
  "name": "titleColor"
},
v21 = [
  (v12/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/)
],
v22 = {
  "concreteType": "HelpLayoutVisualConfig",
  "kind": "LinkedField",
  "name": "visualConfig",
  "plural": false,
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useLayoutServiceVisualConfigData",
      "selections": (v21/*: any*/),
      "argumentDefinitions": ([]/*: any*/)
    }
  ]
},
v23 = {
  "kind": "ScalarField",
  "name": "useGlobalSettings"
},
v24 = [
  (v22/*: any*/)
],
v25 = {
  "kind": "ScalarField",
  "name": "expandButtonTextColor"
},
v26 = {
  "kind": "ScalarField",
  "name": "forgeElementPage"
},
v27 = {
  "kind": "ScalarField",
  "name": "forgeElementType"
},
v28 = {
  "kind": "ScalarField",
  "name": "connectElementPage"
},
v29 = {
  "kind": "ScalarField",
  "name": "connectElementType"
},
v30 = {
  "kind": "ScalarField",
  "name": "__id"
},
v31 = {
  "kind": "ClientExtension",
  "selections": [
    (v30/*: any*/)
  ]
},
v32 = [
  {
    "kind": "Variable",
    "name": "parentAri",
    "variableName": "helpCenterAri"
  }
],
v33 = [
  (v14/*: any*/),
  (v13/*: any*/)
],
v34 = {
  "kind": "ScalarField",
  "name": "id"
},
v35 = {
  "kind": "InlineFragment",
  "selections": [
    (v34/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v36 = {
  "kind": "ScalarField",
  "name": "mediaUrl"
},
v37 = {
  "kind": "ScalarField",
  "name": "mediaToken"
},
v38 = {
  "kind": "ScalarField",
  "name": "mediaCollectionName"
},
v39 = {
  "kind": "ScalarField",
  "name": "asapIssuer"
},
v40 = [
  (v36/*: any*/),
  (v37/*: any*/),
  (v38/*: any*/),
  (v39/*: any*/)
],
v41 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v42 = {
  "kind": "ScalarField",
  "name": "key"
},
v43 = {
  "kind": "ScalarField",
  "name": "name"
},
v44 = {
  "kind": "ScalarField",
  "name": "logo"
},
v45 = {
  "kind": "ScalarField",
  "name": "portalId"
},
v46 = {
  "concreteType": "HelpLayoutVisualConfig",
  "kind": "LinkedField",
  "name": "visualConfig",
  "plural": false,
  "selections": [
    (v20/*: any*/),
    (v12/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/),
    (v19/*: any*/)
  ]
},
v47 = {
  "concreteType": "HelpLayoutAtomicElementType",
  "kind": "LinkedField",
  "name": "elementType",
  "plural": false,
  "selections": [
    (v41/*: any*/),
    (v42/*: any*/)
  ]
},
v48 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isHelpLayoutVisualEntity"
},
v49 = {
  "concreteType": "HelpLayoutBackgroundImage",
  "kind": "LinkedField",
  "name": "backgroundImage",
  "plural": false,
  "selections": (v33/*: any*/)
},
v50 = {
  "concreteType": "HelpLayoutVisualConfig",
  "kind": "LinkedField",
  "name": "visualConfig",
  "plural": false,
  "selections": (v21/*: any*/)
},
v51 = {
  "kind": "ScalarField",
  "name": "isInstalled"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "editHomepageV2Query",
    "selections": [
      {
        "args": (v3/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "globalBrandingSettingsPanelFragment"
                  }
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v5/*: any*/)
                ],
                "type": "QueryError"
              }
            ]
          },
          {
            "kind": "RequiredField",
            "field": {
              "args": (v4/*: any*/),
              "concreteType": "HelpCenterMediaConfig",
              "kind": "LinkedField",
              "name": "mediaConfig",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "globalBrandingSettingsPanelMediaConfigFragment"
                }
              ]
            },
            "action": "THROW",
            "path": "helpCenter.mediaConfig"
          }
        ]
      },
      {
        "args": (v3/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "args": (v6/*: any*/),
              "kind": "LinkedField",
              "name": "layout",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "pageBuilderFragment"
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "useLayoutServiceFragment",
                      "selections": [
                        (v7/*: any*/),
                        {
                          "concreteType": "HelpLayoutSectionConnection",
                          "kind": "LinkedField",
                          "name": "sections",
                          "plural": false,
                          "selections": [
                            {
                              "concreteType": "HelpLayoutSectionEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "concreteType": "HelpLayoutSection",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "HelpLayoutSubsection",
                                      "kind": "LinkedField",
                                      "name": "subsections",
                                      "plural": true,
                                      "selections": [
                                        (v8/*: any*/),
                                        {
                                          "kind": "LinkedField",
                                          "name": "elements",
                                          "plural": true,
                                          "selections": [
                                            (v5/*: any*/),
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceEditorElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v9/*: any*/),
                                                    (v22/*: any*/)
                                                  ],
                                                  "type": "HelpLayoutEditorElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceHeroElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v23/*: any*/),
                                                    (v22/*: any*/)
                                                  ],
                                                  "type": "HelpLayoutHeroElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceAnnouncementElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": (v24/*: any*/),
                                                  "type": "HelpLayoutPortalsListElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceTopicsElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": (v24/*: any*/),
                                                  "type": "HelpLayoutTopicsListElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceListOfPortalsElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v25/*: any*/),
                                                    (v22/*: any*/)
                                                  ],
                                                  "type": "HelpLayoutPortalsListElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceSuggestedRequestFormsElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": (v24/*: any*/),
                                                  "type": "HelpLayoutSuggestedRequestFormsListElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceForgeElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v26/*: any*/),
                                                    (v27/*: any*/),
                                                    (v22/*: any*/)
                                                  ],
                                                  "type": "HelpLayoutForgeElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceConnectElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v28/*: any*/),
                                                    (v29/*: any*/),
                                                    (v22/*: any*/)
                                                  ],
                                                  "type": "HelpLayoutConnectElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceBreadcrumbElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": (v24/*: any*/),
                                                  "type": "HelpLayoutBreadcrumbElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            }
                                          ]
                                        },
                                        (v22/*: any*/)
                                      ]
                                    },
                                    (v22/*: any*/)
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                        (v31/*: any*/)
                      ],
                      "argumentDefinitions": []
                    }
                  ],
                  "type": "HelpLayout"
                }
              ]
            },
            "action": "THROW",
            "path": "helpLayout.layout"
          },
          {
            "args": (v32/*: any*/),
            "concreteType": "HelpLayoutMediaConfig",
            "kind": "LinkedField",
            "name": "mediaConfig",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "pageBuilderMediaConfigFragment"
              }
            ]
          },
          {
            "kind": "LinkedField",
            "name": "elementTypes",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "elementsCatalogueFragment"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "editHomepageV2Query",
    "selections": [
      {
        "args": (v3/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "concreteType": "HelpCenterBranding",
                    "kind": "LinkedField",
                    "name": "helpCenterBranding",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "HelpCenterBrandingColors",
                        "kind": "LinkedField",
                        "name": "colors",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "primary"
                          }
                        ]
                      },
                      {
                        "concreteType": "HelpCenterLogo",
                        "kind": "LinkedField",
                        "name": "logo",
                        "plural": false,
                        "selections": (v33/*: any*/)
                      }
                    ]
                  }
                ],
                "type": "HelpCenter"
              },
              (v35/*: any*/)
            ]
          },
          {
            "args": (v4/*: any*/),
            "concreteType": "HelpCenterMediaConfig",
            "kind": "LinkedField",
            "name": "mediaConfig",
            "plural": false,
            "selections": (v40/*: any*/)
          }
        ]
      },
      {
        "args": (v3/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "kind": "LinkedField",
            "name": "layout",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v34/*: any*/),
                  {
                    "concreteType": "HelpLayoutSectionConnection",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "HelpLayoutSectionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "HelpLayoutSection",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "HelpLayoutSubsection",
                                "kind": "LinkedField",
                                "name": "subsections",
                                "plural": true,
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "name": "elements",
                                    "plural": true,
                                    "selections": [
                                      (v5/*: any*/),
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isHelpLayoutElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v9/*: any*/),
                                          {
                                            "concreteType": "HelpLayoutAtomicElementType",
                                            "kind": "LinkedField",
                                            "name": "elementType",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "args": (v32/*: any*/),
                                                "concreteType": "HelpLayoutMediaConfig",
                                                "kind": "LinkedField",
                                                "name": "mediaConfig",
                                                "plural": false,
                                                "selections": [
                                                  (v38/*: any*/),
                                                  (v37/*: any*/),
                                                  (v36/*: any*/),
                                                  (v39/*: any*/)
                                                ]
                                              },
                                              (v41/*: any*/),
                                              (v42/*: any*/)
                                            ]
                                          },
                                          {
                                            "concreteType": "HelpLayoutVisualConfig",
                                            "kind": "LinkedField",
                                            "name": "visualConfig",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "HelpLayoutAlignmentSettings",
                                                "kind": "LinkedField",
                                                "name": "alignment",
                                                "plural": false,
                                                "selections": [
                                                  (v10/*: any*/),
                                                  (v11/*: any*/),
                                                  (v31/*: any*/)
                                                ]
                                              },
                                              (v15/*: any*/),
                                              (v16/*: any*/),
                                              (v17/*: any*/),
                                              (v18/*: any*/),
                                              (v19/*: any*/),
                                              (v20/*: any*/)
                                            ]
                                          },
                                          (v31/*: any*/)
                                        ],
                                        "type": "HelpLayoutEditorElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutPortalCard",
                                            "kind": "LinkedField",
                                            "name": "portals",
                                            "plural": true,
                                            "selections": [
                                              (v43/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "description"
                                              },
                                              (v44/*: any*/),
                                              (v45/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "isFeatured"
                                              },
                                              (v5/*: any*/)
                                            ]
                                          },
                                          (v25/*: any*/),
                                          (v46/*: any*/),
                                          (v34/*: any*/),
                                          (v47/*: any*/),
                                          (v48/*: any*/)
                                        ],
                                        "type": "HelpLayoutPortalsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutRequestForm",
                                            "kind": "LinkedField",
                                            "name": "suggestedRequestTypes",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "descriptionHtml"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "portalName"
                                              },
                                              (v34/*: any*/),
                                              (v45/*: any*/),
                                              (v43/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "iconUrl"
                                              },
                                              (v5/*: any*/)
                                            ]
                                          },
                                          (v46/*: any*/),
                                          (v34/*: any*/),
                                          (v47/*: any*/),
                                          (v48/*: any*/)
                                        ],
                                        "type": "HelpLayoutSuggestedRequestFormsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "header"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "message"
                                          },
                                          (v47/*: any*/)
                                        ],
                                        "type": "HelpLayoutAnnouncementElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutTopic",
                                            "kind": "LinkedField",
                                            "name": "topics",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "topicId"
                                              },
                                              (v19/*: any*/),
                                              {
                                                "concreteType": "HelpLayoutTopicItem",
                                                "kind": "LinkedField",
                                                "name": "items",
                                                "plural": true,
                                                "selections": [
                                                  (v5/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "title"
                                                  },
                                                  (v44/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "displayLink"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "entityKey"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "helpObjectType"
                                                  },
                                                  (v31/*: any*/)
                                                ]
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "topicName"
                                              }
                                            ]
                                          },
                                          (v46/*: any*/),
                                          (v34/*: any*/),
                                          (v47/*: any*/),
                                          (v48/*: any*/)
                                        ],
                                        "type": "HelpLayoutTopicsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v23/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "name": "homePageTitle"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "userLanguageTag"
                                          },
                                          {
                                            "concreteType": "HelpLayoutVisualConfig",
                                            "kind": "LinkedField",
                                            "name": "visualConfig",
                                            "plural": false,
                                            "selections": [
                                              (v18/*: any*/),
                                              (v17/*: any*/),
                                              (v16/*: any*/),
                                              (v49/*: any*/),
                                              (v12/*: any*/),
                                              (v19/*: any*/),
                                              (v20/*: any*/)
                                            ]
                                          },
                                          (v34/*: any*/),
                                          (v47/*: any*/)
                                        ],
                                        "type": "HelpLayoutHeroElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutBreadcrumb",
                                            "kind": "LinkedField",
                                            "name": "items",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "relativeUrl"
                                              },
                                              (v43/*: any*/)
                                            ]
                                          },
                                          (v47/*: any*/),
                                          (v50/*: any*/)
                                        ],
                                        "type": "HelpLayoutBreadcrumbElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v27/*: any*/),
                                          (v26/*: any*/),
                                          (v47/*: any*/),
                                          (v51/*: any*/),
                                          (v50/*: any*/)
                                        ],
                                        "type": "HelpLayoutForgeElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v28/*: any*/),
                                          (v29/*: any*/),
                                          (v47/*: any*/),
                                          (v51/*: any*/),
                                          (v50/*: any*/)
                                        ],
                                        "type": "HelpLayoutConnectElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "identifier"
                                          },
                                          {
                                            "kind": "LinkedField",
                                            "name": "extensions",
                                            "plural": true,
                                            "selections": [
                                              (v5/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "statusCode"
                                              }
                                            ]
                                          }
                                        ],
                                        "type": "QueryError"
                                      },
                                      (v31/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutAtomicElementType",
                                            "kind": "LinkedField",
                                            "name": "elementType",
                                            "plural": false,
                                            "selections": [
                                              (v42/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "HelpLayoutNoContentElement"
                                      },
                                      (v35/*: any*/)
                                    ]
                                  },
                                  (v34/*: any*/),
                                  (v50/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "HelpLayoutVisualConfig",
                                        "kind": "LinkedField",
                                        "name": "visualConfig",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutAlignmentSettings",
                                            "kind": "LinkedField",
                                            "name": "alignment",
                                            "plural": false,
                                            "selections": [
                                              (v31/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "HelpLayoutVisualEntity",
                                    "abstractKey": "__isHelpLayoutVisualEntity"
                                  },
                                  (v31/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "HelpLayoutVisualConfig",
                                "kind": "LinkedField",
                                "name": "visualConfig",
                                "plural": false,
                                "selections": [
                                  (v17/*: any*/),
                                  (v16/*: any*/),
                                  (v49/*: any*/),
                                  (v19/*: any*/),
                                  (v12/*: any*/),
                                  (v18/*: any*/),
                                  (v20/*: any*/)
                                ]
                              },
                              (v34/*: any*/),
                              {
                                "kind": "ClientExtension",
                                "selections": [
                                  (v30/*: any*/),
                                  {
                                    "alias": "dataId",
                                    "kind": "ScalarField",
                                    "name": "__id"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v7/*: any*/),
                  (v31/*: any*/)
                ],
                "type": "HelpLayout"
              },
              (v35/*: any*/)
            ]
          },
          {
            "args": (v32/*: any*/),
            "concreteType": "HelpLayoutMediaConfig",
            "kind": "LinkedField",
            "name": "mediaConfig",
            "plural": false,
            "selections": (v40/*: any*/)
          },
          {
            "kind": "LinkedField",
            "name": "elementTypes",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isHelpLayoutElementType"
              },
              (v41/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "atomicKey",
                    "kind": "ScalarField",
                    "name": "key"
                  },
                  {
                    "args": (v32/*: any*/),
                    "concreteType": "HelpLayoutMediaConfig",
                    "kind": "LinkedField",
                    "name": "mediaConfig",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v38/*: any*/),
                      (v37/*: any*/),
                      (v36/*: any*/),
                      (v39/*: any*/)
                    ]
                  }
                ],
                "type": "HelpLayoutAtomicElementType"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "compositeKey",
                    "kind": "ScalarField",
                    "name": "key"
                  }
                ],
                "type": "HelpLayoutCompositeElementType"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "1de9c6650a4d15456958100342ad192e3d3437064d80aff90518435f31a8e82c",
    "metadata": {},
    "name": "editHomepageV2Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e0721cb9391648dbc1c11cc1f7260767";

export default node;
