import { styled } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled2 from 'styled-components';
import * as mixins from 'view/styles/mixins';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Link } from '@atlassian/help-center-common-component/analytics/link';

export const SEARCH_BAR_HEIGHT = token('space.1000', '80px');

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Title = styled2.h3`
    ${mixins.clamp};
    margin: 0;
    font-weight: normal;
    font-size: ${token('space.200', '16px')};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Snippet = styled2.p`
    color: ${token('color.text', colors.N800)};
    margin-top: ${token('space.100', '8px')};
    padding-right: ${token('space.200', '16px')};
    ${mixins.multiLineClamp(3, 20)};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const LinkContainer = styled2(Link)`
    display: flex;
    border-radius: ${token('border.radius.100', '3px')};
    ${mixins.raiseUpOnHoverOrFocus()};
    background-color: ${token('elevation.surface.raised', colors.N0)};
    padding: ${token('space.200', '16px')};
    margin: ${token('space.100', '8px')} 0 0;
    &:hover,
    &:focus {
        background: ${token('elevation.surface.raised', colors.N0)};
        text-decoration: none;
    }
    &:hover h3,
    &:focus h3 {
        color: ${(props) => token('color.link', props.theme.color)};
    }
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const ResultTitle = styled.h2({
    paddingTop: token('space.200', '16px'),
    marginTop: token('space.0', '0px'),
    textTransform: 'uppercase',
    fontSize: token('space.150', '12px'),
    fontWeight: 'bold',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    color: token('color.background.accent.gray.subtler', colors.N300),
});

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const ResultList = styled.ul({
    listStyleType: 'none',
    padding: 0,
});

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Img = styled.img({
    width: token('space.1000', '80px'),
    padding: token('space.200', '16px'),
});

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const InlineResultsHeadingWrapper = styled.div({
    paddingLeft: token('space.200', '16px'),
});

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const InlineResultLink = styled(Link)({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    color: `${token('color.text', colors.N900)} !important`,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    textDecoration: 'none !important',
    alignItems: 'center',
    paddingBlock: token('space.075', '6px'),
    paddingInline: token('space.200', '16px'),
    gap: token('space.150', '12px'),
    boxSizing: 'border-box',
    cursor: 'pointer',
    overflow: 'hidden',

    '&:hover': {
        backgroundColor: token('color.background.neutral.subtle.hovered', '#091e4214'),
    },

    '&:active': {
        backgroundColor: token('color.background.neutral.subtle.pressed', '#091E4224'),
    },

    '&:focus': {
        outlineStyle: 'solid',
        outlineWidth: token('border.width.outline', '2px'),
        outlineColor: token('color.border.focused', '#6495ed'),
    },
});

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const DropdownOptionList = styled.ul({
    listStyleType: 'none',
    padding: 0,
    marginBlockStart: token('space.100', '8px'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    li: {
        marginTop: 0,
    },
});
