/**
 * @generated SignedSource<<cf48dacf8a208654794a96cc8d9537b0>>
 * @relayHash c5cb24417487d45d4614c510c4d70b72
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fdcd17cb0ddc5c4f9d251802c96280dec99ed869c5c979d6350e9cba1e5b17e4

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type HelpLayoutFilter = {
  isEditMode?: boolean | null | undefined;
};
export type viewCustomPageQuery$variables = {
  cloudId: string;
  filter?: HelpLayoutFilter | null | undefined;
  helpCenterAri: string;
  helpCenterPageAri: string;
};
export type viewCustomPageQuery$data = {
  readonly helpCenter: {
    readonly helpCenterPageById: {
      readonly __typename: "QueryError";
      readonly description?: {
        readonly default: string | null | undefined;
      } | null | undefined;
      readonly name?: {
        readonly default: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"handleQueryErrorFragment">;
    } | null | undefined;
  } | null | undefined;
  readonly helpLayout: {
    readonly layoutByParentId: {
      readonly __typename: "QueryError";
      readonly " $fragmentSpreads": FragmentRefs<"handleQueryErrorFragment" | "layoutViewRendererFragment">;
    };
  } | null | undefined;
};
export type viewCustomPageQuery = {
  response: viewCustomPageQuery$data;
  variables: viewCustomPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterPageAri"
},
v4 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "helpCenterPageAri",
    "variableName": "helpCenterPageAri"
  }
],
v6 = [
  {
    "kind": "ScalarField",
    "name": "default"
  }
],
v7 = {
  "concreteType": "HelpCenterPageName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": (v6/*: any*/)
},
v8 = {
  "concreteType": "HelpCenterPageDescription",
  "kind": "LinkedField",
  "name": "description",
  "plural": false,
  "selections": (v6/*: any*/)
},
v9 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    (v9/*: any*/),
    {
      "kind": "FragmentSpread",
      "name": "handleQueryErrorFragment"
    }
  ],
  "type": "QueryError"
},
v11 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  },
  {
    "kind": "Variable",
    "name": "parentAri",
    "variableName": "helpCenterPageAri"
  }
],
v12 = {
  "kind": "ScalarField",
  "name": "message"
},
v13 = {
  "kind": "LinkedField",
  "name": "extensions",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "kind": "ScalarField",
      "name": "statusCode"
    }
  ]
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    (v12/*: any*/),
    (v13/*: any*/)
  ],
  "type": "QueryError"
},
v15 = {
  "kind": "ScalarField",
  "name": "id"
},
v16 = {
  "kind": "InlineFragment",
  "selections": [
    (v15/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v17 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v18 = {
  "kind": "ScalarField",
  "name": "key"
},
v19 = {
  "kind": "ScalarField",
  "name": "name"
},
v20 = {
  "kind": "ScalarField",
  "name": "logo"
},
v21 = {
  "kind": "ScalarField",
  "name": "portalId"
},
v22 = {
  "concreteType": "HelpLayoutVisualConfig",
  "kind": "LinkedField",
  "name": "visualConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "titleColor"
    }
  ]
},
v23 = {
  "concreteType": "HelpLayoutAtomicElementType",
  "kind": "LinkedField",
  "name": "elementType",
  "plural": false,
  "selections": [
    (v18/*: any*/)
  ]
},
v24 = {
  "kind": "ScalarField",
  "name": "hidden"
},
v25 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v26 = {
  "kind": "ScalarField",
  "name": "backgroundColor"
},
v27 = {
  "kind": "ScalarField",
  "name": "backgroundType"
},
v28 = {
  "concreteType": "HelpLayoutBackgroundImage",
  "kind": "LinkedField",
  "name": "backgroundImage",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "url"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "viewCustomPageQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterPageById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v7/*: any*/),
                    "action": "THROW",
                    "path": "helpCenter.helpCenterPageById.name"
                  },
                  (v8/*: any*/)
                ],
                "type": "HelpCenterPage"
              },
              (v10/*: any*/)
            ]
          }
        ]
      },
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "args": (v11/*: any*/),
              "kind": "LinkedField",
              "name": "layoutByParentId",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "layoutViewRendererFragment"
                    }
                  ],
                  "type": "HelpLayout"
                },
                (v10/*: any*/)
              ]
            },
            "action": "THROW",
            "path": "helpLayout.layoutByParentId"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "viewCustomPageQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterPageById",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "type": "HelpCenterPage"
              },
              (v14/*: any*/),
              (v16/*: any*/)
            ]
          }
        ]
      },
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "args": (v11/*: any*/),
            "kind": "LinkedField",
            "name": "layoutByParentId",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": (v17/*: any*/),
                    "concreteType": "HelpLayoutSectionConnection",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "concreteType": "HelpLayoutSectionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "HelpLayoutSection",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "HelpLayoutSubsection",
                                "kind": "LinkedField",
                                "name": "subsections",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "HelpLayoutSubsectionConfig",
                                    "kind": "LinkedField",
                                    "name": "config",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "span"
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "name": "elements",
                                    "plural": true,
                                    "selections": [
                                      (v9/*: any*/),
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isHelpLayoutElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "adf"
                                          },
                                          {
                                            "concreteType": "HelpLayoutAtomicElementType",
                                            "kind": "LinkedField",
                                            "name": "elementType",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "args": [
                                                  {
                                                    "kind": "Variable",
                                                    "name": "parentAri",
                                                    "variableName": "helpCenterAri"
                                                  }
                                                ],
                                                "concreteType": "HelpLayoutMediaConfig",
                                                "kind": "LinkedField",
                                                "name": "mediaConfig",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "mediaCollectionName"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "mediaToken"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "mediaUrl"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "asapIssuer"
                                                  }
                                                ]
                                              },
                                              (v18/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "HelpLayoutEditorElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutPortalCard",
                                            "kind": "LinkedField",
                                            "name": "portals",
                                            "plural": true,
                                            "selections": [
                                              (v19/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "description"
                                              },
                                              (v20/*: any*/),
                                              (v21/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "isFeatured"
                                              }
                                            ]
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "expandButtonTextColor"
                                          },
                                          (v22/*: any*/),
                                          (v23/*: any*/)
                                        ],
                                        "type": "HelpLayoutPortalsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutRequestForm",
                                            "kind": "LinkedField",
                                            "name": "suggestedRequestTypes",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "descriptionHtml"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "portalName"
                                              },
                                              (v15/*: any*/),
                                              (v21/*: any*/),
                                              (v19/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "iconUrl"
                                              }
                                            ]
                                          },
                                          (v22/*: any*/),
                                          (v23/*: any*/)
                                        ],
                                        "type": "HelpLayoutSuggestedRequestFormsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "header"
                                          },
                                          (v12/*: any*/),
                                          (v23/*: any*/)
                                        ],
                                        "type": "HelpLayoutAnnouncementElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutTopic",
                                            "kind": "LinkedField",
                                            "name": "topics",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "topicId"
                                              },
                                              (v24/*: any*/),
                                              {
                                                "concreteType": "HelpLayoutTopicItem",
                                                "kind": "LinkedField",
                                                "name": "items",
                                                "plural": true,
                                                "selections": [
                                                  (v9/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "title"
                                                  },
                                                  (v20/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "displayLink"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "entityKey"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "helpObjectType"
                                                  },
                                                  (v25/*: any*/)
                                                ]
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "topicName"
                                              }
                                            ]
                                          },
                                          (v22/*: any*/),
                                          (v23/*: any*/)
                                        ],
                                        "type": "HelpLayoutTopicsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "useGlobalSettings"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "homePageTitle"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "userLanguageTag"
                                          },
                                          {
                                            "concreteType": "HelpLayoutVisualConfig",
                                            "kind": "LinkedField",
                                            "name": "visualConfig",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "foregroundColor"
                                              },
                                              (v26/*: any*/),
                                              (v27/*: any*/),
                                              (v28/*: any*/)
                                            ]
                                          },
                                          (v23/*: any*/)
                                        ],
                                        "type": "HelpLayoutHeroElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v23/*: any*/)
                                        ],
                                        "type": "HelpLayoutNoContentElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "forgeElementType"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "forgeElementPage"
                                          },
                                          (v23/*: any*/)
                                        ],
                                        "type": "HelpLayoutForgeElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "connectElementPage"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "connectElementType"
                                          },
                                          (v23/*: any*/)
                                        ],
                                        "type": "HelpLayoutConnectElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "identifier"
                                          },
                                          (v13/*: any*/),
                                          (v12/*: any*/)
                                        ],
                                        "type": "QueryError"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutBreadcrumb",
                                            "kind": "LinkedField",
                                            "name": "items",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "relativeUrl"
                                              },
                                              (v19/*: any*/)
                                            ]
                                          },
                                          (v23/*: any*/)
                                        ],
                                        "type": "HelpLayoutBreadcrumbElement"
                                      },
                                      (v16/*: any*/),
                                      (v25/*: any*/)
                                    ]
                                  },
                                  (v15/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "HelpLayoutVisualConfig",
                                        "kind": "LinkedField",
                                        "name": "visualConfig",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutAlignmentSettings",
                                            "kind": "LinkedField",
                                            "name": "alignment",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "horizontalAlignment"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "verticalAlignment"
                                              },
                                              (v25/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "HelpLayoutVisualEntity",
                                    "abstractKey": "__isHelpLayoutVisualEntity"
                                  },
                                  (v25/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "HelpLayoutVisualConfig",
                                "kind": "LinkedField",
                                "name": "visualConfig",
                                "plural": false,
                                "selections": [
                                  (v26/*: any*/),
                                  (v27/*: any*/),
                                  (v28/*: any*/),
                                  (v24/*: any*/)
                                ]
                              },
                              (v15/*: any*/),
                              (v9/*: any*/),
                              (v25/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "cursor"
                          }
                        ]
                      },
                      {
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "endCursor"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "hasNextPage"
                          }
                        ]
                      }
                    ],
                    "storageKey": "sections(first:10)"
                  },
                  {
                    "args": (v17/*: any*/),
                    "handle": "connection",
                    "key": "layoutRendererFragment_sections",
                    "kind": "LinkedHandle",
                    "name": "sections"
                  },
                  (v15/*: any*/)
                ],
                "type": "HelpLayout"
              },
              (v14/*: any*/),
              (v16/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "fdcd17cb0ddc5c4f9d251802c96280dec99ed869c5c979d6350e9cba1e5b17e4",
    "metadata": {},
    "name": "viewCustomPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "23de99b0ab8266e76b7d68483d4124a8";

export default node;
