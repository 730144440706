import React, { useCallback } from 'react';
import type { KeyboardEvent } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isSearchUIRedesignEnabled } from 'feature-flags';
import qs from 'query-string';
import FocusLock from 'react-focus-lock';
import { di } from 'react-magnetic-di';
import { useRouter } from 'react-resource-router';
import type { State } from 'state';
import type { ShowSearchAction, UpdateSearchAction } from 'state/actions/search';
import { getProjectId } from 'state/selectors/portal';
import { isLoggedIn as isLoggedInSelector } from 'state/selectors/user';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import Blanket from '@atlaskit/blanket';
import FadeIn from '@atlaskit/motion/fade-in';
import Portal from '@atlaskit/portal';
import { Box, Grid, Inline, media, Stack, xcss } from '@atlaskit/primitives';
import { layers } from '@atlaskit/theme/constants';
import { ScrollLock } from '@atlassian/help-center-common-component/scroll-lock';
import { isCSMHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { getCloudId } from '@atlassian/help-center-common-util/meta';
import { useVirtualAgentAvailability } from '@atlassian/help-center-virtual-agent/src/services/virtual-agent-availability';
import { VirtualAgentBanner } from '@atlassian/help-center-virtual-agent-banner';
import type { SelectValues } from '@atlassian/paginated-picker';
import CategoryFilter from '../category-filter';
import {
    getPortalIdAsNumber,
    isValidSearchTerm,
    SEARCH_BAR_HEIGHT,
    useQueryParams,
    isSinglePortalSearch,
} from '../common';
import { SearchResultNone } from '../none';
import { SearchBar } from '../search-bar';
import { SearchResult } from '../search-result';
import ServiceDeskFilter from '../servicedesk-filter';

interface OwnProps {
    isOpen: boolean;
    term: string;
    portalId?: string;
    onSearch: () => UpdateSearchAction;
    onClose: () => ShowSearchAction;
    onReturnFocus: (_: Element) => boolean;
    isVSAOnHCEnabled?: boolean;
}

export interface Props extends OwnProps {
    isLoggedIn: boolean;
    projectId: number | undefined;
}

const SearchModal = ({
    isOpen,
    term,
    isVSAOnHCEnabled,
    isLoggedIn,
    portalId,
    projectId,
    onSearch,
    onClose,
    onReturnFocus,
}: Props & WithAnalyticsEventsProps) => {
    di(useVirtualAgentAvailability, isSearchUIRedesignEnabled);
    di(isCSMHelpCenter);

    const isCSM = isCSMHelpCenter(getEnv().helpCenterType);
    const showSearchResults = isValidSearchTerm(term);
    const scope = portalId ? 'portal' : 'helpCenter';
    const cloudId = getCloudId();
    const { isVirtualAgentEnabled: isVirtualAgentAvailableForProject } = useVirtualAgentAvailability({
        isLoggedIn,
        cloudId,
        projectId,
    });
    const isVirtualAgentAvailableForPortal = scope === 'portal' && isVirtualAgentAvailableForProject;
    const isVirtualAgentAvailableForHelpCenter = scope === 'helpCenter' && isVSAOnHCEnabled;
    const isVirtualAgentAvailable = isVirtualAgentAvailableForPortal || isVirtualAgentAvailableForHelpCenter;

    const onKeyUp = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        },
        [onClose]
    );
    const [{ location }, { replace }] = useRouter();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const queryStringParams = useQueryParams();
    const { portalIds, categoryIds } = queryStringParams as unknown as { portalIds: string[]; categoryIds: string[] };
    const portalIdAsNumber = getPortalIdAsNumber(portalId);
    const showCategoryFilter = isSinglePortalSearch(portalIdAsNumber);

    const onServiceDeskFilterChange = useCallback(
        (options: SelectValues) => {
            const selectedPortalIds = options.map((option) => option.value);
            const queryString = qs.stringify({
                ...queryStringParams,
                portalIds: selectedPortalIds,
            });

            replace(`${location.pathname}?${queryString}`) as unknown as void;
        },
        [location.pathname, queryStringParams, replace]
    );

    const onCategoryFilterChange = useCallback(
        (options: SelectValues) => {
            const selectedCategoryIds = options.map((option) => option.value);
            const queryString = qs.stringify({
                ...queryStringParams,
                categoryIds: selectedCategoryIds,
            });

            replace(`${location.pathname}?${queryString}`) as unknown as void;
        },
        [location.pathname, queryStringParams, replace]
    );

    const renderResults = () => {
        if (!isSearchUIRedesignEnabled()) {
            return (
                <>
                    {showSearchResults && (
                        <Box xcss={containerStyles}>
                            {isVirtualAgentAvailable && (
                                <Box xcss={virtualBannerStyles}>
                                    <VirtualAgentBanner cloudId={cloudId} portalId={portalId} />
                                </Box>
                            )}
                            <SearchResult term={term} />
                        </Box>
                    )}
                </>
            );
        }
        return (
            <Grid gap="space.200" templateColumns="1fr" alignItems="center" xcss={responsiveStyles}>
                <Box />
                {showSearchResults ? (
                    <Box>
                        <Stack>
                            {isVirtualAgentAvailable && (
                                <Box xcss={virtualBannerStyles}>
                                    <VirtualAgentBanner cloudId={cloudId} portalId={portalId} />
                                </Box>
                            )}
                            {!isCSM && (
                                <Inline xcss={filterStyles}>
                                    {showCategoryFilter && (
                                        <CategoryFilter
                                            onChange={onCategoryFilterChange}
                                            portalId={portalIdAsNumber}
                                            projectId={projectId}
                                            categoryIds={categoryIds}
                                        />
                                    )}
                                    {!showCategoryFilter && (
                                        <ServiceDeskFilter onChange={onServiceDeskFilterChange} portalIds={portalIds} />
                                    )}
                                </Inline>
                            )}
                        </Stack>

                        <SearchResult term={term} />
                    </Box>
                ) : (
                    // Show empty query state if invalid search term
                    <SearchResultNone term={term} />
                )}
                <Box />
            </Grid>
        );
    };

    return (
        <FadeIn duration={200} entranceDirection="bottom">
            {(fadeInProps) =>
                isOpen && (
                    <Portal zIndex={layers.dialog()}>
                        <div
                            // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                            style={{ width: '100vw', height: '100vh', position: 'fixed', top: '0px', left: '0px' }}
                            {...fadeInProps}
                            role="dialog"
                            aria-modal="true"
                        >
                            {/*Disabling existing violations, should be fixed when revisited.*/}
                            {/*eslint-disable-next-line jsx-a11y/no-autofocus*/}
                            <FocusLock autoFocus returnFocus={onReturnFocus}>
                                {/* Ensures scroll events are blocked on the document body and locked */}
                                <ScrollLock />
                                <Blanket shouldAllowClickThrough={false} isTinted>
                                    <Box
                                        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                                        xcss={xcss({
                                            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                                            height: `calc(100% - ${SEARCH_BAR_HEIGHT})`,
                                        })}
                                        onKeyUp={onKeyUp}
                                    >
                                        <SearchBar term={term} onChange={onSearch} onClose={onClose} />
                                        <Box
                                            id="dynamic-search-result"
                                            aria-live="polite"
                                            aria-atomic="true"
                                            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                                            xcss={xcss({
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                position: 'relative',
                                                height: '100%',
                                                // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                                                top: SEARCH_BAR_HEIGHT,
                                                backgroundColor: 'elevation.surface.overlay',
                                                paddingTop: 'space.300',
                                                paddingBottom: 'space.200',
                                                overflow: 'auto',
                                            })}
                                        >
                                            {renderResults()}
                                        </Box>
                                    </Box>
                                </Blanket>
                            </FocusLock>
                        </div>
                    </Portal>
                )
            }
        </FadeIn>
    );
};

export default connect(
    createSelector(
        isLoggedInSelector,
        (state: State, { portalId }: OwnProps) => ({
            portalId,
            projectId: getProjectId(state, getPortalIdAsNumber(portalId)),
        }),
        (isLoggedIn, { portalId, projectId }) => ({
            isLoggedIn,
            portalId,
            projectId,
        })
    )
)(SearchModal);

const virtualBannerStyles = xcss({
    marginBottom: 'space.200',
});
const filterStyles = xcss({
    paddingTop: 'space.100',
    paddingBottom: 'space.300',
});
const containerStyles = xcss({
    width: '90%',
    pointerEvents: 'auto',
    [media.above.sm]: {
        width: '50%',
    },
});
const responsiveStyles = xcss({
    [media.above.xxs]: {
        gridTemplateColumns: '1fr',
        marginLeft: 'space.100',
        marginRight: 'space.100',
        width: '1fr',
    },
    [media.above.md]: {
        gridTemplateColumns: '1fr 965px 1fr',
        marginLeft: 'space.0',
        marginRight: 'space.0',
    },
});
