import { defineMessages } from 'react-intl-next';

// TODO: to be refined in https://ecosystem-platform.atlassian.net/browse/UIM-2463
export default defineMessages({
    obligatoryUimRequestCreateConsentMessage: {
        id: 'ui-modifications-fields-configuration.common.utils.views.request-create.obligatory-uim-request-create-consent-message',
        defaultMessage: 'You must allow "{appTitle}" to access your Atlassian products to create this work item.',
        description:
            'When a user opens a context the first time, we require that they give consent for their data otherwise they cannot get the UIM`s applied',
    },
});
