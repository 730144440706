import React from 'react';
import { islooselyLazyJsErrorsToSentryEnabled } from 'feature-flags';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsContext, withAnalyticsEvents } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { trackError } from '@atlassian/help-center-common-util/analytics';

export interface ErrorBoundaryProps {
    onError?: (error: Error) => void;
    renderError: (error: Error) => JSX.Element;
}

interface State {
    error: Error | undefined;
}
class ErrorBoundary extends React.Component<
    React.PropsWithChildren<ErrorBoundaryProps> & ErrorBoundaryProps & WithAnalyticsEventsProps,
    State
> {
    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    state = {
        error: undefined,
    };

    componentDidCatch(error: Error): void {
        if (error.name !== 'ChunkLoadError') {
            const { createAnalyticsEvent } = this.props;
            if (createAnalyticsEvent !== undefined) {
                // This analytics will help us to note any errors which were previously marked as chunk errors. This might get triggered multiple times if islooselyLazyJsErrorsToSentryEnabled is true.
                const analyticsEvent = createAnalyticsEvent({
                    analyticsType: OPERATIONAL_EVENT_TYPE,
                    action: 'failed',
                    errorMessage: error.message,
                    errorName: error.name,
                    stack: error.stack,
                });
                const finalId = 'looselyLazyErrorBoundary';
                const finalPackage = 'looselyLazyPackage';
                const actionSubject = `${finalPackage}.${finalId}`;
                analyticsEvent.context.push({ componentName: actionSubject });
                analyticsEvent.fire();
            }
        }
        if (islooselyLazyJsErrorsToSentryEnabled()) {
            // Differentiate between a chunkLoadError and other JS error.
            if (error.name === 'ChunkLoadError') {
                trackError('async.loader.failed', {}, error);
                this.props.onError?.(error);
            } else {
                this.props.onError?.(error);
                // this error will be caught by ScreenErrorBoundary at route level which will add it to sentry with packageName derived from route ScreenName
                throw error;
            }
        } else {
            trackError('async.loader.failed', {}, error);
            this.props.onError?.(error);
        }
    }

    render() {
        const error = this.state.error;

        return error ? this.props.renderError(error) : this.props.children;
    }
}
export const ErrorBoundaryAnalytics = withAnalyticsContext()(withAnalyticsEvents()(ErrorBoundary));
