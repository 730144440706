import memoizeOne from 'memoize-one';
import type {
    ViewConfiguration,
    BaseFieldConfiguration,
    AnalyticsResult,
    FieldType,
} from '@atlassian/ui-modifications-core/types';
import { getCommonFieldValidators } from '@atlassian/ui-modifications-core/utils';
import { validateSingleLineTextValue } from '@atlassian/ui-modifications-public-api-utils/field/single-line-text';
import type { RequestCreateExecutionContext } from '../../types/context';
import {
    cancelTTI,
    markFieldsRegistered,
    markIframeLoad,
    markOnInitResolved,
    stopTTI,
} from '../performance-analytics/request-create';
import { SUMMARY_TYPE, SELECT_CF_TYPE, USER_CF_TYPE, MULTI_CHECKBOXES_CF_TYPE } from './constants';
import { singleLineTextFieldGetValueTransformer } from './fields/single-line-text';
import messages from './messages';

export interface RequestCreateViewFieldConfiguration extends BaseFieldConfiguration {
    initializeFieldPropertyMapping?: Record<string, string>;
}

export class RequestCreateViewConfiguration
    implements ViewConfiguration<RequestCreateViewFieldConfiguration, RequestCreateExecutionContext>
{
    private analytics: AnalyticsResult = {
        stopTTI,
        cancelTTI,
        markIframeLoad,
        markFieldsRegistered,
        markOnInitResolved,
    };

    getAnalytics() {
        return this.analytics;
    }

    /**
     * memoizeOne is used to avoid recreating the config objects.
     */
    getSupportedFieldsConfiguration = memoizeOne((): Record<FieldType, RequestCreateViewFieldConfiguration> => {
        const commonFieldValidators = getCommonFieldValidators();

        return {
            [SUMMARY_TYPE]: {
                publicShapeValidators: {
                    ...commonFieldValidators,
                    value: validateSingleLineTextValue,
                },
                publicToInternalTransformers: {
                    value: (stringValue: string) => ({
                        basic: stringValue,
                        details: stringValue,
                    }),
                },
                internalToPublicTransformers: { value: singleLineTextFieldGetValueTransformer },
                emitChangeOn: 'blur',
            },
            // [SELECT_CF_TYPE]: {
            //     publicShapeValidators: {
            //         ...commonFieldValidators,
            //         value: () => true,
            //         optionsVisibility: () => true,
            //     },
            //     publicToInternalTransformers: {
            //         value: (optionId: string, options: SingleSelectOption[]) => {
            //             const option = options?.find((item) => item.value === optionId);
            //             const basic = option?.value ?? null;

            //             return {
            //                 basic,
            //                 details: option,
            //             };
            //         },
            //         optionsVisibility: (
            //             { options, isVisible }: { options: string[]; isVisible: boolean },
            //             lookupValues: SingleSelectOption[]
            //         ) => {
            //             return lookupValues.filter(({ value }) =>
            //                 isVisible ? options.includes(value) : !options.includes(value)
            //             );
            //         },
            //     },
            //     initializeFieldPropertyMapping: {
            //         allowedValues: 'options',
            //         value: 'defaultValue',
            //     },
            //     propertyMapping: {
            //         optionsVisibility: 'options',
            //     },
            //     internalToPublicTransformers: {},
            //     emitChangeOn: 'change',
            // },
            /**
             * These are the next fields to be implemented
             * Let's keep this code here for reference
             * */
            // [MULTI_CHECKBOXES_CF_TYPE]: {
            //     publicShapeValidators: {
            //         ...commonFieldValidators,
            //         optionsVisibility: () => true,
            //     },
            //     publicToInternalTransformers: {
            //         optionsVisibility: (
            //             { options, isVisible }: { options: string[]; isVisible: boolean },
            //             lookupValues: SingleSelectOption[]
            //         ) => {
            //             return lookupValues.filter(({ value }) =>
            //                 isVisible ? options.includes(value) : !options.includes(value)
            //             );
            //         },
            //     },
            //     initializeFieldProprtyMapping: {
            //         allowedValues: 'items',
            //     },
            //     propertyMapping: {
            //         optionsVisibility: 'items',
            //     },
            // },
            // [USER_CF_TYPE]: {
            //     publicShapeValidators: {
            //         ...commonFieldValidators,
            //         value: () => true,
            //     },
            //     publicToInternalTransformers: {
            //         value: (value: string) => ({
            //             basic: value,
            //             details: {
            //                 value,
            //                 accountId: value,
            //                 label: `The fake user name of ${value} since there is no real UIM`,
            //             },
            //         }),
            //     },
            //     initializeFieldProprtyMapping: {
            //         value: 'defaultUser',
            //     },
            //     emitChangeOn: 'change',
            // },
        };
    });

    getSupportedFieldTypes = memoizeOne(() => {
        const supportedFieldTypes: string[] = [SUMMARY_TYPE, SELECT_CF_TYPE, USER_CF_TYPE, MULTI_CHECKBOXES_CF_TYPE];

        return supportedFieldTypes;
    });

    /**
     * On other views in Jira, we check if we support a specific project type available from execution context
     * Here, in JSM, there is nothing specific available from Execution Context that we should check
     */
    isExecutionContextSupported(): boolean {
        return true;
    }

    getConsentMessage() {
        return messages.obligatoryUimRequestCreateConsentMessage;
    }

    getSupportedTriggerPointsConfiguration() {
        return new Set(['DEFAULT'] as const);
    }

    // Note: should be removed by bumping @atlassian/ui-modifications-core to the next minor version. Behavior will default to being true.
    isScreenTabsSupportEnabled = () => true;
}
