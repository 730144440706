import EditHomepageV2Query from 'view/advanced-help-center/edit-homepage-v2/__generated__/editHomepageV2Query.graphql';
import type { editHomepageV2Query } from 'view/advanced-help-center/edit-homepage-v2/__generated__/editHomepageV2Query.graphql';
import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';

export const EditHomepageV2Resource = createRelayResource<editHomepageV2Query>({
    type: 'EDIT_HOMEPAGE',
    getQuery: (_, { cloudId, helpCenterAri, layoutAri }) => {
        return {
            parameters: EditHomepageV2Query,
            variables: {
                cloudId,
                helpCenterAri,
                layoutAri,
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
