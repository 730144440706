import { metrics } from '@atlassian/browser-metrics';
import type { StopTTICustomData } from '@atlassian/ui-modifications-analytics/types';

export const requestCreateTTIWithUIModifications = metrics.interaction({
    key: 'request-create-tti-with-ui-modifications',
    timings: [
        { key: 'script-execution', startMark: 'iframe-load' },
        { key: 'loading-indicators-visibility', startMark: 'fields-registered' },
        { key: 'time-until-oninit-resolved', endMark: 'oninit-resolved' },
    ],
});

export const startScreenTTIWithUIModifications = () => {
    requestCreateTTIWithUIModifications.start();
};

export const stopTTI = (customData: StopTTICustomData) => {
    requestCreateTTIWithUIModifications.stop({ customData });
};

export const cancelTTI = () => {
    requestCreateTTIWithUIModifications.cancel();
};

export const markIframeLoad = () => {
    requestCreateTTIWithUIModifications.mark('iframe-load');
};

export const markFieldsRegistered = () => {
    requestCreateTTIWithUIModifications.mark('fields-registered');
};

export const markOnInitResolved = () => {
    requestCreateTTIWithUIModifications.mark('oninit-resolved');
};
