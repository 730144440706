import React from 'react';
import { styled } from '@compiled/react';
import { di } from 'react-magnetic-di';
import { HEADER_HEIGHT } from 'view/common/styled';
import { ActionBarStateless } from 'view/layout-next/action-bar-stateless';
import { Box, xcss } from '@atlaskit/primitives';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { LoadingSkeleton } from '@atlassian/help-center-common-component/loading';

const SECTION_HEIGHT = 232;

export interface FlexibleEditpageLoaderProps {
    showActionBar?: boolean;
    removeHeader?: boolean;
    sectionCount?: number;
}

const FlexibleEditpageLoader: React.FC<FlexibleEditpageLoaderProps> = ({
    showActionBar,
    removeHeader,
    sectionCount = 5,
}) => {
    di(UFOLoadHold);

    return (
        <UFOLoadHold name="flexible-edit-page-loader">
            {showActionBar && <ActionBarStateless previewButtonDisabled publishButtonDisabled />}
            <Box xcss={container}>
                {/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/design-system/ensure-design-token-usage */}
                <Box as="main" xcss={skeletonContainerWithSidebar} style={{ marginRight: '340px' }}>
                    <Box xcss={layoutBuilderWrapper}>
                        {!removeHeader && (
                            <Box xcss={headerContainer}>
                                <LoadingSkeleton
                                    data-testid="flexible-hc-header-skeleton"
                                    height={HEADER_HEIGHT}
                                    backgroundColor={token('color.skeleton.subtle', colors.N20)}
                                />
                            </Box>
                        )}
                        {Array(sectionCount)
                            .fill(1)
                            .map((_, index) => (
                                <Box xcss={sectionLoader} key={`flexibleHCLoader_${index}`}>
                                    <LoadingSkeleton
                                        data-testid="flexible-hc-section-skeleton"
                                        height={SECTION_HEIGHT}
                                        backgroundColor={token('color.skeleton.subtle', colors.N20)}
                                    />
                                </Box>
                            ))}
                    </Box>
                </Box>
                <Box as="aside" xcss={sidebarWrapper}>
                    <Box xcss={breadcrumbContainer}>
                        {Array(3)
                            .fill(1)
                            .map((_, index) => (
                                <Box xcss={breadCrumbItem} key={`breadCrumbLoader_${index}`}>
                                    <SidePanelLoader
                                        data-testid="flexible-hc-section-skeleton"
                                        height={20}
                                        backgroundColor={token('color.skeleton.subtle', colors.N20)}
                                    />
                                </Box>
                            ))}
                    </Box>
                    {Array(4)
                        .fill(1)
                        .map((_, index) => (
                            <Box
                                xcss={[
                                    configWrapper,
                                    index === 0 && configWrapper24PxWidth,
                                    index === 3 && configWrapper146PxWidth,
                                ]}
                                key={`ConfigPanelLoader_${index}`}
                            >
                                <SidePanelLoader
                                    data-testid="flexible-hc-section-skeleton"
                                    height={20}
                                    backgroundColor={token('color.skeleton.subtle', colors.N20)}
                                />
                            </Box>
                        ))}
                </Box>
            </Box>
        </UFOLoadHold>
    );
};

const skeletonContainerWithSidebar = xcss({
    position: 'relative',
    padding: 'space.150',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: 'elevation.surface.hovered',
    alignItems: 'center',
    overflowY: 'auto',
    height: 'calc(100vh - 56px)',
});

const headerContainer = xcss({
    width: '100%',
});

const container = xcss({
    display: 'flex',
    justifyContent: 'stretch',
    flex: '1 1 auto',
});

const sidebarWrapper = xcss({
    flex: 0,
    position: 'fixed',
    right: 'space.0',
    width: '340px',
    backgroundColor: 'elevation.surface',
    zIndex: 'navigation',
    minHeight: '-webkit-fill-available',
    boxShadow: 'elevation.shadow.overlay',
    height: 'calc(100vh - 56px)',
});

const layoutBuilderWrapper = xcss({
    position: 'relative',
    width: '100%',
    borderRadius: 'border.radius.200',
    boxShadow: 'elevation.shadow.overlay',
    backgroundColor: 'elevation.surface.raised',
    overflow: 'visible',
});

const sectionLoader = xcss({
    padding: 'space.400',
    borderRadius: 'border.radius.200',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const SidePanelLoader = styled(LoadingSkeleton)({
    width: '100%',
});

const breadcrumbContainer = xcss({
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    textAlign: 'left',
    padding: 'space.100',
    borderBottomColor: 'color.border',
    borderBottomWidth: 'border.width',
    borderBottomStyle: 'solid',
});

const breadCrumbItem = xcss({
    width: '84px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: 'space.100',
});

const configWrapper = xcss({
    width: '100%',
    height: 'space.250',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 'space.300',
    paddingBottom: 'space.0',
});

const configWrapper24PxWidth = xcss({
    width: '72px',
});

const configWrapper146PxWidth = xcss({
    width: '194px',
});

export default FlexibleEditpageLoader;
