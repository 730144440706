import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isAdvancedCustomizationEnabled, isCustomThemingEnabled, isCsmAccessControlSplitEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { getServiceDeskVersion } from 'state/selectors/env';
import Banner from '@atlaskit/banner';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { SpotlightManager } from '@atlaskit/onboarding';
import { AnalyticsContext } from '@atlassian/analytics-web-react';
import { FeedbackCollector } from '@atlassian/help-center-common-component/feedback-collector';
import { FlagGroup } from '@atlassian/help-center-common-component/flag-group';
import { MarkAppMounted } from '@atlassian/help-center-common-component/mark-app-mounted';
import {
    isHelpCentersManagement,
    isAdvancedHelpCenter,
    isCSMHelpCenter,
    isJcsOnlyRestrictedRoute,
} from '@atlassian/help-center-common-util/advanced-help-center';
import { isDefaultHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center/advanced-help-center';
import { BrowserMetricsScreenTransitionStatusProvider } from '@atlassian/help-center-common-util/analytics/browsermetrics-screen-transition-status-context';
import { fireAnalyticsForEmptyHelpCenterAri } from '@atlassian/help-center-common-util/analytics/help-center-ari';
import { fireAnalyticsForEmptyHelpCenterLayoutAri } from '@atlassian/help-center-common-util/analytics/help-center-layout-ari';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { hasValidHelpCenterARI } from '@atlassian/help-center-common-util/help-center-ari';
import { isValidLayoutAriPresent } from '@atlassian/help-center-common-util/meta';
import { isBrowserUnsupported } from '@atlassian/help-center-common-util/unsupported-browsers';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { AppContainer } from '../app-container';
import CSMResourceRouterOld, {
    CSMResourceRouterNew as CSMResourceRouter,
} from './customer-service-management-resource-router';
import { helpCenterViewHomePageExperience } from './experiences';
import HelpCentersManagementResourceRouter from './help-centers-management-resource-router';
import { AsyncJSMCookieConsentBanner } from './jsm-cookie-consent-banner';
import JsmResourceRouter from './jsm-resource-router';
import messages from './messages';

export interface Props {
    serviceDeskVersion: string;
}

// eslint-disable-next-line @atlassian/ssr-linting/no-dom-globals-in-module-scope
const BUILD_KEY = window.JSD_CUSTOMER_PORTAL_BUILD_VERSION?.buildKey || 'local';

export const App = (props: Props) => {
    di(hasValidHelpCenterARI, isHelpCentersManagement, isCsmAccessControlSplitEnabled, getEnv);
    const { serviceDeskVersion } = props;
    const isHelpCentersManagementPath = isHelpCentersManagement();
    const shouldUseCsmRouter =
        isCSMHelpCenter(getEnv().helpCenterType) || isJcsOnlyRestrictedRoute(getEnv().customerAccountAvailabilty);
    const { formatMessage } = useIntl();
    useUFOComponentExperience(helpCenterViewHomePageExperience);
    useEffect(() => {
        if (!isHelpCentersManagementPath) {
            if (!hasValidHelpCenterARI()) {
                fireAnalyticsForEmptyHelpCenterAri();
                const traceId = getEnv().traceId ?? '';
                // Disabling existing violations, should be fixed when revisited.
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                helpCenterViewHomePageExperience.failure({
                    metadata: {
                        error: `Empty Help Center Ari, traceId: ${traceId}`,
                    },
                });
            } else {
                // Disabling existing violations, should be fixed when revisited.
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                helpCenterViewHomePageExperience.success();
            }
        }
    }, [isHelpCentersManagementPath]);

    useEffect(() => {
        if (!isHelpCentersManagementPath) {
            if (isAdvancedCustomizationEnabled() && !isValidLayoutAriPresent()) {
                fireAnalyticsForEmptyHelpCenterLayoutAri();
            }
        }
    }, [isHelpCentersManagementPath]);

    const analyticData = {
        version: serviceDeskVersion,
        application: 'jira-servicedesk',
        buildKey: BUILD_KEY,
    };

    const isAdvancedHelpCenterThemingEnabled =
        (isAdvancedHelpCenter() || isDefaultHelpCenter()) &&
        isCustomThemingEnabled() &&
        FeatureGates.checkGate('advanced_help_center_customization');

    const isCsmAccessControlSplitFgEnabled = isCsmAccessControlSplitEnabled();

    const getResourceRouter = React.useCallback(() => {
        if (shouldUseCsmRouter) {
            if (isCsmAccessControlSplitFgEnabled) {
                return isAdvancedHelpCenterThemingEnabled ? (
                    <AppContainer>
                        <CSMResourceRouter />
                    </AppContainer>
                ) : (
                    <CSMResourceRouter />
                );
            }
            return isAdvancedHelpCenterThemingEnabled ? (
                <AppContainer>
                    <CSMResourceRouterOld />
                </AppContainer>
            ) : (
                <CSMResourceRouterOld />
            );
        }
        if (isHelpCentersManagementPath) {
            return <HelpCentersManagementResourceRouter />;
        }
        return isAdvancedHelpCenterThemingEnabled ? (
            <AppContainer>
                <JsmResourceRouter />
            </AppContainer>
        ) : (
            <JsmResourceRouter />
        );
    }, [
        shouldUseCsmRouter,
        isAdvancedHelpCenterThemingEnabled,
        isHelpCentersManagementPath,
        isCsmAccessControlSplitFgEnabled,
    ]);

    return (
        <SpotlightManager blanketIsTinted={false}>
            <AnalyticsContext attributes={analyticData}>
                <BrowserMetricsScreenTransitionStatusProvider>
                    <AsyncJSMCookieConsentBanner />
                    {isBrowserUnsupported() && (
                        <Banner appearance="warning" icon={<WarningIcon label="" secondaryColor="inherit" />}>
                            {formatMessage(messages.unsupportedBrowser)}
                        </Banner>
                    )}
                    {getResourceRouter()}
                    <FlagGroup />
                    <FeedbackCollector />
                    <MarkAppMounted />
                </BrowserMetricsScreenTransitionStatusProvider>
            </AnalyticsContext>
        </SpotlightManager>
    );
};

const selector = createSelector(getServiceDeskVersion, (serviceDeskVersion) => ({
    serviceDeskVersion,
}));

export default connect(selector)(App);
