import * as React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { useRouter, withRouter } from 'react-resource-router';
import { Article } from 'view/article';
import { KnowledgeCategory } from 'view/knowledge-category';
import type { PaperAppearance } from 'view/layout/paper';
import { ScreenErrorBoundary } from 'view/layout/screen-error-boundary';
import { fg } from '@atlaskit/platform-feature-flags';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { ContextSetter } from '@atlassian/help-center-common-component/context';
import { withAsync } from '@atlassian/help-center-common-component/with-async';
import { AnalyticsScreen } from '@atlassian/help-center-common-util/analytics/analytics-screen';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { PORTAL_ROUTE_PREFIX } from '../common/constants';

const AllSearchResultsV1 = withAsync({
    loader: lazyForPaint(() => import(/* webpackChunkName: "all-search-results" */ '../../view/search/all-results')),
});

const AllSearchResults = withAsync({
    loader: lazyForPaint(
        () => import(/* webpackChunkName: "all-search-results-v2" */ '@atlassian/help-center-search/src/all-results')
    ),
});

const RenderKnowledgeCategoryRoute = () => {
    const [routerState] = useRouter();
    const { basePath, ...routeContext } = routerState;
    return (
        <AnalyticsScreen screenName={ScreenName.KNOWLEDGE_CATEGORY} screenId={routeContext.match.url}>
            <ScreenErrorBoundary screenName={ScreenName.KNOWLEDGE_CATEGORY}>
                <KnowledgeCategory {...routeContext} />
            </ScreenErrorBoundary>
        </AnalyticsScreen>
    );
};

const RenderArticlesSearchRoute = () => {
    const [routerState] = useRouter();
    const { basePath, ...routeContext } = routerState;

    return (
        <AnalyticsScreen screenName={ScreenName.KNOWLEDGE_ALL_SEARCH_RESULTS} screenId={routeContext.match.url}>
            <ScreenErrorBoundary screenName={ScreenName.KNOWLEDGE_ALL_SEARCH_RESULTS}>
                <>
                    <ContextSetter {...routeContext} />
                    {fg('helpcenter_search_api_changes') ? (
                        <AllSearchResults {...routeContext} />
                    ) : (
                        <AllSearchResultsV1 {...routeContext} />
                    )}
                </>
            </ScreenErrorBoundary>
        </AnalyticsScreen>
    );
};

export const getPaperAppearance = (isArticleView: boolean): PaperAppearance => {
    return isArticleView ? 'xxwide' : 'xwide';
};

const viewKbPage = {
    resources: [],
    name: 'view-kb-page',
    path: '/kb/view/:pageId',
    exact: true,
    component: Article,
};

const portalKnowledgeCategory = {
    resources: [],
    name: 'view-portal-kb-category',
    path: `/portal/:portalId/topic/:categoryId`,
    exact: true,
    component: RenderKnowledgeCategoryRoute,
};

const viewPortalCategoryArticle = {
    resources: [],
    name: 'view-portal-kb-category-article',
    path: `${PORTAL_ROUTE_PREFIX}/topic/:categoryId/article/:articleId`,
    exact: true,
    component: Article,
};

const viewPortalArticle = {
    resources: [],
    name: 'view-portal-kb-article',
    path: `${PORTAL_ROUTE_PREFIX}/article/:articleId`,
    exact: true,
    component: Article,
};

const viewArticle = {
    resources: [],
    name: 'view-kb-article',
    path: `/article/:articleId`,
    exact: true,
    component: Article,
};

const viewCrossSitePortalArticle = {
    resources: [],
    name: 'view-portal-cross-site-kb-article',
    path: `${PORTAL_ROUTE_PREFIX}/cloudId/:cloudId/article/:articleId`,
    exact: true,
    component: Article,
};

const viewCrossSiteArticle = {
    resources: [],
    name: 'view-kb-cross-site-article',
    path: `/cloudId/:cloudId/article/:articleId`,
    exact: true,
    component: Article,
};

const viewAllSearchResults = {
    resources: [],
    name: 'view-all-search-results',
    path: `/articles/search`,
    exact: true,
    component: fg('helpcenter_search_api_changes') ? AllSearchResults : AllSearchResultsV1,
};

const viewPortalAllSearchResults = {
    resources: [],
    name: 'view-portal-all-search-results',
    path: `${PORTAL_ROUTE_PREFIX}/articles/search`,
    exact: true,
    component: fg('helpcenter_search_api_changes') ? AllSearchResults : AllSearchResultsV1,
};

const categoryLayoutProps = {
    paperAppearance: getPaperAppearance(false),
    fadeInSearchModal: true,
};

const layoutProps = {
    paperAppearance: getPaperAppearance(true),
    fadeInSearchModal: true,
};

export const viewKbPageRoute = createRouteWithLayout(viewKbPage, ScreenName.KNOWLEDGE_ARTICLE, layoutProps);

export const viewAllSearchResultsRoute = {
    ...viewAllSearchResults,
    component: withRouter(RenderArticlesSearchRoute),
};

export const viewPortalAllSearchResultsRoute = {
    ...viewPortalAllSearchResults,
    component: withRouter(RenderArticlesSearchRoute),
};

export const viewPortalKnowledgeCategoryRoute = createRouteWithLayout(
    portalKnowledgeCategory,
    ScreenName.KNOWLEDGE_CATEGORY,
    categoryLayoutProps
);
export const viewPortalCategoryArticleRoute = createRouteWithLayout(
    viewPortalCategoryArticle,
    ScreenName.KNOWLEDGE_ARTICLE,
    layoutProps
);
export const viewPortalArticleRoute = createRouteWithLayout(
    viewPortalArticle,
    ScreenName.KNOWLEDGE_ARTICLE,
    layoutProps
);
export const viewArticleRoute = createRouteWithLayout(viewArticle, ScreenName.KNOWLEDGE_ARTICLE, layoutProps);

export const viewCrossSitePortalArticleRoute = createRouteWithLayout(
    viewCrossSitePortalArticle,
    ScreenName.KNOWLEDGE_ARTICLE,
    layoutProps
);

export const viewCrossSiteArticleRoute = createRouteWithLayout(
    viewCrossSiteArticle,
    ScreenName.KNOWLEDGE_ARTICLE,
    layoutProps
);
