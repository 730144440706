import * as React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { Flex, Box, Stack, xcss } from '@atlaskit/primitives';
import { SkeletonItem } from '@atlassian/help-center-common-component/skeleton-item';
import { MANAGE_PAGE_HEAD_WIDTH } from '../../constants';

const ManagePagesLayoutSkeleton = () => {
    const head = {
        cells: [
            {
                key: 'page-name',
                content: <SkeletonItem width="20%" height="16px" />,
                width: MANAGE_PAGE_HEAD_WIDTH['page-name'],
            },
            {
                key: 'last-updated',
                content: <SkeletonItem width="20%" height="16px" />,
                width: MANAGE_PAGE_HEAD_WIDTH['last-updated'],
            },
            {
                key: 'page-description',
                content: <SkeletonItem width="40%" height="16px" />,
                width: MANAGE_PAGE_HEAD_WIDTH['page-description'],
            },
            {
                key: 'action-button',
                content: <SkeletonItem width="40%" height="16px" />,
                width: MANAGE_PAGE_HEAD_WIDTH['action-button'],
            },
        ],
    };
    const rows = Array.from({ length: 20 }).map((_, index) => ({
        key: `${index}`,
        cells: [
            {
                content: (
                    <Box paddingBlock="space.050">
                        <SkeletonItem width="60%" height="20px" />
                    </Box>
                ),
            },
            {
                content: (
                    <Box paddingBlock="space.050">
                        <SkeletonItem width="60%" height="20px" />
                    </Box>
                ),
            },
            {
                content: (
                    <Box paddingBlock="space.050">
                        <SkeletonItem width="60%" height="20px" />
                    </Box>
                ),
            },
            {
                content: (
                    <Box paddingBlock="space.050">
                        <SkeletonItem width="20%" height="20px" />
                    </Box>
                ),
            },
        ],
    }));
    return (
        <Stack space="space.300">
            <Stack space="space.300">
                <Box paddingBlockStart="space.400">
                    <Flex direction="row" gap="space.100">
                        <SkeletonItem width="84px" height="24px" />
                        <SkeletonItem width="84px" height="24px" />
                    </Flex>
                </Box>
                <Flex direction="row" justifyContent="space-between">
                    <SkeletonItem width="92px" height="28px" data-testid="manage-page-title-skeleton" />
                    <SkeletonItem width="92px" height="28px" data-testid="manage-page-create-button-skeleton" />
                </Flex>
                <Stack space="space.050">
                    <SkeletonItem width="75%" height="20px" />
                </Stack>
            </Stack>
            <Box xcss={tableSkeletonStyles}>
                <DynamicTable head={head} rows={rows} isFixedSize isRankingDisabled />
            </Box>
        </Stack>
    );
};

const tableSkeletonStyles = xcss({
    pointerEvents: 'none',
});

export default ManagePagesLayoutSkeleton;
