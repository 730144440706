import { useCallback } from 'react';
import { getAnalyticsWebClient } from '@helpCenter/util/analytics';
import { di } from 'react-magnetic-di';
import type { DocNode } from '@atlaskit/adf-schema';
import { traverse } from '@atlaskit/adf-utils/traverse';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import type {
    Message,
    AgentMessage,
    HelpseekerMessageBody,
} from '@atlassian/help-center-virtual-agent/src/common/types';
import { ConversationProvider } from '../../../common/types';
import { ASSISTANT_MESSAGE_STATUS } from '../../../rest/converse';
import { useActions as useAIAnswersActions } from '../../../store/converse';
import type { ChatMessage } from '../../../store/converse/types';
import { useActions } from '../../../store/routing';

export const useHandOffConversation = () => {
    di(useActions, useAIAnswersActions, getAnalyticsWebClient);

    const { setProvider } = useActions();
    const { takeOverConversationFromVA } = useAIAnswersActions();
    const analyticsClient = getAnalyticsWebClient().getInstance();

    const sendHandOffAnalyticsEvent = useCallback(
        (conversationId: string, contextId: string) => {
            // https://data-portal.internal.atlassian.com/analytics/registry/70690
            analyticsClient
                .sendTrackEvent({
                    action: 'rejected',
                    actionSubject: 'inferIntent',
                    actionSubjectId: 'globalInferIntentFast',
                    attributes: {
                        conversationId,
                        contextId,
                    },
                    source: ScreenName.CONVERSATION_PAGE,
                })
                .catch((error: string) => {
                    throw new Error(`Failure to send track analytics event ${error}`);
                });
        },
        [analyticsClient]
    );

    const handleHandOff = useCallback(
        async (vaMessages: Message[], conversationId: string, contextId: string) => {
            sendHandOffAnalyticsEvent(conversationId, contextId);

            if (!vaMessages.length || vaMessages[0].message.authorType !== 'HELPSEEKER') {
                throw Error('Cannot hand off conversation without a helpseeker message');
            }

            const history = vaMessages
                .map(transformVAMessageToAIAnswersMessage)
                .filter((message): message is ChatMessage => message !== null);
            await takeOverConversationFromVA(conversationId, history);
            setProvider(ConversationProvider.AI_ANSWERS);
        },
        [sendHandOffAnalyticsEvent, setProvider, takeOverConversationFromVA]
    );

    return { handleHandOff };
};

const extractMessageContent = (message: HelpseekerMessageBody): string => {
    let originalContent = '';
    traverse(message.content, {
        text: (node) => {
            originalContent += node.text;
        },
    });
    return originalContent;
};

const transformVAMessageToAIAnswersMessage = (message: Message, index: number): ChatMessage | null => {
    switch (message.message.authorType) {
        case 'HELPSEEKER':
            return {
                userType: 'helpseeker',
                messageContentAdf: message.message.content,
                messageMetadata: {
                    ...(index === 0 ? { originalQuery: extractMessageContent(message.message) } : {}),
                },
            };
        case 'AGENT':
            return {
                userType: 'assistant',
                aiMateConversationResponse: {
                    message: {
                        content: parseMessageContent(message.message),
                        role: 'assistant',
                        id: String(index), //
                        time_created: message.timeCreated,
                        user_ari: '',
                        plugin_invocations: [],
                    },
                },
                messageMetadata: {
                    status: ASSISTANT_MESSAGE_STATUS.SYNCED_RESPONSE,
                },
            };
        default:
            return null;
    }
};

const parseMessageContent = (message: AgentMessage['message']): DocNode | string => {
    if (message.contentMimetype === 'application/json+adf') {
        return JSON.parse(message.content) as DocNode;
    }
    return message.content;
};
