import React from 'react';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import type { CmdbObjectAttribute, ObjectAttributeValue } from '../../../../rest/cmdb-object-picker';

interface Props {
    attributes: CmdbObjectAttribute[];
}

export const AttributeList = ({ attributes }: Props) => {
    const objectAttributeValuesAreNotEmpty = (values: ObjectAttributeValue[]): boolean => {
        return values.every((value) => Object.keys(value).length > 0);
    };

    const renderAttribute = (attribute: CmdbObjectAttribute): JSX.Element => {
        return (
            <>
                {attribute.objectAttributeValues.map((value, index) => (
                    <div key={index}>{value.displayValue}</div>
                ))}
            </>
        );
    };

    return (
        <Box xcss={ListWrapperStyles}>
            <Box as="dl" xcss={ListStyles}>
                {attributes.map(
                    (attribute) =>
                        objectAttributeValuesAreNotEmpty(attribute.objectAttributeValues) && (
                            <Box key={attribute.id} xcss={AttributeWrapperStyles}>
                                <Box as="dt" xcss={AttributeLabelWrapperStyles}>
                                    <Heading size="xsmall" as="span">
                                        <Box as="span" xcss={attributeLabelStyles}>
                                            {attribute.objectTypeAttribute.name}
                                        </Box>
                                    </Heading>
                                </Box>
                                <Box as="dd" xcss={AttributeValueStyles}>
                                    {renderAttribute(attribute)}
                                </Box>
                            </Box>
                        )
                )}
            </Box>
        </Box>
    );
};

const ListStyles = xcss({
    paddingLeft: 'space.0',
});

const ListWrapperStyles = xcss({
    /* Offset the outside padding from children */
    // Half vertical space between each attribute, half horizontal space between label & value
    marginTop: 'space.negative.050',
    marginRight: 'space.negative.150',
    marginBottom: 'space.negative.050',
    marginLeft: 'space.negative.150',
});

const attributeLabelStyles = xcss({ color: 'color.text.subtle' });

const AttributeLabelWrapperStyles = xcss({
    marginBlock: 'space.050',
    marginInline: 'space.150',
    minHeight: 'space.300',
    lineHeight: 'space.300',
    flexBasis: '112px',
    flexGrow: 1,
});

const AttributeValueStyles = xcss({
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    color: 'color.text',
    // Half vertical space between each attribute, half horizontal space between label & value
    marginTop: 'space.050',
    marginRight: 'space.150',
    marginBottom: 'space.050',
    marginLeft: 'space.150',
    minHeight: 'space.300',
    lineHeight: 'space.300',
    flexBasis: '224px',
    flexGrow: 1,
});

const AttributeWrapperStyles = xcss({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
});
