import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getHelpCenterBrandingLoader, getThemeColor, getHelpCenterTopBarSplitFlag } from 'state/selectors/help-center';
import { BannerWithLayoutHeader, BannerWithLayoutHeaderMemoized } from './banner-with-layout-header';

const selector = createSelector(
    getThemeColor,
    getHelpCenterBrandingLoader,
    getHelpCenterTopBarSplitFlag,
    (themeColor, isBrandingLoaded, hasTopBarBeenSplit) => ({
        themeColor,
        isBrandingLoaded,
        hasTopBarBeenSplit,
    })
);

const ConnectedBannerWithLayoutHeader = connect(selector)(BannerWithLayoutHeader);
export default ConnectedBannerWithLayoutHeader;

export const BannerWithLayoutHeaderMemoizedHOC = connect(selector)(BannerWithLayoutHeaderMemoized);
