import React from 'react';
import { FlexibleHomepageLoader } from '@helpCenter/view/advanced-help-center/flexible-homepage-loader';
import { isIncreasedFlexibilityEnabled } from 'feature-flags';
import { lazyForPaint } from 'react-loosely-lazy';
import { FlexibleEditpageLoader } from 'view/advanced-help-center/flexible-edit-page-loader';
import { withAsync } from '@atlassian/help-center-common-component/with-async';

export const EditCustomPage = withAsync({
    loader: lazyForPaint(() => import(/* webpackChunkName: 'async-view-custom-page' */ './edit-custom-page')),
    LoadingSkeleton: () =>
        isIncreasedFlexibilityEnabled() ? (
            <FlexibleEditpageLoader showActionBar />
        ) : (
            <FlexibleHomepageLoader showActionBar />
        ),
});
