import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
    getLogoUrl,
    getSharedPortalName,
    getHelpCenterTopBarSplitFlag,
    getHelpCenterTopBarTextColor,
} from 'state/selectors/help-center';
import { Logo, LogoMemoized } from './logo';

const selector = createSelector(
    getLogoUrl,
    getSharedPortalName,
    getHelpCenterTopBarTextColor,
    getHelpCenterTopBarSplitFlag,
    (url, name, topBarTextColor, hasTopBarBeenSplit) => ({
        url,
        name,
        topBarTextColor: hasTopBarBeenSplit ? topBarTextColor : undefined,
    })
);

export const LogoMemoizedHOC = connect(selector)(LogoMemoized);
export default connect(selector)(Logo);
