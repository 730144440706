import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { sanitize } from 'dompurify';
import { isIsomorphicDompurifyLibUsed } from 'feature-flags';
import { sanitize as isomorphicSanitize } from 'isomorphic-dompurify';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';
import { usePathParam } from 'react-resource-router';
import type { State } from 'state';
import { getThemeColor } from 'state/selectors/help-center';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Box, Inline, xcss, Text } from '@atlaskit/primitives';
import { Link } from '@atlassian/help-center-common-component/analytics/link';
import { urlRefsAndTarget } from '@atlassian/help-center-common-util/is-external-resource';
import articleMessages from '../articles/messages';
import { formatText, sendSearchUIAnalyticEvents, useVisibilityState } from '../common';
import { ResourceIcon } from '../resource-icon';
import type { requestFormItemFragmentV2$key } from './__generated__/requestFormItemFragmentV2.graphql';

export interface Props {
    itemOrder: number;
    term: string;
    themeColor: string;
    result: requestFormItemFragmentV2$key;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const RequestFormItem = ({ itemOrder, result, term, themeColor, createAnalyticsEvent }: Props) => {
    const { formatMessage } = useIntl();
    const data = useFragment<requestFormItemFragmentV2$key>(
        graphql`
            fragment requestFormItemFragmentV2 on HelpObjectStoreRequestTypeSearchResult {
                id
                title
                displayLink
                metadata {
                    isExternal
                    searchStrategy
                }
            }
        `,
        result
    );
    const [portalId] = usePathParam('portalId');
    const { isShortcutIconVisible, setIsHovered, setIsFocused } = useVisibilityState();
    const { title, displayLink, id, metadata } = data;
    const { isExternal, searchStrategy } = metadata;
    const shortcutIcon = isExternal ? (
        <ShortcutIcon label={formatMessage(articleMessages.shortcutIcon)} size="small" />
    ) : null;
    const highlightedTitle = title ? formatText(title, term) : '';
    const linkProps = urlRefsAndTarget({ displayLink, isAnExternalResource: isExternal });

    useEffect(() => {
        sendSearchUIAnalyticEvents(
            {
                searchAlgorithm: searchStrategy,
                action: 'list-item-rendered',
                resourceType: 'request-form',
            },
            createAnalyticsEvent,
            portalId
        );
    }, [createAnalyticsEvent, portalId, searchStrategy]);

    return (
        <Box
            xcss={containerStyles}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocusCapture={() => setIsFocused(true)}
            onBlurCapture={() => setIsFocused(false)}
        >
            <Inline xcss={headingStyles} space="space.100">
                <ResourceIcon entityType="REQUEST_FORM" displayLink={displayLink} />
                <Link
                    key={id}
                    actionSubjectId={isExternal ? 'external-request-form-link' : 'request-form-link'}
                    data-test-id="result-link-container"
                    aria-label={title}
                    attributes={{
                        itemOrder,
                        searchAlgorithm: searchStrategy,
                    }}
                    {...linkProps}
                >
                    <Text as="p">
                        <span
                            style={{
                                color: themeColor,
                            }}
                            dangerouslySetInnerHTML={{
                                __html: isIsomorphicDompurifyLibUsed()
                                    ? isomorphicSanitize(highlightedTitle)
                                    : sanitize(highlightedTitle),
                            }}
                        />
                    </Text>
                </Link>
                <Box
                    xcss={isShortcutIconVisible ? headerRightAreaStylesVisible : headerRightAreaStylesInVisible}
                    testId="search-result-shortcut-icon"
                >
                    {shortcutIcon}
                </Box>
            </Inline>
        </Box>
    );
};

const ConnectedRequestFormItem = connect((state: State) => {
    return {
        themeColor: getThemeColor(state),
    };
})(RequestFormItem);

export default withAnalyticsEvents()(ConnectedRequestFormItem);

const containerStyles = xcss({
    overflow: 'hidden',
    width: '100%',
    padding: 'space.100',
    gap: 'space.150',
    borderRadius: 'border.radius.100',
});
const headingStyles = xcss({
    overflow: 'hidden',
    width: '100%',
});
const headerRightAreaStylesInVisible = xcss({
    display: 'flex',
    opacity: 0,
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
});
const headerRightAreaStylesVisible = xcss({
    display: 'flex',
    opacity: 1,
    alignItems: 'flex-start',
});
