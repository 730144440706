import { isCustomPagesUiEnabled, isIncreasedFlexibilityEnabled } from 'feature-flags';
import { defaults, createContainer, createHook, createStore } from 'react-sweet-state';
import type { StoreActionApi } from 'react-sweet-state';
import { EnvType, getEnv } from '@atlassian/help-center-common-util/env';
import { actions } from './actions';
import type { Actions } from './actions';
import {
    getConfigPanelUI,
    getIsPageInEditMode,
    getSectionPickerId,
    getIsPublishing,
    getIsDirty,
    getIsPublishDisabled,
    getMediaConfig,
    getIsPageInBrandingPreviewMode,
    getLayoutConfig,
    getLayoutIds,
    getIsPageInPreviewMode,
    getElementRenderer,
    getSelectedPrimitive,
} from './selectors';
import { type Props, type State, type LayoutId } from './types';

if (getEnv().environment !== EnvType.PROD) {
    defaults.devtools = true;
}

export const getInitialLayoutConfig: () => State['config'] = () => {
    return isIncreasedFlexibilityEnabled()
        ? {
              hideCatalogue: false,
              canAddNewSections: true,
              canAddNewSubSections: false,
              canInteractWithSubSections: false,
              canInteractWithElements: true,
              canDeleteSections: true,
              newSectionCanOnlyAddEditor: true,
          }
        : {
              hideCatalogue: true,
              canAddNewSections: true,
              canAddNewSubSections: false,
              canInteractWithSubSections: false,
              canInteractWithElements: false,
              canDeleteSections: true,
              newSectionCanOnlyAddEditor: true,
          };
};

const emptyLayoutId: LayoutId = { sectionId: undefined, elementId: undefined };

export const layoutIds: State['layoutIds'] = {
    HelpLayoutHeroElement: emptyLayoutId,
    HelpLayoutAnnouncementElement: emptyLayoutId,
    HelpLayoutTopicsListElement: emptyLayoutId,
    HelpLayoutPortalsListElement: emptyLayoutId,
    HelpLayoutSuggestedRequestFormsListElement: emptyLayoutId,
    HelpLayoutForgeElement: [],
    HelpLayoutConnectElement: [],
};

export const initialState: State = {
    layoutIds,
    isPageInEditMode: false,
    isPageInPreviewMode: false,
    isPageInBrandingPreviewMode: false,
    isPublishing: false,
    isPublishDisabled: true,
    isDirty: false,
    mediaConfig: {},
    deletedModulesCount: 0,
    reorderedModulesCount: 0,
    screenName: undefined,
    experience: undefined,
    traceId: undefined,
    events: {
        selected: undefined,
        hovered: undefined,
        dragged: undefined,
        sectionPickerId: undefined,
    },
    configPanel: {
        ui: null,
    },
    config: getInitialLayoutConfig(),
};

export const store = createStore<State, Actions>({
    initialState,
    actions,
    name: 'LayoutBuilderStore',
});

export const onInit =
    () =>
    (
        { setState }: StoreActionApi<State>,
        {
            configPanelUI,
            editMode,
            ElementRenderer,
            screenName,
            experience,
            traceId,
            mediaConfig,
            pageType,
            config = {},
            events = {},
        }: Props
    ) => {
        setState({
            events,
            mediaConfig,
            ElementRenderer,
            screenName,
            experience,
            traceId,
            pageType: isCustomPagesUiEnabled() ? pageType : undefined,
            isPageInEditMode: editMode,
            isPageInPreviewMode: false,
            config: {
                ...getInitialLayoutConfig(),
                ...config,
            },
            configPanel: {
                ui: configPanelUI,
            },
        });
    };

export const LayoutBuilderContainer = createContainer<State, Actions, Props>(store, {
    onInit,
    displayName: 'LayoutBuilderContainer',
});

export const useIsPageInEditMode = createHook(store, {
    selector: getIsPageInEditMode,
});

export const useIsPageInPreviewMode = createHook(store, {
    selector: getIsPageInPreviewMode,
});

export const useSectionPicker = createHook(store, {
    selector: getSectionPickerId,
});

export const useSelectedPrimitive = createHook(store, {
    selector: getSelectedPrimitive,
});

export const useIsPublishing = createHook(store, {
    selector: getIsPublishing,
});

export const useIsPublishDisabled = createHook(store, {
    selector: getIsPublishDisabled,
});

export const useIsDirty = createHook(store, {
    selector: getIsDirty,
});

export const useMediaConfig = createHook(store, {
    selector: getMediaConfig,
});

export const useIsPageInBrandingPreviewMode = createHook(store, {
    selector: getIsPageInBrandingPreviewMode,
});

export const useLayoutConfig = createHook(store, {
    selector: getLayoutConfig,
});

export const useLayoutIds = createHook(store, {
    selector: getLayoutIds,
});

export const useElementRenderer = createHook(store, {
    selector: getElementRenderer,
});

export const useDeletedModulesCount = createHook(store, {
    selector: (state) => state.deletedModulesCount,
});

export const useReorderedModulesCount = createHook(store, {
    selector: (state) => state.reorderedModulesCount,
});

export const useConfigPanelUI = createHook(store, {
    selector: getConfigPanelUI,
});

export const useLayoutActions = createHook(store);
