import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LazySuspense } from 'react-loosely-lazy';
import { di } from 'react-magnetic-di';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { Grid, media, Stack, xcss } from '@atlaskit/primitives';
import { isCSMHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { useIsoMorphicLayoutEffect } from '@atlassian/help-center-common-util/hooks';
import useDebouncedValue from '@atlassian/help-center-common-util/use-debounced-value';
import { Articles } from '../articles';
import { SEARCH_QUERY_DEBOUNCE_TIME } from '../common';
import { csmSearchExperience, searchExperience } from '../experiences';
import { ArticleResultsLoader, NewSearchResultsLoader, SidePanelResultsLoader } from '../loading';
import { SearchResultNone } from '../none';
import { Portals } from '../portals';
import { RequestForms } from '../request-forms';

export interface ResourceProps {
    term: string;
    updateResultsCount: (count: number) => void;
}

export const Resources = ({ term, updateResultsCount }: ResourceProps) => {
    di(getEnv, isCSMHelpCenter);

    const isCSM = isCSMHelpCenter(getEnv().helpCenterType);
    const onlyRenderArticles = isCSM;

    if (onlyRenderArticles) {
        return (
            <LazySuspense fallback={<ArticleResultsLoader />}>
                <Articles term={term} updateResultsCount={updateResultsCount} />
            </LazySuspense>
        );
    }
    return (
        <Grid gap="space.400" templateColumns="1fr" xcss={responsiveStyles}>
            <LazySuspense fallback={<ArticleResultsLoader />}>
                <Articles term={term} updateResultsCount={updateResultsCount} />
            </LazySuspense>
            <Stack space="space.100" alignBlock="start">
                <LazySuspense fallback={<SidePanelResultsLoader />}>
                    <RequestForms term={term} updateResultsCount={updateResultsCount} />
                </LazySuspense>
                <LazySuspense fallback={<SidePanelResultsLoader />}>
                    <Portals term={term} updateResultsCount={updateResultsCount} />
                </LazySuspense>
            </Stack>
        </Grid>
    );
};

export interface Props {
    term: string;
}

type SearchResultProps = Props & WithAnalyticsEventsProps;

export const SearchResult = ({ term }: SearchResultProps) => {
    di(getEnv, isCSMHelpCenter);

    const isCSM = isCSMHelpCenter(getEnv().helpCenterType);

    const [totalCount, setTotalCount] = useState(-1);
    const resultsCount = useRef(-1);
    const callBackCount = useRef(0);
    const debouncedTerm = useDebouncedValue(term, SEARCH_QUERY_DEBOUNCE_TIME);

    // Every time this callback will be called, that resource will increment callBackCount through acknowledgement
    const updateResultsCount = useCallback((count: number, acknowledgement?: number) => {
        if (resultsCount.current < 0) resultsCount.current = 0;

        resultsCount.current += count;

        if (acknowledgement) callBackCount.current += acknowledgement;

        // Update state only when articles, portals and requestForms all have updated the results
        if (callBackCount.current === 3) {
            // Since callbackCount will persist across states, setting it back to 0
            callBackCount.current = 0;
            setTotalCount(resultsCount.current);
        }
    }, []);

    useIsoMorphicLayoutEffect(() => {
        // Reset count to initial state on term change
        resultsCount.current = -1;
        setTotalCount(-1);
        callBackCount.current = 0;
    }, [debouncedTerm]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        isCSM ? csmSearchExperience.start() : searchExperience.start();

        return () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            isCSM ? csmSearchExperience.abort() : searchExperience.abort();
        };
    }, [debouncedTerm, isCSM]);

    const isEmptyResultsState = totalCount === 0;
    const isEmptyQueryState = debouncedTerm === '';
    const showEmptyState = isEmptyQueryState || isEmptyResultsState;
    return (
        <LazySuspense fallback={<NewSearchResultsLoader />}>
            {showEmptyState && <SearchResultNone term={debouncedTerm} />}
            {!isEmptyResultsState && <Resources term={term} updateResultsCount={updateResultsCount} />}
        </LazySuspense>
    );
};

const responsiveStyles = xcss({
    [media.above.xxs]: { gridTemplateColumns: '1fr' },
    [media.above.md]: {
        gridTemplateColumns: '638px 287px',
    },
});
