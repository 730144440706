import React, { Suspense } from 'react';
import noop from 'lodash/noop';
import { ErrorBoundaryAnalytics } from '../error-boundary';
import { Fallback } from '../fallback';
import { LoadingComponent } from '../loading-component';
import type { LazyOptions } from '../types';
export const LazyLoadableComponent = <TProps extends object>(lazyOptions: LazyOptions<object>) => {
    const Component = lazyOptions.lazyLoader;
    const renderError = React.useCallback(
        (error: Error) => {
            return <LoadingComponent error={error} LoadingSkeleton={lazyOptions.LoadingSkeleton} />;
        },
        [lazyOptions.LoadingSkeleton]
    );
    const LazyWrapperWithSuspense: React.FC<TProps> & { preload: () => void } = (props) => {
        if (!Component) return null;
        return (
            <ErrorBoundaryAnalytics renderError={renderError}>
                <Suspense fallback={<Fallback {...lazyOptions} />}>
                    <Component {...props} />
                </Suspense>
            </ErrorBoundaryAnalytics>
        );
    };

    // TODO: Muskein, add preload capabilities. we don't have preload capability in react lazy world yet
    LazyWrapperWithSuspense.preload = noop;
    return LazyWrapperWithSuspense;
};
