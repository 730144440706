/**
 * @generated SignedSource<<3a60bcae1e1e07359a963ad39d3f2f96>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type articleMetadataFragmentV2$data = {
  readonly ari: string;
  readonly containerName: string;
  readonly displayLink: string;
  readonly metadata: {
    readonly isExternal: boolean;
  };
  readonly " $fragmentType": "articleMetadataFragmentV2";
};
export type articleMetadataFragmentV2$key = {
  readonly " $data"?: articleMetadataFragmentV2$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleMetadataFragmentV2">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "articleMetadataFragmentV2",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "ari"
    },
    {
      "kind": "ScalarField",
      "name": "displayLink"
    },
    {
      "kind": "ScalarField",
      "name": "containerName"
    },
    {
      "concreteType": "HelpObjectStoreArticleMetadata",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isExternal"
        }
      ]
    }
  ],
  "type": "HelpObjectStoreArticleSearchResult"
};

(node as any).hash = "4b4d8b2fa1ba79bfe0bad22aa395f082";

export default node;
