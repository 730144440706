export {
    getLogoUrl,
    getSharedPortalName,
    getThemeColor,
    getIsKbEnabled,
    getIsLoaded,
    getHelpCenterBanner,
    getHelpCenterTitle,
    getHelpCenterTitleColor,
    getCanCustomize,
    getHelpCenterAnnoucement,
    getHelpCenterState,
    getDefaultSiteLanguageTag,
    getHelpCenterStateTranslationPersisted,
    getKbHelpCenterLinkDomainURLs,
    getHelpCenterBrandingLoader,
    getHelpCenterTopBarSplitFlag,
    getHelpCenterPersistedTopBarSplitFlag,
    getHelpCenterTopBarColor,
    getHelpCenterTopBarTextColor,
} from './help-center';
