import { lazyForPaint } from 'react-loosely-lazy';
import { withAsync } from '@atlassian/help-center-common-component/with-async';

const loader = lazyForPaint(() => {
    return import(/* webpackChunkName: "request-create-form" */ /* webpackPrefetch: true */ './hocs');
});

export const RequestCreatePage = withAsync({
    loader,
});
