/**
 * @generated SignedSource<<f1f1e378f484dbc25c9cbaf6996b3df5>>
 * @relayHash b4c5cb2add64b92e5fa0a81f307c32d2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d9f8850f70c76ffcbcce7f2698b51dd8a8d50e7e457be551f94df33714abf2a1

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type HelpObjectStorePortalSearchStrategy = "JIRA" | "SEARCH_PLATFORM" | "%future added value";
export type portalsSearchQuery$variables = {
  cloudId: string;
  helpCenterAri?: string | null | undefined;
  limit: number;
  queryTerm: string;
};
export type portalsSearchQuery$data = {
  readonly helpObjectStore_searchPortals: {
    readonly __typename: "HelpObjectStorePortalSearchResults";
    readonly results: ReadonlyArray<{
      readonly id: string;
      readonly metadata: {
        readonly searchStrategy: HelpObjectStorePortalSearchStrategy;
      };
      readonly " $fragmentSpreads": FragmentRefs<"portalItemFragmentV2">;
    }>;
  } | {
    readonly __typename: "HelpObjectStoreSearchError";
    readonly extensions: ReadonlyArray<{
      readonly errorType: string | null | undefined;
      readonly statusCode: number | null | undefined;
    }>;
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type portalsSearchQuery = {
  response: portalsSearchQuery$data;
  variables: portalsSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryTerm"
},
v4 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "queryTerm",
    "variableName": "queryTerm"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "concreteType": "HelpObjectStorePortalMetadata",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "searchStrategy"
    }
  ]
},
v8 = {
  "kind": "ScalarField",
  "name": "message"
},
v9 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v10 = {
  "kind": "ScalarField",
  "name": "errorType"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "portalsSearchQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "LinkedField",
        "name": "helpObjectStore_searchPortals",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "HelpObjectStorePortalSearchResult",
                "kind": "LinkedField",
                "name": "results",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "portalItemFragmentV2"
                  }
                ]
              }
            ],
            "type": "HelpObjectStorePortalSearchResults"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              }
            ],
            "type": "HelpObjectStoreSearchError"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "portalsSearchQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "LinkedField",
        "name": "helpObjectStore_searchPortals",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "HelpObjectStorePortalSearchResult",
                "kind": "LinkedField",
                "name": "results",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "title"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "displayLink"
                  }
                ]
              }
            ],
            "type": "HelpObjectStorePortalSearchResults"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              }
            ],
            "type": "HelpObjectStoreSearchError"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d9f8850f70c76ffcbcce7f2698b51dd8a8d50e7e457be551f94df33714abf2a1",
    "metadata": {},
    "name": "portalsSearchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5fbada5df4e3a5676426867e2805de0f";

export default node;
