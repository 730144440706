import GoogleDriveIcon from './ui/icons/google-drive-icon';
import SharePointIcon from './ui/icons/sharepoint-icon';

export const getThirdPartyConnectionStatusEndpoint = () =>
    `/gateway/api/third-party-configuration/connected-data-sources`;

export const IntegrationProviderMeta = {
    sharepoint: {
        name: 'SharePoint',
        icon: SharePointIcon,
        integrationId: 'ari:cloud:platform::integration/microsoft',
    },
    googleDrive: {
        name: 'Google Drive',
        icon: GoogleDriveIcon,
        integrationId: 'ari:cloud:platform::integration/google',
    },
} as const;
