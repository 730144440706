import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { sanitize } from 'dompurify';
import { isIsomorphicDompurifyLibUsed } from 'feature-flags';
import { sanitize as isomorphicSanitize } from 'isomorphic-dompurify';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';
import { usePathParam } from 'react-resource-router';
import type { State } from 'state';
import { getThemeColor } from 'state/selectors/help-center';
import { isLoggedIn as isUserLoggedIn } from 'state/selectors/user';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Box, Grid, xcss, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Link } from '@atlassian/help-center-common-component/analytics/link';
import { urlRefsAndTarget } from '@atlassian/help-center-common-util/is-external-resource';
import { ArticleMetadata } from '../article-metadata';
import messages from '../articles/messages';
import { formatText, sendSearchUIAnalyticEvents, useVisibilityState } from '../common';
import { ResourceIcon } from '../resource-icon';
import type { articleItemFragmentV2$key } from './__generated__/articleItemFragmentV2.graphql';

export interface Props {
    isLoggedIn: boolean;
    itemOrder: number;
    term: string;
    result: articleItemFragmentV2$key;
    projectId?: number;
    themeColor: string;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const ArticleItem = ({ itemOrder, result, term, themeColor, createAnalyticsEvent }: Props) => {
    const { formatMessage } = useIntl();
    const data = useFragment<articleItemFragmentV2$key>(
        graphql`
            fragment articleItemFragmentV2 on HelpObjectStoreArticleSearchResult
            @argumentDefinitions(isSourceSystemEnabled: { type: "Boolean!" }) {
                __typename
                ari
                title
                excerpt
                displayLink
                sourceSystem @include(if: $isSourceSystemEnabled)
                metadata {
                    isExternal
                    searchStrategy
                }
                ...articleMetadataFragmentV2
            }
        `,
        result
    );

    const [portalId] = usePathParam('portalId');
    const { title, excerpt, displayLink, metadata, ari, sourceSystem } = data;
    const { isExternal, searchStrategy } = metadata;
    const shortcutIcon = isExternal ? <ShortcutIcon label={formatMessage(messages.shortcutIcon)} size="small" /> : null;
    const highlightedTitle = title ? formatText(title, term) : '';
    const highlightedDescription = excerpt ? formatText(excerpt, term) : '';

    const urlSearchParams = new URLSearchParams();
    sourceSystem && urlSearchParams.set('sourceSystem', sourceSystem);

    const linkProps = urlRefsAndTarget({
        displayLink: isExternal ? displayLink : `${displayLink}?${urlSearchParams.toString()}`,
        isAnExternalResource: isExternal,
    });

    useEffect(() => {
        sendSearchUIAnalyticEvents(
            {
                searchAlgorithm: searchStrategy,
                action: 'list-item-rendered',
                resourceType: 'article',
            },
            createAnalyticsEvent,
            portalId
        );
    }, [createAnalyticsEvent, portalId, searchStrategy]);

    const { isShortcutIconVisible, setIsHovered, setIsFocused } = useVisibilityState();

    return (
        <Box
            key={ari}
            xcss={containerStyles}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocusCapture={() => setIsFocused(true)}
            onBlurCapture={() => setIsFocused(false)}
            testId="search-page-result"
        >
            <Grid
                columnGap="space.150"
                templateAreas={['icon heading header-right', 'icon subheading-left header-right', '. body body']}
                templateColumns="auto 1fr auto"
            >
                <Box xcss={iconAreaStyles}>
                    <ResourceIcon
                        displayLink={displayLink}
                        entityType="ARTICLE"
                        sourceSystem={sourceSystem}
                        isExternal={isExternal}
                    />
                </Box>
                <Box xcss={headingAreaStyles}>
                    <Link
                        key={ari}
                        actionSubjectId={isExternal ? 'external-article-link' : 'article-link'}
                        data-test-id="result-link-container"
                        aria-label={title}
                        attributes={{
                            itemOrder,
                            searchStrategy,
                            sourceSystem,
                        }}
                        {...linkProps}
                    >
                        <Text as="p">
                            <span
                                style={{
                                    color: themeColor,
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: isIsomorphicDompurifyLibUsed()
                                        ? isomorphicSanitize(highlightedTitle)
                                        : sanitize(highlightedTitle),
                                }}
                            />
                        </Text>
                    </Link>
                </Box>
                <Box xcss={[subheadingLeftAreaStyles, subheadingStyles]} testId="search-page-result-metadata">
                    <ArticleMetadata result={data} />
                </Box>
                <Box
                    xcss={isShortcutIconVisible ? headerRightAreaStylesVisible : headerRightAreaStylesInVisible}
                    testId="search-result-shortcut-icon"
                >
                    {shortcutIcon}
                </Box>
                <Box xcss={bodyAreaStyles}>
                    <Text>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: isIsomorphicDompurifyLibUsed()
                                    ? isomorphicSanitize(highlightedDescription)
                                    : sanitize(highlightedDescription),
                            }}
                        />
                    </Text>
                </Box>
            </Grid>
        </Box>
    );
};

const ConnectedArticleItem = connect((state: State) => {
    return {
        isLoggedIn: isUserLoggedIn(state),
        themeColor: getThemeColor(state),
    };
})(ArticleItem);

export default withAnalyticsEvents()(ConnectedArticleItem);

const containerStyles = xcss({
    padding: 'space.100',
    marginLeft: 'space.negative.100',
    marginRight: 'space.negative.100',
    borderRadius: 'border.radius.100',
});
const iconAreaStyles = xcss({
    gridArea: 'icon',
    width: 'size.300',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    textDecoration: 'none',
});
const headingAreaStyles = xcss({
    gridArea: 'heading',
    lineHeight: '20px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    font: token('font.body.large', 'normal'),
});
const subheadingStyles = xcss({
    color: 'color.text.subtlest',
    marginTop: 'space.050',
    fontSize: '12px',
    lineHeight: '16px',
    overflow: 'clip',
    overflowClipMargin: '5px',
});
const subheadingLeftAreaStyles = xcss({
    gridArea: 'subheading-left',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '16px',
});
const headerRightAreaStylesInVisible = xcss({
    gridArea: 'header-right',
    display: 'flex',
    opacity: 0,
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
});
const headerRightAreaStylesVisible = xcss({
    gridArea: 'header-right',
    display: 'flex',
    opacity: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
});
const bodyAreaStyles = xcss({
    gridArea: 'body',
    marginTop: 'space.100',
    overflowWrap: 'break-word',
});
