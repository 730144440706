import { isEditLayoutEnabledForCSM } from 'feature-flags';
import { isNil } from 'lodash';
import type { Category } from 'rest/category';
import type { MediaApiUploadToken } from 'rest/portal';
import type { State } from 'state';
import type { NOT_SUPPORTED } from 'state/actions/kb-category';
import type { AnalyticAttributes, Portal, ReqGroupsState, ReqTypesState } from 'state/persisted/portal';
import type { KnowledgeArticle } from 'state/ui/search';
import { isCSMHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center/advanced-help-center';
import { getEnv } from '@atlassian/help-center-common-util/env';

import type { PersistedError } from 'state/persisted/types';

export const getPortalAnalyticAttributes = (state: State, portalId?: number): AnalyticAttributes | undefined => {
    const portal = getPortal(state, portalId);
    return portal && portal.analyticAttributes;
};

export const getName = (state: State, portalId: number | undefined): string | undefined => {
    const portal = getPortal(state, portalId);
    return portal && portal.name;
};

export const getLogoUrl = (state: State, portalId: number): string | undefined => {
    const portal = getPortal(state, portalId);
    return portal && portal.logoUrl;
};

export const getDescription = (state: State, portalId: number): string | undefined => {
    const portal = getPortal(state, portalId);
    return portal && portal.description;
};

export const getIsPortalLoaded = (state: State, portalId: number): boolean => !!getPortal(state, portalId);

export const getPortalRequestGroups = (state: State, portalId?: number): ReqGroupsState[] => {
    const portal = getPortal(state, portalId);
    return portal ? portal.reqGroups : [];
};

export const getPortalRequestGroupById = (
    state: State,
    portalId: number | undefined,
    requestGroupId: number
): ReqGroupsState | undefined => {
    const portal = getPortal(state, portalId);

    if (!portal) {
        return undefined;
    }

    const requestGroupWithId = portal.reqGroups.find((reqGroup) => reqGroup.id === requestGroupId);

    return requestGroupWithId;
};

export const getPortalRequestTypes = (state: State, portalId?: number): ReqTypesState[] => {
    const portal = getPortal(state, portalId);
    return portal ? portal.reqTypes : [];
};

export const getPortalRequestType = (
    state: State,
    portalId: number | undefined,
    requestTypeId: number | undefined
): ReqTypesState | undefined => {
    if (isNil(portalId) || isNil(requestTypeId)) {
        return undefined;
    }

    const portal = getPortal(state, portalId);
    return portal && portal.reqTypes.find((reqType) => reqType.id === String(requestTypeId));
};

export const getPortalRequestTypesForRequestGroup = (
    state: State,
    portalId: number | undefined,
    requestGroupId: number
): ReqTypesState[] => {
    const allRequestTypesInPortal = getPortalRequestTypes(state, portalId);
    const selectedRequestGroup = getPortalRequestGroupById(state, portalId, requestGroupId);

    if (!selectedRequestGroup) {
        return [];
    }

    const { reqTypes: reqTypesIdsInSelectedGroup } = selectedRequestGroup;

    const requestTypesInGroup = reqTypesIdsInSelectedGroup
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((id) => allRequestTypesInPortal.find((rt) => rt.id === id)!)
        .filter(Boolean);

    return requestTypesInGroup;
};

export const getRequestGroupsForRequestType = ({
    state,
    portalId,
    requestTypeId,
}: {
    state: State;
    portalId: number | undefined;
    requestTypeId: number;
}): ReqGroupsState[] => {
    const requestGroups = getPortalRequestGroups(state, portalId);
    return requestGroups.filter((group) => group.reqTypes.includes(String(requestTypeId)));
};

export const getIsRequestTypeBelongsToGroup = (
    state: State,
    portalId: number,
    requestGroupId: number,
    requestTypeId: number
): boolean => {
    const requestGroups = getRequestGroupsForRequestType({ state, portalId, requestTypeId });
    return (requestGroups || []).filter((requestGroup) => requestGroup.id === requestGroupId).length > 0;
};

export const getPortalRequestTypesNotInRequestGroup = (
    state: State,
    portalId: number | undefined,
    requestGroupId: number
): ReqTypesState[] => {
    const allRequestTypesInPortal = getPortalRequestTypes(state, portalId);

    const selectedRequestGroup = getPortalRequestGroupById(state, portalId, requestGroupId);

    if (!selectedRequestGroup) {
        return allRequestTypesInPortal;
    }

    const { reqTypes: reqTypesIdsInSelectedGroup } = selectedRequestGroup;

    const requestTypesNotInSelectedGroup = allRequestTypesInPortal.filter(
        ({ id: idInPortal }) =>
            // only include request types that are not in the specified group
            !reqTypesIdsInSelectedGroup.some((idInGroup) => idInGroup === idInPortal)
    );

    return requestTypesNotInSelectedGroup;
};

/**
 * @deprecated we don't want to use the project key anymore.
 */
export const getProjectKey = (state: State, portalId?: number) => {
    const portal = getPortal(state, portalId);
    return !!portal && portal.projectKey && portal.projectKey.toUpperCase();
};

export const getProjectId = (state: State, portalId: number | undefined): number | undefined => {
    if (isNil(portalId)) {
        return undefined;
    }
    const portal = getPortal(state, portalId);
    return portal ? portal.projectId : undefined;
};

export const getPortalOrError = (state: State, portalId?: number): Portal | PersistedError | undefined => {
    if (isNil(portalId)) {
        return undefined;
    }

    return state.persisted.portals[portalId];
};

export const getPortal = (state: State, portalId?: number): Portal | undefined => {
    const portal = getPortalOrError(state, portalId);
    if (!portal || 'error' in portal) {
        return undefined;
    }

    return portal;
};

export const getIsKbEnabled: (state: State, portalId: number | undefined) => boolean = (state, portalId) => {
    const portal = getPortal(state, portalId);
    return !!portal && !!portal.knowledgeBase;
};

/**
 * Checks if the knowledge base instance is a confluence server
 * @param state
 * @param portalId
 */
export const getIsKbServer: (state: State, portalId: number | undefined) => boolean = (state, portalId) => {
    const portal = getPortal(state, portalId);
    return Boolean(portal?.knowledgeBase?.kbLink?.isServer);
};

export const getKbLinkDomainURLs: (state: State, portalId: number | undefined) => string[] = (state, portalId) => {
    const portal = getPortal(state, portalId);
    return portal?.knowledgeBase?.kbLinkDomainURLs || [];
};

export const getPortalAnnouncement = (state: State, portalId: number | undefined) => {
    const portal = getPortal(state, portalId);
    return portal ? portal.portalAnnouncement : undefined;
};

export const getCanEditAnnoucement = (state: State, portalId?: number) => {
    const portalAnnouncement = getPortalAnnouncement(state, portalId);
    return !!portalAnnouncement && portalAnnouncement.canEditAnnouncement;
};

export const getCanAdministerProject = (state: State, portalId?: number) => {
    const portalAnnouncement = getPortalAnnouncement(state, portalId);
    return portalAnnouncement && portalAnnouncement.canAdministerProject;
};

export const getIsProjectSimplified = (state: State, portalId: number | undefined) => {
    const portal = getPortal(state, portalId);
    return portal ? portal.isProjectSimplified : undefined;
};

/**
 * Returns a list of categories or undefined if categories is not loaded
 */
export const getCategories: (state: State, portalId?: number) => Category[] | undefined | typeof NOT_SUPPORTED = (
    state,
    portalId
) => {
    const portal = getPortal(state, portalId);

    if (portal && portal.knowledgeBase && portal.knowledgeBase.kbCategories) {
        const categoryOrError = portal.knowledgeBase.kbCategories;
        return 'error' in categoryOrError ? [] : categoryOrError;
    }

    return undefined;
};

export const getCategory: (
    state: State,
    portalId?: number,
    categoryId?: string
) => Category | PersistedError | undefined | typeof NOT_SUPPORTED = (state, portalId, categoryId) => {
    if (!categoryId || !portalId) {
        return undefined;
    }

    const portal = getPortal(state, portalId);

    if (portal && portal.knowledgeBase && portal.knowledgeBase.kbCategories) {
        const categoryOrError = portal.knowledgeBase.kbCategories;

        if (Array.isArray(categoryOrError)) {
            return categoryOrError.find((category) => category.id === categoryId);
        }
        return categoryOrError;
    }

    return undefined;
};

export const getPortalLink: (state: State, portalId?: number) => string = (state, portalId) => {
    const requestGroups = getPortalRequestGroups(state, portalId);
    // If the user has exactly 1 request group and request type, we link them directly to that form.
    // Otherwise we direct them to the request groups
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    let portalLink = `/portal/${portalId}/group/-1`;

    if (requestGroups.length === 1) {
        const [requestGroup] = requestGroups;

        if (requestGroup.reqTypes.length === 1) {
            const [requestTypeId] = requestGroup.reqTypes;
            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            portalLink = `/portal/${portalId}/group/${requestGroup.id}/create/${requestTypeId}`;
        }
    }

    return portalLink;
};

export const canCreateRequest: (state: State, portalId?: number) => boolean = (state, portalId) => {
    if (isCSMHelpCenter(getEnv().helpCenterType) && isEditLayoutEnabledForCSM()) {
        return false;
    }
    return Object.keys(getPortalRequestGroups(state, Number(portalId))).length > 0;
};

export const getPortalAnnouncementPersisted = (state: State, payload: Partial<{ portalId: number }>) => {
    const portalAnnouncement = getPortalAnnouncement(state, payload?.portalId);

    return portalAnnouncement ? portalAnnouncement : undefined;
};

export const getPortalAnnouncementsUI = (state: State) => state.ui.portals.portalAnnouncementsResponse;

export const isSaveInProgress = (state: State) => state.ui.portals.isSaveInProgress;

export const isSaveFailed = (state: State) => state.ui.portals.isSaveFailed;

export const getServiceDeskId = (state: State, portalId?: number): undefined | number => {
    const portal = getPortal(state, portalId);
    return portal?.serviceDeskId;
};

export const getKbSuggestions = (state: State, portalId?: number): KnowledgeArticle[] => {
    const portal = getPortal(state, portalId);
    const kbSuggestions = portal && !('error' in portal) && portal.knowledgeBase?.kbArticleSuggestions;
    if (kbSuggestions === false || kbSuggestions === undefined) {
        return [];
    }
    return kbSuggestions;
};

export const getMediaApiUploadToken = (state: State, portalId?: number): MediaApiUploadToken | undefined => {
    const portal = getPortal(state, portalId);
    return portal?.mediaApiUploadToken;
};

export const getMediaApiError = (state: State, portalId?: number): string | undefined => {
    const portal = getPortal(state, portalId);
    return portal?.mediaApiError;
};
