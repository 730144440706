/**
 * @generated SignedSource<<7ff7a2c74acd6ea83f59e341b733ebff>>
 * @relayHash 56de91789824be9bc7b27c98b3f4b318
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ba9814da3207200793998fa5eeecaa0dfb1069f20ebd284d60fa78420caefa7e

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type editCustomPageQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
  helpCenterPageAri: string;
};
export type editCustomPageQuery$data = {
  readonly helpCenter: {
    readonly mediaConfig: {
      readonly " $fragmentSpreads": FragmentRefs<"globalBrandingSettingsPanelMediaConfigFragment">;
    };
  } | null | undefined;
  readonly helpLayout: {
    readonly elementTypes: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"elementsCatalogueFragment">;
    }> | null | undefined;
    readonly layoutByParentId: {
      readonly __typename: "QueryError";
      readonly id?: string;
      readonly " $fragmentSpreads": FragmentRefs<"handleQueryErrorFragment" | "pageBuilderFragment">;
    };
    readonly mediaConfig: {
      readonly " $fragmentSpreads": FragmentRefs<"pageBuilderMediaConfigFragment">;
    };
  };
};
export type editCustomPageQuery = {
  response: editCustomPageQuery$data;
  variables: editCustomPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterPageAri"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v4 = {
  "kind": "Variable",
  "name": "helpCenterAri",
  "variableName": "helpCenterAri"
},
v5 = [
  (v4/*: any*/)
],
v6 = [
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "parentAri",
    "variableName": "helpCenterPageAri"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = [
  {
    "kind": "Variable",
    "name": "parentAri",
    "variableName": "helpCenterAri"
  }
],
v10 = {
  "kind": "ScalarField",
  "name": "mediaUrl"
},
v11 = {
  "kind": "ScalarField",
  "name": "mediaToken"
},
v12 = {
  "kind": "ScalarField",
  "name": "mediaCollectionName"
},
v13 = {
  "kind": "ScalarField",
  "name": "asapIssuer"
},
v14 = [
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/)
],
v15 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v16 = {
  "kind": "ScalarField",
  "name": "key"
},
v17 = {
  "kind": "ScalarField",
  "name": "horizontalAlignment"
},
v18 = {
  "kind": "ScalarField",
  "name": "verticalAlignment"
},
v19 = {
  "kind": "ScalarField",
  "name": "__id"
},
v20 = {
  "kind": "ClientExtension",
  "selections": [
    (v19/*: any*/)
  ]
},
v21 = {
  "kind": "ScalarField",
  "name": "fileId"
},
v22 = {
  "kind": "ScalarField",
  "name": "url"
},
v23 = {
  "concreteType": "HelpLayoutBackgroundImage",
  "kind": "LinkedField",
  "name": "backgroundImage",
  "plural": false,
  "selections": [
    (v21/*: any*/),
    (v22/*: any*/)
  ]
},
v24 = {
  "kind": "ScalarField",
  "name": "backgroundType"
},
v25 = {
  "kind": "ScalarField",
  "name": "backgroundColor"
},
v26 = {
  "kind": "ScalarField",
  "name": "foregroundColor"
},
v27 = {
  "kind": "ScalarField",
  "name": "hidden"
},
v28 = {
  "kind": "ScalarField",
  "name": "titleColor"
},
v29 = {
  "kind": "ScalarField",
  "name": "name"
},
v30 = {
  "kind": "ScalarField",
  "name": "logo"
},
v31 = {
  "kind": "ScalarField",
  "name": "portalId"
},
v32 = {
  "concreteType": "HelpLayoutAlignmentSettings",
  "kind": "LinkedField",
  "name": "alignment",
  "plural": false,
  "selections": [
    (v17/*: any*/),
    (v18/*: any*/)
  ]
},
v33 = {
  "concreteType": "HelpLayoutVisualConfig",
  "kind": "LinkedField",
  "name": "visualConfig",
  "plural": false,
  "selections": [
    (v28/*: any*/),
    (v32/*: any*/),
    (v23/*: any*/),
    (v24/*: any*/),
    (v25/*: any*/),
    (v26/*: any*/),
    (v27/*: any*/)
  ]
},
v34 = {
  "concreteType": "HelpLayoutAtomicElementType",
  "kind": "LinkedField",
  "name": "elementType",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v16/*: any*/)
  ]
},
v35 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isHelpLayoutVisualEntity"
},
v36 = {
  "kind": "ScalarField",
  "name": "message"
},
v37 = {
  "concreteType": "HelpLayoutBackgroundImage",
  "kind": "LinkedField",
  "name": "backgroundImage",
  "plural": false,
  "selections": [
    (v22/*: any*/),
    (v21/*: any*/)
  ]
},
v38 = {
  "concreteType": "HelpLayoutVisualConfig",
  "kind": "LinkedField",
  "name": "visualConfig",
  "plural": false,
  "selections": [
    (v32/*: any*/),
    (v23/*: any*/),
    (v24/*: any*/),
    (v25/*: any*/),
    (v26/*: any*/),
    (v27/*: any*/),
    (v28/*: any*/)
  ]
},
v39 = {
  "kind": "ScalarField",
  "name": "isInstalled"
},
v40 = {
  "kind": "LinkedField",
  "name": "extensions",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "kind": "ScalarField",
      "name": "statusCode"
    }
  ]
},
v41 = {
  "kind": "InlineFragment",
  "selections": [
    (v7/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "editCustomPageQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "args": (v5/*: any*/),
              "concreteType": "HelpCenterMediaConfig",
              "kind": "LinkedField",
              "name": "mediaConfig",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "globalBrandingSettingsPanelMediaConfigFragment"
                }
              ]
            },
            "action": "THROW",
            "path": "helpCenter.mediaConfig"
          }
        ]
      },
      {
        "kind": "RequiredField",
        "field": {
          "args": (v3/*: any*/),
          "concreteType": "HelpLayoutQueryApi",
          "kind": "LinkedField",
          "name": "helpLayout",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v6/*: any*/),
                "kind": "LinkedField",
                "name": "layoutByParentId",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "pageBuilderFragment"
                      }
                    ],
                    "type": "HelpLayout"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "handleQueryErrorFragment"
                      }
                    ],
                    "type": "QueryError"
                  }
                ]
              },
              "action": "THROW",
              "path": "helpLayout.layoutByParentId"
            },
            {
              "kind": "RequiredField",
              "field": {
                "args": (v9/*: any*/),
                "concreteType": "HelpLayoutMediaConfig",
                "kind": "LinkedField",
                "name": "mediaConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "pageBuilderMediaConfigFragment"
                  }
                ]
              },
              "action": "THROW",
              "path": "helpLayout.mediaConfig"
            },
            {
              "kind": "LinkedField",
              "name": "elementTypes",
              "plural": true,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "elementsCatalogueFragment"
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "helpLayout"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "editCustomPageQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "HelpCenterMediaConfig",
            "kind": "LinkedField",
            "name": "mediaConfig",
            "plural": false,
            "selections": (v14/*: any*/)
          }
        ]
      },
      {
        "args": (v3/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "kind": "LinkedField",
            "name": "layoutByParentId",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/),
                  {
                    "concreteType": "HelpLayoutSectionConnection",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "HelpLayoutSectionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "HelpLayoutSection",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "HelpLayoutSubsection",
                                "kind": "LinkedField",
                                "name": "subsections",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "HelpLayoutSubsectionConfig",
                                    "kind": "LinkedField",
                                    "name": "config",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "span"
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "name": "elements",
                                    "plural": true,
                                    "selections": [
                                      (v8/*: any*/),
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isHelpLayoutElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "adf"
                                          },
                                          {
                                            "concreteType": "HelpLayoutAtomicElementType",
                                            "kind": "LinkedField",
                                            "name": "elementType",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "args": (v9/*: any*/),
                                                "concreteType": "HelpLayoutMediaConfig",
                                                "kind": "LinkedField",
                                                "name": "mediaConfig",
                                                "plural": false,
                                                "selections": [
                                                  (v12/*: any*/),
                                                  (v11/*: any*/),
                                                  (v10/*: any*/),
                                                  (v13/*: any*/)
                                                ]
                                              },
                                              (v15/*: any*/),
                                              (v16/*: any*/)
                                            ]
                                          },
                                          {
                                            "concreteType": "HelpLayoutVisualConfig",
                                            "kind": "LinkedField",
                                            "name": "visualConfig",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "HelpLayoutAlignmentSettings",
                                                "kind": "LinkedField",
                                                "name": "alignment",
                                                "plural": false,
                                                "selections": [
                                                  (v17/*: any*/),
                                                  (v18/*: any*/),
                                                  (v20/*: any*/)
                                                ]
                                              },
                                              (v23/*: any*/),
                                              (v24/*: any*/),
                                              (v25/*: any*/),
                                              (v26/*: any*/),
                                              (v27/*: any*/),
                                              (v28/*: any*/)
                                            ]
                                          },
                                          (v20/*: any*/)
                                        ],
                                        "type": "HelpLayoutEditorElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutPortalCard",
                                            "kind": "LinkedField",
                                            "name": "portals",
                                            "plural": true,
                                            "selections": [
                                              (v29/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "description"
                                              },
                                              (v30/*: any*/),
                                              (v31/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "isFeatured"
                                              },
                                              (v8/*: any*/)
                                            ]
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "expandButtonTextColor"
                                          },
                                          (v33/*: any*/),
                                          (v7/*: any*/),
                                          (v34/*: any*/),
                                          (v35/*: any*/)
                                        ],
                                        "type": "HelpLayoutPortalsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutRequestForm",
                                            "kind": "LinkedField",
                                            "name": "suggestedRequestTypes",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "descriptionHtml"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "portalName"
                                              },
                                              (v7/*: any*/),
                                              (v31/*: any*/),
                                              (v29/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "iconUrl"
                                              },
                                              (v8/*: any*/)
                                            ]
                                          },
                                          (v33/*: any*/),
                                          (v7/*: any*/),
                                          (v34/*: any*/),
                                          (v35/*: any*/)
                                        ],
                                        "type": "HelpLayoutSuggestedRequestFormsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "header"
                                          },
                                          (v36/*: any*/),
                                          (v34/*: any*/)
                                        ],
                                        "type": "HelpLayoutAnnouncementElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutTopic",
                                            "kind": "LinkedField",
                                            "name": "topics",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "topicId"
                                              },
                                              (v27/*: any*/),
                                              {
                                                "concreteType": "HelpLayoutTopicItem",
                                                "kind": "LinkedField",
                                                "name": "items",
                                                "plural": true,
                                                "selections": [
                                                  (v8/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "title"
                                                  },
                                                  (v30/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "displayLink"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "entityKey"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "helpObjectType"
                                                  },
                                                  (v20/*: any*/)
                                                ]
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "topicName"
                                              }
                                            ]
                                          },
                                          (v33/*: any*/),
                                          (v7/*: any*/),
                                          (v34/*: any*/),
                                          (v35/*: any*/)
                                        ],
                                        "type": "HelpLayoutTopicsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "useGlobalSettings"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "homePageTitle"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "userLanguageTag"
                                          },
                                          {
                                            "concreteType": "HelpLayoutVisualConfig",
                                            "kind": "LinkedField",
                                            "name": "visualConfig",
                                            "plural": false,
                                            "selections": [
                                              (v26/*: any*/),
                                              (v25/*: any*/),
                                              (v24/*: any*/),
                                              (v37/*: any*/),
                                              (v32/*: any*/),
                                              (v27/*: any*/),
                                              (v28/*: any*/)
                                            ]
                                          },
                                          (v7/*: any*/),
                                          (v34/*: any*/)
                                        ],
                                        "type": "HelpLayoutHeroElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutBreadcrumb",
                                            "kind": "LinkedField",
                                            "name": "items",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "relativeUrl"
                                              },
                                              (v29/*: any*/)
                                            ]
                                          },
                                          (v34/*: any*/),
                                          (v38/*: any*/)
                                        ],
                                        "type": "HelpLayoutBreadcrumbElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "forgeElementType"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "forgeElementPage"
                                          },
                                          (v34/*: any*/),
                                          (v39/*: any*/),
                                          (v38/*: any*/)
                                        ],
                                        "type": "HelpLayoutForgeElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "connectElementPage"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "connectElementType"
                                          },
                                          (v34/*: any*/),
                                          (v39/*: any*/),
                                          (v38/*: any*/)
                                        ],
                                        "type": "HelpLayoutConnectElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "identifier"
                                          },
                                          (v40/*: any*/)
                                        ],
                                        "type": "QueryError"
                                      },
                                      (v20/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutAtomicElementType",
                                            "kind": "LinkedField",
                                            "name": "elementType",
                                            "plural": false,
                                            "selections": [
                                              (v16/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "HelpLayoutNoContentElement"
                                      },
                                      (v41/*: any*/)
                                    ]
                                  },
                                  (v7/*: any*/),
                                  (v38/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "HelpLayoutVisualConfig",
                                        "kind": "LinkedField",
                                        "name": "visualConfig",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutAlignmentSettings",
                                            "kind": "LinkedField",
                                            "name": "alignment",
                                            "plural": false,
                                            "selections": [
                                              (v20/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "HelpLayoutVisualEntity",
                                    "abstractKey": "__isHelpLayoutVisualEntity"
                                  },
                                  (v20/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "HelpLayoutVisualConfig",
                                "kind": "LinkedField",
                                "name": "visualConfig",
                                "plural": false,
                                "selections": [
                                  (v25/*: any*/),
                                  (v24/*: any*/),
                                  (v37/*: any*/),
                                  (v27/*: any*/),
                                  (v32/*: any*/),
                                  (v26/*: any*/),
                                  (v28/*: any*/)
                                ]
                              },
                              (v7/*: any*/),
                              {
                                "kind": "ClientExtension",
                                "selections": [
                                  (v19/*: any*/),
                                  {
                                    "alias": "dataId",
                                    "kind": "ScalarField",
                                    "name": "__id"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "alias": "layoutId",
                    "kind": "ScalarField",
                    "name": "id"
                  },
                  (v20/*: any*/)
                ],
                "type": "HelpLayout"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v36/*: any*/),
                  (v40/*: any*/)
                ],
                "type": "QueryError"
              },
              (v41/*: any*/)
            ]
          },
          {
            "args": (v9/*: any*/),
            "concreteType": "HelpLayoutMediaConfig",
            "kind": "LinkedField",
            "name": "mediaConfig",
            "plural": false,
            "selections": (v14/*: any*/)
          },
          {
            "kind": "LinkedField",
            "name": "elementTypes",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isHelpLayoutElementType"
              },
              (v15/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "atomicKey",
                    "kind": "ScalarField",
                    "name": "key"
                  },
                  {
                    "args": (v9/*: any*/),
                    "concreteType": "HelpLayoutMediaConfig",
                    "kind": "LinkedField",
                    "name": "mediaConfig",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v12/*: any*/),
                      (v11/*: any*/),
                      (v10/*: any*/),
                      (v13/*: any*/)
                    ]
                  }
                ],
                "type": "HelpLayoutAtomicElementType"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "compositeKey",
                    "kind": "ScalarField",
                    "name": "key"
                  }
                ],
                "type": "HelpLayoutCompositeElementType"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ba9814da3207200793998fa5eeecaa0dfb1069f20ebd284d60fa78420caefa7e",
    "metadata": {},
    "name": "editCustomPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9ca73b9faca34ec0f24ad41263235fbb";

export default node;
