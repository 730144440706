import React from 'react';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';

interface Props {
    isExpanded: boolean;
    label: string;
    testId?: string;
    onClick: () => void;
}

export const ExpandCollapseToggle = (props: Props) => {
    return (
        <Tooltip content={props.label}>
            <Button
                appearance="subtle"
                onClick={props.onClick}
                iconBefore={
                    <Box xcss={[animatedRotationStyles, props.isExpanded && animatedRotationStylesInverted]}>
                        <ChevronDownIcon spacing="spacious" label={props.label} />
                    </Box>
                }
                testId={props.testId}
            />
        </Tooltip>
    );
};

const animatedRotationStyles = xcss({
    transition: '200ms ease all',
    transform: 'rotate(0deg)',
});

const animatedRotationStylesInverted = xcss({
    transform: 'rotate(180deg)',
});
