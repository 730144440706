import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import type { ThemedProps } from 'styled-components';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import { withTheme } from 'styled-components';
import type { SelectProps, Option } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
    Select,
    CreatableSelect,
    AsyncSelect,
    AsyncCreatableSelect,
} from '@atlassian/help-center-common-component/analytics/select';

function withThemeSelect<U extends Option<string>, T extends SelectProps<U, boolean> = SelectProps<U, boolean>>(
    Component: React.ComponentType<T>
) {
    class ThemedSelectWrapper extends React.PureComponent<ThemedProps<T>> {
        render() {
            const { theme, styles: externalStyles = {}, ...selectProps } = this.props;
            const customStyles = {
                // This is to ensure the multi select options stay within the container without overflowing
                valueContainer: (styles: object) => ({
                    ...styles,
                    width: '100%',
                    overflow: 'hidden',
                }),
                // This is used to apply our theming
                option: (styles: object, { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean }) => {
                    const optionFocusBackground = isFocused
                        ? token('color.background.neutral.subtle.hovered', colors.N20)
                        : null;
                    const backgroundColor = isSelected ? theme.lightColor : optionFocusBackground;

                    return {
                        ...styles,
                        backgroundColor,
                        ':active': {
                            backgroundColor: theme.lightColor,
                        },
                        color: token('color.text', colors.N800),
                    };
                },
                // This is to ensure long select options doesn't cause a horizontal scroll bar to appear in the select menu
                menu: (styles: object) => ({
                    ...styles,
                    'word-wrap': 'break-word',
                    'overflow-y': 'hidden',
                    backgroundColor: token('elevation.surface.overlay', colors.N0),
                }),
            };

            return (
                <Component
                    placeholder=""
                    // eslint-disable-next-line @atlassian/ssr-linting/no-dom-globals-in-react-cc-render
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    {...(selectProps as any)}
                    styles={{ ...externalStyles, ...customStyles }}
                />
            );
        }
    }

    return withTheme(ThemedSelectWrapper);
}

export const ThemedAsyncCreatableSelect = withThemeSelect(AsyncCreatableSelect);
export const ThemedCreatableSelect = withThemeSelect(CreatableSelect);
export const ThemedAsyncSelect = withThemeSelect(AsyncSelect);
export const ThemedSelect = withThemeSelect(Select);
