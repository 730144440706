import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import { isUseReact18CreateRootEnabled } from 'feature-flags';
import { createRoot } from 'react-dom/client';
import { AnalyticsListener } from '@atlaskit/analytics-next';
import { AnalyticsContext, ScreenTypes } from '@atlassian/analytics-web-react';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { getMessages } from '@atlassian/help-center-common-util/i18n-messages';
import { getLanguageFromLocale } from '@atlassian/help-center-common-util/locale-data';
import { getMetaData } from '@atlassian/help-center-common-util/meta';
import * as Sentry from '@atlassian/help-center-common-util/sentry';
import type { CmdbObject } from '../../../rest/cmdb-object-picker';
import { CmdbObjectCard } from '../../common/cmdb-object-card';

const onIntlError = (intlError: string): void => {
    try {
        Sentry.reportError(intlError);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Failed to report intl error: ${intlError}`, err);
    }
};

export const decodeBase64ToObject = (base64String: string | null): CmdbObject | null => {
    try {
        // Decode base64 string while preserving Unicode characters
        const binaryString = atob(base64String || '');
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const decoder = new TextDecoder('utf-8');
        const decodedString = decoder.decode(bytes);
        return JSON.parse(decodedString) as CmdbObject;
    } catch (e) {
        return null;
    }
};

export const getCmdbFieldValueElement = () => {
    class CmdbFieldValueElement extends HTMLElement {
        connectedCallback() {
            // Jira passes the cmdb object data as a data-object-details-base64 attribute
            const objectDetailsBase64 = this.getAttribute('data-object-details-base64');
            const parsedObjectDetails = decodeBase64ToObject(objectDetailsBase64);

            if (!parsedObjectDetails) {
                return;
            }

            if (isUseReact18CreateRootEnabled()) {
                const container = document.createElement('span');
                const root = createRoot(container);
                root.render(
                    <IntlProvider
                        messages={getMessages()}
                        locale={getLanguageFromLocale(getMetaData().userLocale)}
                        onError={onIntlError}
                    >
                        <AnalyticsListener onEvent={sendEvent}>
                            <AnalyticsContext
                                sourceName="portalUnknown"
                                sourceType={ScreenTypes.SCREEN}
                                attributes={{ context: 'Portal' }}
                            >
                                <ErrorBoundary
                                    packageName="cmdbFieldValueWebComponent"
                                    id="cmdb-object-card"
                                    renderError={() => null}
                                >
                                    <CmdbObjectCard cmdbObject={parsedObjectDetails} />
                                </ErrorBoundary>
                            </AnalyticsContext>
                        </AnalyticsListener>
                    </IntlProvider>
                );
                this.appendChild(container);
            } else {
                // render the component with its props, and add to the Shadow DOM
                const root = document.createElement('span');
                // eslint-disable-next-line react/no-deprecated
                ReactDOM.render(
                    <IntlProvider
                        messages={getMessages()}
                        locale={getLanguageFromLocale(getMetaData().userLocale)}
                        onError={onIntlError}
                    >
                        <AnalyticsListener onEvent={sendEvent}>
                            <AnalyticsContext
                                sourceName="portalUnknown"
                                sourceType={ScreenTypes.SCREEN}
                                attributes={{ context: 'Portal' }}
                            >
                                <ErrorBoundary
                                    packageName="cmdbFieldValueWebComponent"
                                    id="cmdb-object-card"
                                    renderError={() => null}
                                >
                                    <CmdbObjectCard cmdbObject={parsedObjectDetails} />
                                </ErrorBoundary>
                            </AnalyticsContext>
                        </AnalyticsListener>
                    </IntlProvider>,
                    root
                );
                this.appendChild(root);
            }
        }
    }
    return CmdbFieldValueElement;
};
