import { HelpPageAri } from '@atlassian/ari';
import { getActivationId } from '@atlassian/help-center-common-util/meta/meta';
import { createRelayResource } from '@atlassian/help-center-common-util/resource-router/resource-router';
import type { viewCustomPageQuery } from '@atlassian/help-center-custom-pages/src/ui/view-custom-page/__generated__/viewCustomPageQuery.graphql';
import ViewCustomPageQuery from '@atlassian/help-center-custom-pages/src/ui/view-custom-page/__generated__/viewCustomPageQuery.graphql';

export const ViewCustomPageResource = createRelayResource<viewCustomPageQuery>({
    type: 'VIEW_CUSTOM_PAGE',
    getQuery: ({ match }, { cloudId, helpCenterAri }) => {
        const pageId = match.params.id || '';
        const helpCenterPageAri: string = HelpPageAri.create({
            pageId,
            activationId: getActivationId(),
        }).toString();
        return {
            parameters: ViewCustomPageQuery,
            variables: {
                cloudId,
                helpCenterAri,
                helpCenterPageAri,
                filter: { isEditMode: false },
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
