import { lazyForPaint } from 'react-loosely-lazy';
import { withAsync } from '@atlassian/help-center-common-component/with-async';
export type { FormErrors, FormInput } from './types';

const loader = lazyForPaint(() => {
    return import(/* webpackChunkName: "request-create-form" */ /* webpackPrefetch: true */ './hocs');
});
export const RequestCreateForm = withAsync({
    loader,
});
