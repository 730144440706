import React from 'react';
import emptyConfigPanel from '@helpCenter/assets/images/config-panel-default.svg';
import { useIntl } from 'react-intl-next';
import Heading from '@atlaskit/heading';
import { Box, Text, xcss } from '@atlaskit/primitives';
import messages from './messages';

export const ConfigPanelEmptyState = () => {
    const { formatMessage } = useIntl();
    return (
        <>
            <Heading size="medium" as="h3">
                {formatMessage(messages.emptyConfigPanelHeading)}
            </Heading>
            <Box xcss={emptyConfigPanelStyles}>
                <img src={emptyConfigPanel} alt={formatMessage(messages.emptyConfigPanelImageAltText)} />
                <Text align="center" as="p">
                    {formatMessage(messages.emptyConfigPanelText, {
                        b: (chunks: React.ReactNode) => <b>{chunks}</b>,
                    })}
                </Text>
            </Box>
        </>
    );
};

const emptyConfigPanelStyles = xcss({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 'space.400',
    gap: 'space.200',
});
