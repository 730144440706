import { memo, useEffect } from 'react';
import { isAiWidgetTeam25Enabled } from 'feature-flags';
import { di } from 'react-magnetic-di';
import { getEnv, EnvType } from '@atlassian/help-center-common-util/env';
import { getMeta } from '@atlassian/help-center-common-util/meta';

export const CsmChatWidget = memo(function CsmChatWidget() {
    di(getEnv, getMeta, isAiWidgetTeam25Enabled);

    useEffect(() => {
        if (!isAiWidgetTeam25Enabled()) {
            return undefined;
        }

        const src = new URL(
            getEnv().environment === EnvType.PROD
                ? 'https://jcs-chat-widget.atlassian.com/assets/script.js'
                : 'https://jcs-chat-widget.stg.atlassian.com/assets/script.js'
        );
        src.searchParams.set('siteId', getMeta('tenant-id') || '');
        src.searchParams.set('site', window.location.hostname);

        const script = document.createElement('script');
        script.async = true;
        script.src = src.toString();
        script.setAttribute('data-testid', 'csm-chat-widget-script');

        const body = document.querySelector('body');
        body?.appendChild(script);

        return () => {
            body?.removeChild(script);
        };
    }, []);

    return null;
});
