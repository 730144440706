import React, { memo } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import styled from 'styled-components';
import { getPortalId } from '@helpCenter/state/selectors/context';
import { isIncreasedFlexibilityEnabled } from 'feature-flags';
import { di } from 'react-magnetic-di';
import { useRouter } from 'react-resource-router';
import type { PortalAnnouncement } from 'rest/portal';
import type { State } from 'state';
import { getHelpCenterTopBarSplitFlag } from 'state/selectors/help-center';
import { getPortalAnnouncement } from 'state/selectors/portal';
import { AnnouncementBanner } from 'view/common/announcement-banner';
import { PaperContent } from 'view/layout/paper';
import { Box, xcss } from '@atlaskit/primitives';
import { GridContentWrapper } from '../grid-container/grid-container';
import type { GridAppearanceTypes } from '../grid-container/utils';

export interface StateProps {
    announcement: PortalAnnouncement;
    appearance?: GridAppearanceTypes;
    hasTopBarBeenSplit?: boolean;
}

const Announcement: React.FC<StateProps> = ({ announcement, hasTopBarBeenSplit, appearance: contentAppearance }) => {
    di(useRouter);
    const [{ route }] = useRouter();
    const { name } = route;
    const whiteListedRouteNames = ['portal-home', 'request-create'];

    const AnnouncementInnerContainer = memo(({ children }: { children: JSX.Element }) => {
        const hide = contentAppearance === 'none';
        const appearance = contentAppearance || 'normal';
        return (
            <GridContentWrapper appearance={appearance} hide={hide}>
                <PaperInnerContainer data-testid="paper-inner-container">{children}</PaperInnerContainer>
            </GridContentWrapper>
        );
    });

    AnnouncementInnerContainer.displayName = 'AnnouncementInnerContainer';

    if (isIncreasedFlexibilityEnabled() && hasTopBarBeenSplit) {
        return (
            <>
                {!!announcement && whiteListedRouteNames.includes(name) && (
                    <Box xcss={announcementContainer} testId={'increased-flexibility-container'}>
                        <AnnouncementBanner
                            actionSubjectId="portalAnnouncementBanner"
                            header={announcement.userLanguageHeader}
                            messageHtml={announcement.userLanguageMessageWiki}
                            InnerContainerComponent={AnnouncementInnerContainer}
                        />
                    </Box>
                )}
            </>
        );
    }

    return (
        <>
            {!!announcement && whiteListedRouteNames.includes(name) && (
                <AnnouncementBanner
                    actionSubjectId="portalAnnouncementBanner"
                    header={announcement.userLanguageHeader}
                    messageHtml={announcement.userLanguageMessageWiki}
                    InnerContainerComponent={AnnouncementInnerContainer}
                />
            )}
        </>
    );
};

const selector = createSelector(
    (state: State) => {
        const portalId = getPortalId(state);

        return {
            announcement: getPortalAnnouncement(state, portalId),
        };
    },
    getHelpCenterTopBarSplitFlag,
    ({ announcement }, hasTopBarBeenSplit) => {
        return {
            announcement,
            hasTopBarBeenSplit,
        };
    }
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const PaperInnerContainer = styled(PaperContent)({
    paddingTop: 0,
});

const announcementContainer = xcss({
    marginTop: 'space.100',
    marginBottom: 'space.100',
    marginLeft: 'space.400',
    marginRight: 'space.400',
    borderRadius: 'border.radius.200',
    overflow: 'hidden',
    // make sure extra margin is not added in case announcement is not present
    ':empty': {
        display: 'none',
    },
});

export default connect(selector)(Announcement);
