import { HelpPageAri } from '@atlassian/ari';
import { getActivationId } from '@atlassian/help-center-common-util/meta/meta';
import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';
import EditCustomPageQuery from '@atlassian/help-center-custom-pages/src/ui/edit-custom-page/__generated__/editCustomPageQuery.graphql';
import type { editCustomPageQuery } from '@atlassian/help-center-custom-pages/src/ui/edit-custom-page/__generated__/editCustomPageQuery.graphql';

export const EditCustomPageResource = createRelayResource<editCustomPageQuery>({
    type: 'EDIT_CUSTOM_PAGE',
    getQuery: ({ match }, { cloudId, helpCenterAri }) => {
        const pageId = match.params.id;
        if (!pageId) {
            throw Error('No page id for custom page.');
        }
        const helpCenterPageAri: string = HelpPageAri.create({
            pageId,
            activationId: getActivationId(),
        }).toString();
        return {
            parameters: EditCustomPageQuery,
            variables: {
                cloudId,
                helpCenterAri,
                helpCenterPageAri,
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
