/**
 * @generated SignedSource<<2995eeef9415079171df3b44d96e2f57>>
 * @relayHash 3d7d8595e1a2b94ac5a9d1ed63a683fa
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c5d1a0554cfea25edff26986e66159762fd68f0e3d031809b8a57bdd091c66c8

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type HelpObjectStoreArticleSearchStrategy = "CONTENT_SEARCH" | "CQL" | "PROXY" | "%future added value";
export type HelpObjectStoreArticleSourceSystem = "CONFLUENCE" | "CROSS_SITE_CONFLUENCE" | "EXTERNAL" | "GOOGLE_DRIVE" | "SHAREPOINT" | "%future added value";
export type articlesSearchQuery$variables = {
  categoryIds?: ReadonlyArray<string> | null | undefined;
  cloudId: string;
  helpCenterAri?: string | null | undefined;
  highlight?: boolean | null | undefined;
  isSourceSystemEnabled: boolean;
  limit: number;
  portalIds?: ReadonlyArray<string> | null | undefined;
  queryTerm: string;
};
export type articlesSearchQuery$data = {
  readonly helpObjectStore_searchArticles: {
    readonly __typename: "HelpObjectStoreArticleSearchResults";
    readonly results: ReadonlyArray<{
      readonly metadata: {
        readonly isExternal: boolean;
        readonly searchStrategy: HelpObjectStoreArticleSearchStrategy;
      };
      readonly sourceSystem?: HelpObjectStoreArticleSourceSystem | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"articleItemFragmentV2">;
    }>;
  } | {
    readonly __typename: "HelpObjectStoreSearchError";
    readonly extensions: ReadonlyArray<{
      readonly errorType: string | null | undefined;
      readonly statusCode: number | null | undefined;
    }>;
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type articlesSearchQuery = {
  response: articlesSearchQuery$data;
  variables: articlesSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categoryIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "highlight"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSourceSystemEnabled"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "portalIds"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryTerm"
},
v8 = [
  {
    "kind": "Variable",
    "name": "categoryIds",
    "variableName": "categoryIds"
  },
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  },
  {
    "kind": "Variable",
    "name": "highlight",
    "variableName": "highlight"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "portalIds",
    "variableName": "portalIds"
  },
  {
    "kind": "Variable",
    "name": "queryTerm",
    "variableName": "queryTerm"
  }
],
v9 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v10 = {
  "concreteType": "HelpObjectStoreArticleMetadata",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "searchStrategy"
    },
    {
      "kind": "ScalarField",
      "name": "isExternal"
    }
  ]
},
v11 = {
  "condition": "isSourceSystemEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "sourceSystem"
    }
  ]
},
v12 = {
  "kind": "ScalarField",
  "name": "message"
},
v13 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v14 = {
  "kind": "ScalarField",
  "name": "errorType"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "name": "articlesSearchQuery",
    "selections": [
      {
        "args": (v8/*: any*/),
        "kind": "LinkedField",
        "name": "helpObjectStore_searchArticles",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "HelpObjectStoreArticleSearchResult",
                "kind": "LinkedField",
                "name": "results",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "isSourceSystemEnabled",
                        "variableName": "isSourceSystemEnabled"
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "articleItemFragmentV2"
                  }
                ]
              }
            ],
            "type": "HelpObjectStoreArticleSearchResults"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v12/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/)
                ]
              }
            ],
            "type": "HelpObjectStoreSearchError"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "articlesSearchQuery",
    "selections": [
      {
        "args": (v8/*: any*/),
        "kind": "LinkedField",
        "name": "helpObjectStore_searchArticles",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "HelpObjectStoreArticleSearchResult",
                "kind": "LinkedField",
                "name": "results",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "ari"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "title"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "excerpt"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "displayLink"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "containerName"
                  }
                ]
              }
            ],
            "type": "HelpObjectStoreArticleSearchResults"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v12/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ]
              }
            ],
            "type": "HelpObjectStoreSearchError"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "c5d1a0554cfea25edff26986e66159762fd68f0e3d031809b8a57bdd091c66c8",
    "metadata": {},
    "name": "articlesSearchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "80662fa972099ecead235defc9f0075c";

export default node;
