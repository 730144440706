import * as React from 'react';
import type { CmdbMediaClientConfig } from 'rest/cmdb-object-picker';
import Avatar, { Skeleton } from '@atlaskit/avatar';
import type { FileIdentifier } from '@atlaskit/media-client';
import { MediaImage as MediaImageDI } from '@atlaskit/media-image';
import { gridSize } from '@atlaskit/theme';

export interface Props extends CmdbMediaClientConfig {
    MediaImage?: typeof MediaImageDI;
}

// We want to reuse mediaClientConfigs across all InsightIcons to avoid extra http requests
// MediaImage internally checks for object equality to prevent image refetch.
// That's why we need to check for the individual parameters equality at this level
// and prevent the property mediaClientConfig to change
const mediaClientConfigs = new Map();
const getMediaClientConfig = ({ mediaJwtToken, clientId, mediaBaseUrl }: Props) => {
    const key = `${mediaJwtToken}:${clientId}:${mediaBaseUrl}`;

    if (mediaClientConfigs.has(key)) {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return mediaClientConfigs.get(key);
    }

    const mediaClientConfig = {
        authProvider: () =>
            Promise.resolve({
                clientId,
                token: mediaJwtToken,
                baseUrl: mediaBaseUrl,
            }),
        useSha256ForUploads: true,
    };

    mediaClientConfigs.set(key, mediaClientConfig);

    return mediaClientConfig;
};

const hasAuth = ({ mediaBaseUrl, mediaJwtToken, fileId, clientId }: Props): boolean =>
    Boolean(mediaBaseUrl) && Boolean(mediaJwtToken) && Boolean(clientId) && Boolean(fileId);

const DefaultIcon = () => <Avatar appearance="square" size="small" />;

export const InsightIcon = (props: Props) => {
    const { fileId, MediaImage = MediaImageDI } = props;

    if (!hasAuth({ ...props }) || !MediaImage) {
        return <DefaultIcon />;
    }

    const fileIdentifier: FileIdentifier = {
        id: fileId,
        mediaItemType: 'file',
    };
    const imageSize = {
        width: gridSize() * 3,
        height: gridSize() * 3,
    };
    return (
        <div>
            <MediaImage
                identifier={fileIdentifier}
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                mediaClientConfig={getMediaClientConfig(props)}
                apiConfig={imageSize}
            >
                {({ loading, error, data }) => {
                    if (loading) {
                        return <Skeleton appearance="square" size="small" />;
                    }

                    if (error) {
                        return <DefaultIcon />;
                    }

                    if (!data) {
                        return <DefaultIcon />;
                    }

                    return <Avatar src={data.src} appearance="square" size="small" />;
                }}
            </MediaImage>
        </div>
    );
};

export const MemoizedInsightIcon = React.memo<Props>(InsightIcon);
