import React, { useEffect } from 'react';
import { isProjectMappingInHelpCenterEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { usePathParam } from 'react-resource-router';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import Heading from '@atlaskit/heading';
import { Box, media, xcss } from '@atlaskit/primitives';
import { getCloudId, getHelpCenterAri } from '@atlassian/help-center-common-util/meta';
import { ExperienceSuccess } from '@atlassian/ufo';
import {
    PORTALS_RESULT_LIMIT,
    ResultList,
    sendSearchOperationalAnalyticEvents,
    sendSearchUIAnalyticEvents,
} from '../common';
import { searchExperience } from '../experiences';
import { PortalItem } from '../portal-item';
import type { portalsSearchQuery } from './__generated__/portalsSearchQuery.graphql';
import messages from './messages';

export interface Props {
    term: string;
    updateResultsCount: (count: number, acknowledgement?: number) => void;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const Portals = ({ term, updateResultsCount, createAnalyticsEvent }: Props) => {
    const { formatMessage } = useIntl();
    const cloudId = getCloudId();

    const data = useLazyLoadQuery<portalsSearchQuery>(
        graphql`
            query portalsSearchQuery($cloudId: ID!, $queryTerm: String!, $limit: Int!, $helpCenterAri: ID) {
                helpObjectStore_searchPortals(
                    cloudId: $cloudId
                    queryTerm: $queryTerm
                    limit: $limit
                    helpCenterAri: $helpCenterAri
                ) {
                    __typename
                    ... on HelpObjectStorePortalSearchResults {
                        results {
                            id
                            metadata {
                                searchStrategy
                            }
                            ...portalItemFragmentV2
                        }
                    }
                    ... on HelpObjectStoreSearchError {
                        message
                        extensions {
                            statusCode
                            errorType
                        }
                    }
                }
            }
        `,
        {
            cloudId,
            queryTerm: term,
            helpCenterAri: isProjectMappingInHelpCenterEnabled() ? getHelpCenterAri() : undefined,
            limit: PORTALS_RESULT_LIMIT,
        }
    );
    const [portalId] = usePathParam('portalId');
    const portals =
        data.helpObjectStore_searchPortals?.__typename === 'HelpObjectStorePortalSearchResults'
            ? data.helpObjectStore_searchPortals.results
            : [];
    const showSection = portals.length > 0;

    useEffect(() => {
        if (data.helpObjectStore_searchPortals?.__typename === 'HelpObjectStorePortalSearchResults') {
            const externalResourcesCount = 0;
            let firstPartySearchPlatformResourcesCount = 0;

            data.helpObjectStore_searchPortals.results.forEach((portal) => {
                if (portal.metadata.searchStrategy === 'SEARCH_PLATFORM') {
                    firstPartySearchPlatformResourcesCount += 1;
                }
            });

            sendSearchOperationalAnalyticEvents({ action: 'portalSearchSucceeded' }, createAnalyticsEvent, portalId);

            if (showSection) {
                sendSearchUIAnalyticEvents(
                    {
                        externalResourcesCount,
                        firstPartySearchPlatformResourcesCount,
                        action: 'rendered',
                        resourceType: 'portal',
                        resourceCount: data.helpObjectStore_searchPortals.results.length,
                    },
                    createAnalyticsEvent,
                    portalId
                );
            }
        }
    }, [term, showSection, data, createAnalyticsEvent, portalId]);

    useEffect(() => {
        if (data.helpObjectStore_searchPortals?.__typename === 'HelpObjectStoreSearchError') {
            const { extensions, message: errorMessage } = data.helpObjectStore_searchPortals;

            sendSearchOperationalAnalyticEvents(
                {
                    errorMessage,
                    action: 'portalSearchFailed',
                    statusCode: extensions.length > 0 ? extensions[0].statusCode : undefined,
                    errorType: extensions.length > 0 ? extensions[0].errorType : undefined,
                },
                createAnalyticsEvent,
                portalId
            );
        }
    }, [createAnalyticsEvent, data, portalId]);

    useEffect(() => {
        updateResultsCount(portals.length, 1);
    }, [term, portals.length, updateResultsCount]);

    return (
        <>
            {showSection && (
                <Box xcss={containerStyles}>
                    <Box xcss={headingStyles}>
                        <Heading size="xxsmall">{formatMessage(messages.portals)}</Heading>
                    </Box>
                    <ResultList role="list">
                        {portals.map((resource, index) => {
                            const itemOrder = index + 1;
                            const key = resource.id;
                            return (
                                <li key={key}>
                                    <PortalItem itemOrder={itemOrder} term={term} result={resource} />
                                </li>
                            );
                        })}
                    </ResultList>
                </Box>
            )}
            <ExperienceSuccess experience={searchExperience} />
        </>
    );
};

const containerStyles = xcss({
    overflow: 'hidden',
    paddingTop: 'space.250',
    paddingRight: 'space.150',
    paddingBottom: 'space.150',
    paddingLeft: 'space.150',
    gap: 'space.150',
    borderColor: 'color.border',
    borderStyle: 'solid',
    borderRadius: 'border.radius.100',
    borderWidth: 'border.width',
    rowGap: 'space.100',
    [media.above.xxs]: { marginLeft: 'space.100', marginRight: 'space.100', width: '1fr' },
    [media.above.md]: {
        marginLeft: 'space.0',
        marginRight: 'space.0',
        width: '320px',
    },
});
const headingStyles = xcss({
    width: '100%',
    paddingRight: 'space.100',
    paddingBottom: 'space.050',
    paddingLeft: 'space.100',
});

export default withAnalyticsEvents()(Portals);
