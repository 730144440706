import React from 'react';
import { Loading } from '../../common/loading';
import type { LoadingPosition } from '../../common/loading';
import type { LoadableLoadingComponentProps } from '../types';
interface LoadingComponentProps {
    error?: Error;
    timeout?: number;
    loadingPosition?: LoadingPosition;
    LoadingSkeleton?: React.ComponentType<LoadableLoadingComponentProps>;
}
export const LoadingComponent: React.FC<LoadingComponentProps> = React.memo(function LoadingComponent({
    error,
    LoadingSkeleton,
    timeout,
    loadingPosition,
}) {
    const [isTimedOut, setIsTimedOut] = React.useState(false);
    React.useEffect(() => {
        let timer: NodeJS.Timeout;
        if (timeout) {
            timer = setTimeout(() => {
                setIsTimedOut(true);
            }, timeout);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timeout]);

    if (LoadingSkeleton) {
        return <LoadingSkeleton isLoading={!error} error={error} loadingPosition={loadingPosition} />;
    }

    return <Loading isLoading={!error} error={error} loadingPosition={loadingPosition} timedOut={isTimedOut} />;
});
