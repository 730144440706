import { defineMessages } from 'react-intl';
export default defineMessages({
    error: {
        id: 'cmdb.object.card.error.loading.object',
        defaultMessage: 'Error occured loading object',
        description: 'Displayed when a value for a CMDB Assets field does not load',
    },
    expandLabel: {
        id: 'cmdb.object.card.expand.label',
        defaultMessage: 'Expand object details',
        description: 'Label for the expand button on the CMDB object field',
    },
    collapseLabel: {
        id: 'cmdb.object.card.collapse.label',
        defaultMessage: 'Collapse object details',
        description: 'Label for the collapse button on the CMDB object field',
    },
});
