import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { sanitize } from 'dompurify';
import { isSearchUIRedesignEnabled, isIsomorphicDompurifyLibUsed } from 'feature-flags';
import { sanitize as isomorphicSanitize } from 'isomorphic-dompurify';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { graphql, useFragment } from 'react-relay';
import { usePathParam } from 'react-resource-router';
import type { State } from 'state';
import { getThemeColor } from 'state/selectors/help-center';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Inline, xcss, Text, Box } from '@atlaskit/primitives';
import { Link } from '@atlassian/help-center-common-component/analytics/link';
import { urlRefsAndTarget } from '@atlassian/help-center-common-util/is-external-resource';
import articleMessages from '../articles/messages';
import { Title, LinkContainer, Snippet, formatText, sendSearchUIAnalyticEvents, useVisibilityState } from '../common';
import { ResourceIcon } from '../resource-icon';
import type { portalItemFragment$key } from './__generated__/portalItemFragment.graphql';

export interface Props {
    itemOrder: number;
    term: string;
    showAllResults?: boolean;
    themeColor: string;
    result: portalItemFragment$key;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const PortalItem = ({ itemOrder, result, term, themeColor, createAnalyticsEvent }: Props) => {
    di(isSearchUIRedesignEnabled);
    const { formatMessage } = useIntl();
    const data = useFragment<portalItemFragment$key>(
        graphql`
            fragment portalItemFragment on HelpObjectStoreSearchResult {
                __typename @required(action: THROW)
                id @required(action: THROW)
                title @required(action: THROW)
                description @required(action: THROW)
                displayLink @required(action: THROW)
                isExternal @required(action: THROW)
                searchBackend
                searchAlgorithm
                ...resourceIconFragment
            }
        `,
        result
    );
    const { title, description, displayLink, isExternal, id, searchBackend, searchAlgorithm } = data;
    const [portalId] = usePathParam('portalId');
    const { isShortcutIconVisible, setIsHovered, setIsFocused } = useVisibilityState();
    const shortcutIcon = isExternal ? (
        <ShortcutIcon label={formatMessage(articleMessages.shortcutIcon)} size="small" />
    ) : null;
    const highlightedTitle = title ? formatText(title, term) : '';
    const highlightedDescription = description ? formatText(description, term) : '';
    const linkProps = urlRefsAndTarget({ displayLink, isAnExternalResource: isExternal });

    useEffect(() => {
        sendSearchUIAnalyticEvents(
            {
                searchBackend,
                searchAlgorithm,
                action: 'list-item-rendered',
                resourceType: 'portal',
            },
            createAnalyticsEvent,
            portalId
        );
    }, [createAnalyticsEvent, portalId, searchAlgorithm, searchBackend]);

    if (!isSearchUIRedesignEnabled()) {
        return (
            <LinkContainer
                key={id}
                actionSubjectId={isExternal ? 'external-portal-link' : 'portal-link'}
                data-test-id="result-link-container"
                attributes={{
                    itemOrder,
                    searchBackend,
                    searchAlgorithm,
                }}
                {...linkProps}
            >
                <ResourceIcon result={data} />
                <Box
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    xcss={xcss({
                        overflow: 'hidden',
                        width: '100%',
                        paddingLeft: 'space.200',
                        paddingRight: 'space.200',
                    })}
                >
                    <Title
                        dangerouslySetInnerHTML={{
                            __html: isIsomorphicDompurifyLibUsed()
                                ? isomorphicSanitize(highlightedTitle)
                                : sanitize(highlightedTitle),
                        }}
                    />
                    <Snippet
                        dangerouslySetInnerHTML={{
                            __html: isIsomorphicDompurifyLibUsed()
                                ? isomorphicSanitize(highlightedDescription)
                                : sanitize(highlightedDescription),
                        }}
                    />
                </Box>
                {shortcutIcon}
            </LinkContainer>
        );
    }

    return (
        <Box
            xcss={containerStyles}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocusCapture={() => setIsFocused(true)}
            onBlurCapture={() => setIsFocused(false)}
        >
            <Inline xcss={headingStyles} space="space.100">
                <ResourceIcon result={data} />
                <Link
                    key={id}
                    actionSubjectId={isExternal ? 'external-portal-link' : 'portal-link'}
                    data-test-id="result-link-container"
                    attributes={{
                        itemOrder,
                        searchBackend,
                        searchAlgorithm,
                    }}
                    {...linkProps}
                >
                    <Text as="p">
                        <span
                            style={{
                                color: themeColor,
                            }}
                            dangerouslySetInnerHTML={{
                                __html: isIsomorphicDompurifyLibUsed()
                                    ? isomorphicSanitize(highlightedTitle)
                                    : sanitize(highlightedTitle),
                            }}
                        />
                    </Text>
                </Link>
                <Box
                    xcss={isShortcutIconVisible ? headerRightAreaStylesVisible : headerRightAreaStylesInVisible}
                    testId="search-result-shortcut-icon"
                >
                    {shortcutIcon}
                </Box>
            </Inline>
        </Box>
    );
};

const ConnectedPortalItem = connect((state: State) => {
    return {
        themeColor: getThemeColor(state),
    };
})(PortalItem);

export default withAnalyticsEvents()(ConnectedPortalItem);

const containerStyles = xcss({
    overflow: 'hidden',
    width: '100%',
    padding: 'space.100',
    gap: 'space.150',
    borderRadius: 'border.radius.100',
});
const headingStyles = xcss({
    overflow: 'hidden',
    width: '100%',
});
const headerRightAreaStylesInVisible = xcss({
    display: 'flex',
    opacity: 0,
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
});
const headerRightAreaStylesVisible = xcss({
    display: 'flex',
    opacity: 1,
    alignItems: 'flex-start',
});
