import { defineMessages } from 'react-intl';

export default defineMessages({
    customizeRequestTypesLabel: {
        defaultMessage: 'Customize request types',
        description: 'Button to Navigate user to Customize Request Types in JSM',
        id: 'cv.portal.single.portal.customize.request.types.label',
    },
    managePortalGroupsLabel: {
        defaultMessage: 'Manage portal groups',
        description: 'Button to Navigate user to Manage Request Types in JSM',
        id: 'cv.portal.single.portal.manage.portal.groups.label',
    },
    editRequestTypesLabel: {
        defaultMessage: 'Edit request type',
        description: 'Button to Navigate user to Edit Request Type Form in JSM',
        id: 'cv.portal.single.portal.edit.request.types.label',
    },
});
