import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { isUiModificationsSupportEnabled } from '@helpCenter/feature-flags';
import { UI_MODIFICATIONS_JSM_REQUEST_CREATE_RESOURCE } from '@helpCenter/routes/portal-home/resources';
import { lazyForPaint } from 'react-loosely-lazy';
import type { Route } from 'react-resource-router';
import { useRouter, withRouter } from 'react-resource-router';
import { ScreenErrorBoundary } from 'view/layout/screen-error-boundary';
import * as grid from 'view/styles/grid';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { ScreenName as ScreenNameType } from '@atlassian/help-center-common-component/constants';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { ContextSetter } from '@atlassian/help-center-common-component/context';
import { withAsync } from '@atlassian/help-center-common-component/with-async';
import { AnalyticsScreen } from '@atlassian/help-center-common-util/analytics/analytics-screen';

export const withEmbeddedPage = <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
    screenName: ScreenNameType
) => {
    const ComponentWithEmbeddedPage = (props: TProps) => {
        const [routerState] = useRouter();
        const { basePath, ...routeContext } = routerState;

        return (
            <AnalyticsScreen screenName={screenName} screenId={routeContext.match.url}>
                <ScreenErrorBoundary screenName={screenName}>
                    <EmbedComponentWrapper>
                        <ContextSetter {...routeContext} />
                        <WrappedComponent isEmbeddedRoute {...routeContext} {...props} />
                    </EmbedComponentWrapper>
                </ScreenErrorBoundary>
            </AnalyticsScreen>
        );
    };

    return ComponentWithEmbeddedPage;
};

// Route for embeddable request create page
const AsyncEmbedCreateRequestPage = withAsync({
    loader: lazyForPaint(
        () =>
            import(
                /* webpackChunkName: "async-embed-request-create-page" */ '../../view/request-create-page/embed-request-create-page'
            )
    ),
});

export const embedCreateRequestRoute: Route = {
    name: 'embed-request-create',
    path: `/embeds/portal/:portalId/create/:requestTypeId`,
    exact: true,
    component: withRouter(withEmbeddedPage(AsyncEmbedCreateRequestPage, ScreenName.EMBED_REQUEST_CREATE)),
    resources: isUiModificationsSupportEnabled() ? [UI_MODIFICATIONS_JSM_REQUEST_CREATE_RESOURCE] : [],
};

// Route for embeddable single portal page
const AsyncEmbedSinglePortalPage = withAsync({
    loader: lazyForPaint(
        () =>
            import(
                /* webpackChunkName: "async-embed-single-portal-page" */ '../../view/help-center/help-center-content/embed-single-portal'
            )
    ),
});

export const embedSinglePortalRoute: Route = {
    name: 'embed-single-portal',
    path: `/embeds/portal/:portalId`,
    exact: true,
    component: withRouter(withEmbeddedPage(AsyncEmbedSinglePortalPage, ScreenName.EMBED_SINGLE_PORTAL)),
};

// Route for embeddable knowledge base article page
const AsyncEmbedKBArticlePage = withAsync({
    loader: lazyForPaint(
        () => import(/* webpackChunkName: "async-embed-kb-article-page" */ '../../view/article/embed-kb-article')
    ),
});

export const embedKBArticleRoute = {
    name: 'embed-kb-article',
    path: `/embeds/portal/:portalId/:topic?/:categoryId?/:article/:articleId`,
    exact: true,
    component: withRouter(withEmbeddedPage(AsyncEmbedKBArticlePage, ScreenName.EMBED_KB_ARTICLE)),
};

// Route for embeddable featured portals page
const AsyncEmbedFeaturedPortalsPage = withAsync({
    loader: lazyForPaint(
        () =>
            import(
                /* webpackChunkName: "async-embed-featured-portals-page" */ '../../view/help-center/embed-featured-portals'
            )
    ),
});

export const embedFeaturedPortalsRoute = {
    name: 'embed-featured-portals',
    path: `/embeds/portals`,
    exact: true,
    component: withRouter(withEmbeddedPage(AsyncEmbedFeaturedPortalsPage, ScreenName.EMBED_FEATURED_PORTALS)),
};

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const EmbedComponentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    border: ${grid.multiple(1 / 8).px} solid ${token('color.border.bold', colors.N30)};
    border-radius: ${grid.multiple(3 / 8).px};
    box-sizing: border-box;
`;
