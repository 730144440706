import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { di } from 'react-magnetic-di';
import type { State } from 'state';
import { getThemeColor } from 'state/selectors/help-center';
import { isLoggedIn as isUserLoggedIn } from 'state/selectors/user';
import { useExternalLink } from 'view/external-links/use-external-link';
import ActivityIcon from '@atlaskit/icon/glyph/activity';
import DocumentIcon from '@atlaskit/icon/glyph/media-services/document';
import QuestionsIcon from '@atlaskit/icon/glyph/questions';
import ScreenIcon from '@atlaskit/icon/glyph/screen';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';
import { SkeletonItem } from '@atlassian/help-center-common-component/skeleton-item';
import { IntegrationProviderMeta as ThirdPartyProvider } from '@atlassian/third-party-connectors/src/common/constants';
import type { HelpObjectStoreArticleSourceSystem } from '../inline-article-item/__generated__/inlineArticleItemFragmentV2.graphql';

export interface Props {
    entityType: string;
    displayLink: string;
    isExternal?: boolean;
    isLoggedIn: boolean;
    themeColor: string;
    iconUrl?: string | null;
    sourceSystem?: HelpObjectStoreArticleSourceSystem | null;
}

export const ResourceIcon = ({
    entityType,
    displayLink,
    isLoggedIn,
    isExternal,
    themeColor,
    iconUrl,
    sourceSystem,
}: Props) => {
    di(useExternalLink);
    const {
        data: { iconUrl: externalResourceIconUrl },
        isLoading: isIconLoading,
    } = useExternalLink({
        isLoggedIn,
        link: displayLink,
    });

    const isValidResourceIconUrl = !!iconUrl;
    const isValidExternalResourceIconUrl = !!externalResourceIconUrl && externalResourceIconUrl !== '';
    const getDefaultIcon = useMemo(() => {
        switch (entityType) {
            case 'ARTICLE':
                return <DocumentIcon label="" testId="article-icon" size="medium" />;

            case 'REQUEST_FORM':
                return <ActivityIcon label="" testId="request-form-icon" size="medium" />;

            case 'PORTAL':
                return <ScreenIcon label="" testId="portal-icon" size="medium" />;

            default:
                return <QuestionsIcon label="" testId="question-icon" size="medium" />;
        }
    }, [entityType]);

    const getThirdPartyIcon = useMemo(() => {
        if (sourceSystem === 'SHAREPOINT') return <ThirdPartyProvider.sharepoint.icon label="sharepoint" />;
        if (sourceSystem === 'GOOGLE_DRIVE') return <ThirdPartyProvider.googleDrive.icon label="googledrive" />;
        return null;
    }, [sourceSystem]);

    const getExternalResourceIcon = () => {
        if (isIconLoading) {
            return <SkeletonItem height={'24px'} width={'24px'} />;
        }

        if (isValidExternalResourceIconUrl) {
            return (
                <Box as="img" xcss={imgStyles} testId="external-resource-icon" src={externalResourceIconUrl} alt="" />
            );
        }

        if (fg('3p-integration-enabled_t5ysh')) {
            return getThirdPartyIcon ?? getDefaultIcon;
        }

        return getDefaultIcon;
    };

    const getResourceIcon = () => {
        if (isIconLoading) {
            return <SkeletonItem height={'24px'} width={'24px'} />;
        }
        if (isValidResourceIconUrl) {
            return <Box as="img" xcss={imgStyles} testId="resource-icon" src={iconUrl} alt="" />;
        }

        return getDefaultIcon;
    };

    return (
        <Box
            xcss={imgStyles}
            style={{
                color: themeColor,
            }}
        >
            {isExternal ? getExternalResourceIcon() : getResourceIcon()}
        </Box>
    );
};

const ConnectedResourceIcon = connect((state: State) => {
    return {
        isLoggedIn: isUserLoggedIn(state),
        themeColor: getThemeColor(state),
    };
})(ResourceIcon);

export default ConnectedResourceIcon;

const imgStyles = xcss({
    width: 'size.200',
    height: 'size.200',
    objectFit: 'contain',
});
