/* eslint-disable rulesdir/do-not-manually-build-jsm-agent-view-url */
import React from 'react';
import { useIntl } from 'react-intl-next';
import { withAnalyticsContext, useAnalyticsEvents } from '@atlaskit/analytics-next';
import { LinkButton } from '@atlaskit/button/new';
import EditIcon from '@atlaskit/icon/core/edit';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import messages from '../messages';

interface EditRequestTypeButtonProps {
    projectKey?: string;
    requestTypeId?: number;
    isProjectSimplified?: boolean;
}

const EditRequestTypeButton = (props: EditRequestTypeButtonProps) => {
    const { formatMessage } = useIntl();
    const { projectKey = '', requestTypeId = 0, isProjectSimplified = false } = props;

    const { createAnalyticsEvent } = useAnalyticsEvents();

    const onClickEditRequestTypes = () => {
        createAnalyticsEvent({
            actionSubjectId: 'editRequestTypesButton',
            action: 'clicked',
            actionSubject: 'button',
            analyticsType: UI_EVENT_TYPE,
        }).fire();
    };

    const generateRedirectionURL = () =>
        isProjectSimplified
            ? `/jira/servicedesk/projects/${projectKey}/settings/issuetypes/${requestTypeId}`
            : `/jira/servicedesk/projects/${projectKey}/settings/request-types/request-type/${requestTypeId}/request-form`;

    return (
        <Box xcss={ButtonStyles}>
            <LinkButton
                href={generateRedirectionURL()}
                isDisabled={!projectKey.length}
                appearance="subtle"
                target="_blank"
                testId="edit-request-type-button"
                onClick={onClickEditRequestTypes}
            >
                <Inline xcss={ButtonTextStyles} space="space.075" alignBlock="center">
                    <EditIcon label={formatMessage(messages.editRequestTypesLabel)} spacing="spacious" />
                    {formatMessage(messages.editRequestTypesLabel)}
                </Inline>
            </LinkButton>
        </Box>
    );
};

const ButtonStyles = xcss({
    borderWidth: 'border.width',
    borderStyle: 'solid',
    borderRadius: 'border.radius.100',
    borderColor: 'color.border',
    height: 'fit-content',
});

const ButtonTextStyles = xcss({
    color: 'color.text.subtle',
});

export default withAnalyticsContext({ component: 'EditRequestTypeButton' })(EditRequestTypeButton);
