import * as React from 'react';
import { styled } from '@compiled/react';
import { noop } from 'lodash';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';
import { ProjectCardSkeleton } from 'view/help-center/project-card-skeleton';
import type { LoadableLoadingComponentProps } from 'view/lazy';
import { bp } from 'view/styles/compiled/breakpoints';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { ChunkLoadErrorMessage } from '@atlassian/help-center-common-component/loading';
import { ActionBarWithButtonsStateless } from '../action-bar-with-buttons-stateless';
import { SortByFilter } from '../sort-by-filter';
import { CardsContainer, ExperienceContainer, Wrapper } from '../styled';
import messages from './messages';

export const PortalReorderExperienceLoader: React.FC<LoadableLoadingComponentProps & WrappedComponentProps> = (
    props
) => {
    return (
        <Wrapper>
            <ActionBarWithButtonsStateless isDirty={false} openPublishPrompt={noop} openCancelPrompt={noop} />
            <ExperienceContainer>
                {props.error ? (
                    <LoaderContainer>
                        <ChunkLoadErrorMessage />
                    </LoaderContainer>
                ) : (
                    <>
                        <FeaturedPortalsWrapper>
                            <h2>{props.intl.formatMessage(messages.featuredPortalsTitle)}</h2>
                            <CardsContainer>
                                {Array.from({ length: 3 }).map((_, index) => (
                                    <ProjectCardSkeleton key={index} isFeatured />
                                ))}
                            </CardsContainer>
                        </FeaturedPortalsWrapper>
                        <UnFeaturedPortalsWrapper>
                            <SortByFilter isDisabled value="POPULARITY" onChange={noop} />
                            <CardsContainer>
                                {Array.from({ length: 9 }).map((_, index) => (
                                    <ProjectCardSkeleton key={index} isFeatured />
                                ))}
                            </CardsContainer>
                        </UnFeaturedPortalsWrapper>
                    </>
                )}
            </ExperienceContainer>
        </Wrapper>
    );
};

export default injectIntl(PortalReorderExperienceLoader);

const LOADER_MIN_HEIGHT = '75vh';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const LoaderContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: LOADER_MIN_HEIGHT,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const UnFeaturedPortalsWrapper = styled.section({
    marginTop: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FeaturedPortalsWrapper = styled.section({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    h2: {
        textTransform: 'uppercase',
        marginTop: token('space.0', '0px'),
        marginBottom: token('space.100', '8px'),
        marginLeft: token('space.500', '40px'),
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        [bp.from.fromXSmall]: {
            marginLeft: 0,
        },
    },
    marginTop: token('space.200', '16px'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    borderBottom: `${token('space.025', '2px')} solid ${token('color.border', colors.N30A)}`,
});
