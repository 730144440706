export const SIDEBAR_WIDTH = 240;
export const CANT_UNLINK_ALL_DIALOG = 'cant-unlink-all-dialog';
export const UNLINK_CONFIRMATION_DIALOG = 'unlink-confirmation-dialog';
export const MANAGE_PROJECT_HEAD_WIDTH = {
    'select-all': 5,
    'project-name': 35,
    'lead-name': 35,
    status: 15,
    'action-button': 15,
};
export const MANAGE_PAGE_HEAD_WIDTH = {
    'page-name': 20,
    'last-updated': 20,
    'page-description': 40,
    'action-button': 10,
};

export const ALREADY_EXISTED_CUSTOM_PAGE_MESSAGE = `A custom page with this name already exists on your site. Try again with a different name.`;
export const CUSTOM_PAGE_NAME_CHARACTER_LIMIT = 100;
export const CUSTOM_PAGE_DESC_CHARACTER_LIMIT = 256;
export const NUMBER_OF_PAGES_INITIAL_FETCH = 100;
export const HARD_LIMIT_CUSTOM_PAGES_MESSAGE = `Pages limit of ${NUMBER_OF_PAGES_INITIAL_FETCH} is exceeded`;
export const NUMBER_OF_PAGES_PER_PAGE = 20;
